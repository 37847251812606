import { useAuthStore } from '@/store/auth';
import { Geolocation } from '@capacitor/geolocation';
import { useAppStore } from '@/store/app';

export default function useGeolocation() {
    return {
        async getPosition() {
            if (useAuthStore().getUser?.id == process.env.VUE_APP_DEMO_ID)
                return {
                    coords: {
                        latitude: 41.4064449,
                        longitude: -8.5154625,
                    },
                };

            return await Geolocation.getCurrentPosition().catch(() => {
                return {
                    coords: {
                        latitude: 41.4064449,
                        longitude: -8.5154625,
                    },
                };
            });
        },
        calculateDistance(pointA, pointB) {
            // The radius of the Earth in kilometers
            const R = 6371;

            // Convert degrees to radians
            function degreesToRadians(degrees) {
                return (degrees * Math.PI) / 180;
            }

            const dLat = degreesToRadians(pointB.lat - pointA.lat);
            const dLon = degreesToRadians(pointB.lng - pointA.lng);

            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(degreesToRadians(pointA.lat)) * Math.cos(degreesToRadians(pointB.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return R * c;
        },
        async askForLocationPermission() {
            try {
                let permission = await Geolocation.requestPermissions();
                useAppStore();
                alert(permission);
            } catch (error) {
                return await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            useAppStore().setHasLocationPermission(true);
                            resolve(position);
                        },
                        (error) => {
                            if (error.code == 1) {
                                useAppStore().setUserDeclinedLocationPermission(true);
                                useAppStore().setHasLocationPermission(false);
                            }
                            reject(error);
                        },
                    );
                });
            }
        },
    };
}
