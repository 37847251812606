<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height">
            <div class="is-flex is-justify-content-space-between is-align-items-center my-2">
                <h1 class="title is-5 has-text-left">{{ $t('components.parking.title') }}</h1>
                <b-button
                    @click="$router.push({ name: 'BottomMenu' }), appStore.setIsShowOpen(false)"
                    type="has-text-primary has-text-weight-semibold has-background-primary-light pr-5 "
                    icon-right="map"
                    >{{ $t('components.parking.seeMap') }}
                </b-button>
            </div>
            <div class="is-flex is-justify-content-space-between is-align-items-center my-2 gap-4">
                <GmapAutocomplete @place_changed="setLocation" :placeholder="$t('components.parking.parkingPlace')" slot-ref-name="parksAutocomplete" class="is-flex-grow-1">
                    <template v-slot="{ attrs, listeners }">
                        <b-input
                            icon-right="close-circle"
                            icon-right-clickable
                            @icon-right-click="onClear"
                            v-model="selectedLocation"
                            v-bind="attrs"
                            v-on="listeners"
                            ref="parksAutocomplete"></b-input>
                    </template>
                </GmapAutocomplete>
                <b-button @click="$router.push({ name: 'Filters' })" size="is-44x44" class="button is-white has-shadow is-flex ml-auto">
                    <b-icon type="is-grey-light is-medium" pack="fas" icon="sliders"></b-icon>
                </b-button>
            </div>
            <div>
                <b-datetimepicker
                    :min-datetime="new Date()"
                    :mobile-native="false"
                    v-model="selectedDate"
                    placeholder="Click to select..."
                    :icon-right="selectedDate ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="clearDateTime"
                    @input="setDateTime"
                    horizontal-time-picker>
                </b-datetimepicker>
            </div>
            <b-loading :active="isLoading"></b-loading>
            <div ref="scrollable_content" class="scrollable-content mt-2">
                <template v-if="filteredParks.length > 0">
                    <div v-for="(park, index) of filteredParks" :key="index">
                        <ParkInformation :park="park"></ParkInformation>
                        <hr class="mt-4 mb-0" />
                    </div>
                </template>
                <template v-else-if="filteredParks.length == 0 && !isLoading">
                    <div class="is-flex is-justify-content-center is-align-items-center fill-height">
                        <span class="has-text-grey-light">{{ $t('components.parking.noResults') }}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import ParkInformation from '@/components/ui/ParkInformation.vue';
    import useParks from '@/mixins/useParks';
    import useScroll from '@/mixins/useScroll';
    import { useParkStore } from '@/store/park';
    import { useMapStore } from '@/store/map';
    import { useAppStore } from '@/store/app';
    import _ from 'lodash';

    export default {
        name: 'ParkingResearch',
        components: {
            ParkInformation,
        },
        data() {
            return {
                selectedDate: new Date(),
                isLoading: false,
                selectedLocation: null,
                selectedCoords: null,
                useParks: useParks(),
                parkStore: useParkStore(),
                mapStore: useMapStore(),
                useScroll: null,
                parks: [],
                page: 1,
                pageCount: 1,
                appStore: useAppStore(),
            };
        },
        created() {
            if (this.mapStore.getCenterCoords) this.selectedCoords = this.mapStore.getCenterCoords;
            this.fetchParks();
            this.selectedLocation = this.parkStore.getSearchText;
        },
        mounted() {
            this.useScroll = useScroll(this.$refs.scrollable_content);
            this.useScroll.onScrollBottom(() => {
                if (this.page >= this.pageCount) return;
                this.page++;
                this.fetchParks();
            });
        },
        computed: {
            filterDistance() {
                return _.get(this.parkStore.getQuery, 'filters.distance.$lte', null);
            },
            filteredParks() {
                if (!this.filterDistance) return this.parks;
                return this.parks.filter((park) => _.get(park, 'distance.distance.value', 100000) <= parseInt(this.filterDistance) * 1000);
            },
        },
        methods: {
            async fetchParks() {
                this.isLoading = true;
                const response = await this.useParks.getParksByQuery({
                    ...(this.selectedCoords ? { calculateDistance: true, calcRealDistance: true, latitude: this.selectedCoords.lat, longitude: this.selectedCoords.lng } : {}),
                    ...this.parkStore.getQuery,
                    pagination: {
                        page: this.page,
                    },
                });

                this.pageCount = response.meta.pagination.pageCount;
                if (this.page == 1) this.parks = response.data;
                else this.parks = [...this.parks, ...response.data];
                this.isLoading = false;
            },
            setLocation(location) {
                this.selectedLocation = location.formatted_address;
                this.parkStore.setSearchText(location.formatted_address);
                this.selectedCoords = {
                    lat: location.geometry.location.lat(),
                    lng: location.geometry.location.lng(),
                };
                this.page = 1;
                this.fetchParks();
                this.mapStore.setCenterCoords(this.selectedCoords);
            },
            onClear() {
                this.selectedLocation = null;
                this.parkStore.setSearchText(null);
                this.selectedCoords = {
                    lat: null,
                    lng: null,
                };
                this.page = 1;
                this.mapStore.toggleCenterCoords();
                this.fetchParks();
            },
            clearDateTime() {
                this.selectedDate = null;
            },
            setDateTime(date) {
                // set date seconds and milliseconds to 0
                date.setSeconds(0);
                date.setMilliseconds(0);
                date = date.toISOString();

                this.parks.forEach((park) => {
                    let parkLotation = this.parkStore.getParksLotation.find((parkLotation) => {
                        return parkLotation.parkId == park.id && parkLotation.date == date;
                    });

                    // random number between 0 and park.totalCapacity
                    if (parkLotation) {
                        park.freeLots = parkLotation.lotation;
                    } else {
                        park.freeLots = Math.floor(Math.random() * park.totalCapacity);
                        this.parkStore.setParksLotationDate(date, park.id, park.freeLots);
                    }
                });
            },
        },
    };
</script>
