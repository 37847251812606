<template>
    <nav class="navbar is-fixed-bottom rounded" role="navigation">
        <div class="navbar has-background-white container is-expanded rounded is-relative pb-5">
            <div class="columns is-mobile is-multiline is-centered">
                <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet mt-6">
                    <b-icon type="is-primary is-large has-background-primary-light has-border-radius py-6 px-6" pack="fas" icon="user"></b-icon>
                </div>
            </div>
            <div class="columns is-mobile is-multiline is-centered is-vcentered mb-4 mt-6">
                <div class="column is-10-mobile is-10-tablet">
                    <h1 class="title is-5">{{ $t('components.notifications.notRegistered') }}</h1>
                </div>
                <div class="column is-10-mobile is-10-tablet">
                    <p class="subtitle is-6 has-text-weight-light px-5">{{ $t('components.notifications.registerParagraph') }}</p>
                </div>
                <div class="column is-9-mobile is-9-tablet mt-5">
                    <b-button expanded class="has-text-weight-lighter has-shadow mb-4" type="is-primary">
                        {{ $t('components.notifications.registButton') }}
                    </b-button>
                    <a class="has-text-grey is-size-6">
                        {{ $t('components.notifications.notNow') }}
                    </a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'NotRegistered',
    };
</script>
