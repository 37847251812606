<template>
    <section class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.push({ name: isDesktop ? 'Main-Settings' : 'Settings' })" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">
                    {{ $t('views.profile.language.title') }}
                </h1>
                <div></div>
            </div>

            <div class="is-flex is-flex-direction-column fill-height gap-6 mt-5">
                <div class="card">
                    <header class="card-header">
                        <div class="card-header-icon has-text-grey pr-3">
                            <b-image :src="require('@/assets/img/icons/pt.png')"></b-image>
                        </div>
                        <p class="card-header-title has-text-weight-normal has-text-left has-text-grey is-family-primary is-size-6">
                            {{ $t('views.profile.language.pt') }}
                        </p>
                        <b-radio @input="setLanguage('pt')" v-model="currentLanguage" native-value="pt" name="language"></b-radio>
                    </header>
                </div>
                <div class="card">
                    <header class="card-header">
                        <div class="card-header-icon is-size-6 has-text-grey pr-3">
                            <b-image :src="require('@/assets/img/icons/uk.png')"></b-image>
                        </div>
                        <p class="card-header-title has-text-weight-normal has-text-left has-text-grey is-family-primary is-size-6">
                            {{ $t('views.profile.language.en') }}
                        </p>
                        <b-radio @input="setLanguage('en')" v-model="currentLanguage" native-value="en" name="language"></b-radio>
                    </header>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { useAppStore } from '@/store/app';
    import useDesktop from '@/mixins/useDesktop';
    import { localeChanged } from 'vee-validate';
    export default {
        name: 'Language',
        data() {
            return {
                currentLanguage: '',
                appStore: useAppStore(),
                useDesktop: useDesktop(),
            };
        },
        created() {
            this.currentLanguage = this.appStore.getLocale;
        },
        computed: {
            isDesktop() {
                return this.useDesktop.isDesktop;
            },
        },
        methods: {
            setLanguage(language) {
                this.appStore.setLocale(language);
                this.$i18n.locale = language;
                localeChanged();
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    .card-header {
        border-radius: 15px;
        height: 56px;
        font-size: 90%;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
    }
    .card {
        border-radius: 15px;
    }
</style>
