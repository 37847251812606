<template>
    <div>
        <div class="is-hidden-touch">
            <div class="columns is-mobile is-multiline is-centered is-vcentered">
                <div class="column is-1">
                    <b-button @click="$router.go(-1)" size="is-40x40" class="submit-button button is-primary is-pulled-left">
                        <b-icon type="is-white py-1	px-1" pack="fas" icon="angle-left"> </b-icon>
                    </b-button>
                </div>
                <div class="column is-7">
                    <h1 class="title is-5">
                        {{ $t('components.search.favorite') }}
                    </h1>
                </div>
                <div class="column is-1"></div>
            </div>
            <div class="columns is-mobile is-multiline is-centered is-mobile is-vcentered mb-4">
                <div class="column is-3">
                    <b-button
                        @click="setSelectedFavType('parkingLot')"
                        expanded
                        :class="selectedFavType == 'parkingLot' ? 'is-primary submit-button' : 'has-background-grey-lighter'"
                        class="button is-small has-shadow">
                        <p class="is-size-6">{{ $t('components.search.parks') }}</p>
                    </b-button>
                </div>
                <div class="column is-3">
                    <b-button
                        @click="setSelectedFavType('stop')"
                        expanded
                        :class="selectedFavType == 'stop' ? 'is-primary submit-button' : 'has-background-grey-lighter'"
                        class="button is-small has-shadow">
                        <p class="is-size-6">{{ $t('components.search.stops') }}</p>
                    </b-button>
                </div>
                <div class="column is-3">
                    <b-button
                        expanded
                        @click="setSelectedFavType('route')"
                        class="button is-small has-shadow"
                        :class="selectedFavType == 'route' ? 'is-primary submit-button' : 'has-background-grey-lighter'">
                        <p class="is-size-6">{{ $t('components.search.route') }}</p>
                    </b-button>
                </div>
            </div>
            <div class="columns is-overflow is-mobile is-multiline is-centered mt-3">
                <div v-if="favorites.length > 0" class="column is-11">
                    <div v-for="(favorite, index) of filteredFavorites" :key="index">
                        <!-- <ParkInformation v-if="favorite.type == 'parkingLot'" :park="favorite.parkingLot"></ParkInformation>
                        <StopInformation v-if="favorite.type == 'stop'" :stop="favorite.stop"></StopInformation> -->
                        <ParkInfoSimple v-if="favorite.type == 'parkingLot'" :park="favorite.parkingLot"></ParkInfoSimple>
                        <StopInfoSimple v-if="favorite.type == 'stop'" :stop="favorite.stop"></StopInfoSimple>
                        <TransportSchedule v-if="favorite.type == 'route'" :route="favorite.route"></TransportSchedule>

                        <hr />
                    </div>
                </div>
                <div v-else class="column is-11">
                    <h1>{{ $t('components.search.notFavorites') }}</h1>
                </div>
            </div>
        </div>
        <section class="section is-fullheight is-hidden-desktop">
            <div class="container is-fullheight-per is-flex is-flex-direction-column is-justify-content-flex-start">
                <div class="columns is-mobile is-multiline is-vcentered">
                    <div class="column is-1">
                        <b-button @click="$router.go(-1)" size="is-40x40" class="button is-primary is-pulled-left">
                            <b-icon type="is-white py-1	px-1" pack="fas" icon="angle-left"> </b-icon>
                        </b-button>
                    </div>
                    <div class="column is-10">
                        <h1 class="title is-5">
                            {{ $t('components.search.favorite') }}
                        </h1>
                    </div>
                    <div class="column is-1"></div>
                </div>
                <div class="columns is-mobile is-multiline is-centered is-mobile is-vcentered mb-4">
                    <div class="column is-4">
                        <b-button
                            @click="setSelectedFavType('parkingLot')"
                            expanded
                            :class="selectedFavType == 'parkingLot' ? 'is-primary submit-button' : 'has-background-grey-lighter'"
                            class="button is-small has-shadow">
                            <p class="is-size-6">{{ $t('components.search.parks') }}</p>
                        </b-button>
                    </div>
                    <div class="column is-4">
                        <b-button
                            @click="setSelectedFavType('stop')"
                            expanded
                            :class="selectedFavType == 'stop' ? 'is-primary submit-button' : 'has-background-grey-lighter'"
                            class="button is-small has-shadow">
                            <p class="is-size-6">{{ $t('components.search.stops') }}</p>
                        </b-button>
                    </div>
                    <div class="column is-4">
                        <b-button
                            expanded
                            @click="setSelectedFavType('route')"
                            class="button is-small has-shadow"
                            :class="selectedFavType == 'route' ? 'is-primary submit-button' : 'has-background-grey-lighter'">
                            <p class="is-size-6">{{ $t('components.search.route') }}</p>
                        </b-button>
                    </div>
                </div>
                <div class="columns is-overflow is-mobile is-multiline is-centered mt-3">
                    <div v-if="favorites.length > 0" class="column is-11">
                        <div v-for="(favorite, index) of filteredFavorites" :key="index">
                            <ParkInfoSimple v-if="favorite.type == 'parkingLot'" :park="favorite.parkingLot"></ParkInfoSimple>
                            <StopInfoSimple v-if="favorite.type == 'stop'" :stop="favorite.stop"></StopInfoSimple>
                            <TransportSchedule v-if="favorite.type == 'route'" :route="favorite.route"></TransportSchedule>

                            <hr />
                        </div>
                    </div>
                    <div v-else class="column is-11">
                        <h1>{{ $t('components.search.notFavorites') }}</h1>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import ParkInformation from '@/components/ui/ParkInformation.vue';
    import StopInformation from '@/components/ui/StopInformation.vue';
    import DestinationInfo from '@/components/ui/DestinationInfo.vue';
    import ParkInfoSimple from '@/components/ui/ParkInfoSimple.vue';
    import StopInfoSimple from '@/components/ui/StopInfoSimple.vue';
    import TransportSchedule from '@/components/ui/TransportSchedule.vue';

    export default {
        name: 'FavoriteView',
        components: {
            ParkInformation,
            DestinationInfo,
            StopInformation,
            ParkInfoSimple,
            StopInfoSimple,
            TransportSchedule,
        },

        data() {
            return {
                selectedFavType: '',
            };
        },
        methods: {
            setSelectedFavType(type) {
                if (this.selectedFavType == type) this.selectedFavType = '';
                else this.selectedFavType = type;
            },
        },

        computed: {
            favorites() {
                return this.$store.getters['favorites/getFavorites'];
            },
            filteredFavorites() {
                if (!this.selectedFavType) {
                    return this.favorites;
                }
                return this.favorites.filter((favorite) => favorite.type == this.selectedFavType);
            },
        },
    };
</script>
