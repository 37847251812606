<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-10">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.parking.details') }}</h1>
                <div></div>
            </div>

            <BusInformation :bus="bus"></BusInformation>
        </div>
    </div>
</template>

<script>
    // import { addFavorites, removeFavorites } from '@/api/favorites';
    import BusInformation from '@/components/ui/BusInformation';
    import _ from 'lodash';
    import { getBus } from '@/api/bus';

    export default {
        name: 'BusDetails',
        components: {
            BusInformation,
        },
        data() {
            return {
                bus: null,
            };
        },
        watch: {
            async '$route.params.id'(newValue) {
                await this.getBus();
            },
        },
        created() {
            this.getBus();
        },
        watch: {
            '$route.params.id'(newValue) {
                this.getBus();
            },
        },
        methods: {
            getBus() {
                getBus(this.$route.params.id, {
                    populate: ['agency.pictures'],
                }).then((response) => {
                    this.bus = response.data.data;
                });
            },
        },
    };
</script>
