<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="is-flex is-justify-content-space-between is-align-items-center my-2">
                <h1 class="title is-5 has-text-left">{{ $t('components.mapSettings.filters') }}</h1>
            </div>

            <div class="is-flex is-flex-direction-column gap-4">
                <div class="columns is-mobile is-vcentered">
                    <div class="column is-4-mobile is-4-tablet is-flex is-flex-direction-column is-align-items-center">
                        <img @click="setParks" :class="mapStore.getShowParks == true ? 'has-border' : ''" src="@/assets/img/map/PARQUES.svg" class="image is-64x64" />
                        <p :class="mapStore.getShowParks == true ? 'has-text-primary has-text-weight-semibold' : 'has-text-grey-light'" class="is-size-9 mt-1">
                            {{ $t('components.mapSettings.parks') }}
                        </p>
                    </div>
                    <div class="column is-4-mobile is-4-tablet is-flex is-flex-direction-column is-align-items-center">
                        <img @click="setStops" :class="mapStore.getShowStops == true ? 'has-border' : ''" src="@/assets/img/map/TRANSPORTES.svg" class="image is-64x64" />
                        <p :class="mapStore.getShowStops == true ? 'has-text-primary has-text-weight-semibold' : 'has-text-grey-light'" class="is-size-9 mt-1">
                            {{ $t('components.mapSettings.stops') }}
                        </p>
                    </div>
                    <div class="column is-4-mobile is-4-tablet is-flex is-flex-direction-column is-align-items-center">
                        <img @click="setRoutes" :class="mapStore.getShowRoutes == true ? 'has-border' : ''" src="@/assets/img/map/ROTAS.svg" class="image is-64x64" />
                        <p :class="mapStore.getShowRoutes == true ? 'has-text-primary has-text-weight-semibold' : 'has-text-grey-light'" class="is-size-9 mt-1">
                            {{ $t('components.mapSettings.routes') }}
                        </p>
                    </div>
                </div>

                <b-button
                    v-if="this.authStore.isGuest == false"
                    expanded
                    @click="setFavorites"
                    class="button has-text-weight-semibold"
                    icon-pack="fas"
                    icon-left="heart"
                    :class="mapStore.getShowFavorites ? 'has-text-pink' : 'has-text-grey-light'">
                    <p :class="mapStore.getShowFavorites ? 'has-text-pink' : 'has-text-grey-light'" class="ml-3">{{ $t('components.mapSettings.onlyFavorites') }}</p>
                </b-button>

                <div>
                    <h1 class="title is-5 has-text-left">{{ $t('components.mapSettings.mapType') }}</h1>

                    <div class="columns is-mobile is-vcentered">
                        <div class="column is-6-mobile is-6-tablet">
                            <img @click="showDefault" :class="selectedMapType == 'default' ? 'has-border' : ''" src="@/assets/img/map/default.svg" />
                            <p :class="selectedMapType == 'default' ? 'has-text-primary has-text-weight-semibold' : 'has-text-grey-light'" class="is-size-9">
                                {{ $t('components.mapSettings.default') }}
                            </p>
                        </div>
                        <div class="column is-6-mobile is-6-tablet">
                            <img @click="showSatelite" :class="selectedMapType == 'satelite' ? 'has-border' : ''" src="@/assets/img/map/satellite.svg" />
                            <p :class="selectedMapType == 'satelite' ? 'has-text-primary has-text-weight-semibold' : 'has-text-grey-light'" class="is-size-9">
                                {{ $t('components.mapSettings.satellite') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMapStore } from '@/store/map';
    import { useAuthStore } from '@/store/auth';

    export default {
        name: 'MapSettings',
        data() {
            return {
                mapStore: useMapStore(),
                authStore: useAuthStore(),
                selectedMapType: 'default',
                checkboxGroup: ['freeparking', 'paidparking'],
            };
        },
        methods: {
            setParks() {
                if (this.mapStore.getShowParks == true) {
                    this.mapStore.setShowParks(false);
                } else {
                    this.mapStore.setShowParks(true);
                }
            },
            setStops() {
                if (this.mapStore.getShowStops == true) {
                    this.mapStore.setShowStops(false);
                } else {
                    this.mapStore.setShowStops(true);
                }
            },
            setRoutes() {
                if (this.mapStore.getShowRoutes == true) {
                    this.mapStore.setShowRoutes(false);
                } else {
                    this.mapStore.setShowRoutes(true);
                }
            },
            setFavorites() {
                if (this.mapStore.getShowFavorites == true) {
                    this.mapStore.setShowFavorites(false);
                } else {
                    this.mapStore.setShowFavorites(true);
                }
            },
            showDefault() {
                this.selectedMapType = 'default';
                document.querySelectorAll(".gmnoprint[role='menubar']>div>button")[0].click();
            },
            showSatelite() {
                this.selectedMapType = 'satelite';
                document.querySelectorAll(".gmnoprint[role='menubar']>div>button")[1].click();
            },
        },
    };
</script>

<style scoped>
    .has-border {
        border: 4px solid #01acca;
        border-radius: 15px;
        width: 70px;
        height: 70px;
    }
</style>
