export default function useScroll(element) {
    return {
        // function that detects when scroll has reached the bottom
        onScrollBottom(callback) {
            element.addEventListener('scroll', () => {
                if (element.scrollTop + element.clientHeight >= element.scrollHeight * 0.9) {
                    callback();
                }
            });
        },
    };
}
