<template>
    <article @click="$router.push({ name: 'ParkDetails', params: { id: park.id }, query: { freeLots: park.freeLots } })" class="cursor-pointer">
        <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center gap-2">
            <figure class="is-relative fit-content">
                <img :src="getParkPic" class="image is-55x55 has-border-radius" />
            </figure>
            <div class="is-flex-grow-1 has-text-left is-flex is-flex-direction-column is-justify-content-space-between is-align-self-flex-start">
                <h5 class="title is-size-6 is-family-primary pb-1 mt-1">{{ park.name }}</h5>
                <p class="subtitle is-7 is-family-secondary has-text-grey-light">{{ park.address }}</p>
            </div>
            <div class="fit-content">
                <h5 class="title is-size-5 has-text-weight-bold has-text-right">{{ duration }}</h5>
                <p class="subtitle is-size-7 has-text-right has-text-grey-light">min</p>
            </div>
            <div v-if="authStore.isGuest == false" class="fit-content">
                <b-button
                    :loading="isLoading"
                    @click.stop="setFavorite"
                    :type="park.favoriteId ? 'has-text-pink' : 'has-text-grey-light'"
                    class="is-borderless is-white is-small"
                    :class="{ 'fa-spin-pulse': isLoading }"
                    icon-pack="fas"
                    :icon-right="!isLoading ? 'heart' : 'spinner'" />
            </div>
        </div>

        <div class="mt-4 tooltip-icons">
            <a class="is-flex is-flex-direction-row is-align-items-center" aria-label="reply">
                <div class="has-background-orange-light has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-orange" pack="fas" size="icon-box--icon" icon="euro-sign"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">{{ park.price ? `${parseFloat(park.price).toFixed(2)} €/h` : $t('components.parking.free') }}</p>
            </a>
            <a class="is-flex is-flex-direction-row is-align-items-center" aria-label="reply">
                <div class="has-background-blue-lighter has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-blue" pack="fas" size="icon-box--icon" icon="route"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">{{ distance }}</p>
            </a>
            <a class="is-flex is-flex-direction-row is-align-items-center" aria-label="reply">
                <div class="has-background-purple-light has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-purple" pack="fas" size="icon-box--icon" :icon="getParkType == 'indoor' ? 'house' : 'cloud'"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">{{ $t(`components.filters.${getParkType}`) }}</p>
            </a>
            <a class="is-flex is-flex-direction-row is-align-items-center" aria-label="reply">
                <div :class="carIconColor.background" class="has-icon-euro-radius mr-1 icon-box">
                    <b-icon :type="carIconColor.icon" pack="fas" size="icon-box--icon" icon="car"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">{{ park.freeLots }}/{{ park.totalCapacity }} {{ $t('components.parking.freespot') }}</p>
            </a>
        </div>
    </article>
</template>

<script>
    import useFavorites from '@/mixins/useFavorites';
    import { useAuthStore } from '@/store/auth';
    import _ from 'lodash';

    export default {
        name: 'ParkInformation',
        props: {
            park: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        data() {
            return {
                isLoading: false,
                useFavorites: useFavorites(),
                authStore: useAuthStore(),
            };
        },
        computed: {
            getParkPic() {
                if (this.park.pictures) {
                    if (this.park?.pictures[0]?.formats?.small?.url) {
                        return this.park.pictures[0].formats.small.url;
                    } else {
                        return this.park.pictures[0].url;
                    }
                } else return require('@/assets/img/examples/parque.jpg');
            },
            getParkType() {
                let type = _.get(this.park, 'type', 'outdoor') || 'outdoor';
                return type.toLowerCase();
            },
            distance() {
                return _.get(this.park.distance, 'distance.text', null);
            },
            duration() {
                return _.get(this.park.distance, 'duration.text', '').split(' ')[0];
            },
            carIconColor() {
                if (this.park.freeLots / this.park.totalCapacity >= 0.6) {
                    return {
                        icon: 'is-success',
                        background: 'has-background-green-light',
                    };
                } else if (this.park.freeLots / this.park.totalCapacity >= 0.3) {
                    return {
                        icon: 'is-yellow',
                        background: 'has-background-yellow-light',
                    };
                } else {
                    return {
                        icon: 'is-danger',
                        background: 'has-background-red-lighter',
                    };
                }
            },
        },
        methods: {
            async setFavorite() {
                this.isLoading = true;
                if (!this.park.favoriteId) {
                    let favorite = await this.useFavorites.addFavorite({
                        parkingLot: this.park.id,
                        type: 'parkingLot',
                    });
                    this.park.favoriteId = favorite.id;
                } else {
                    await this.useFavorites.removeFavorite(this.park.favoriteId);
                    this.park.favoriteId = null;
                }
                this.isLoading = false;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import '@/assets/scss/variables.scss';
    .has-icon-euro-radius {
        border-radius: 0.5rem;
    }

    .has-border-radius {
        border-radius: 0.75rem;
    }
    .is-55x55 {
        width: 55px;
        height: 55px;
    }

    figure > .icon-box {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 0.75rem 0 0.375rem 0;
    }

    img {
        object-fit: cover;
    }

    .tooltip-icons {
        display: grid;
        // grid of columns 2x2
        grid-template-columns: repeat(2, 1fr);
        // grid of rows 2x2
        grid-template-rows: repeat(2, 1fr);
        // gap between rows and columns
        gap: 0.5rem;
    }
</style>
