<template>
    <div class="is-centered">
        <article class="media is-flex is-align-items-center">
            <div class="media-left">
                <!-- <figure>
                    <img src="@/assets/img/examples/famalicao_estadio.jpg" :class="pageSelected == 'result' ? 'image is-75x75 has-border-radius' : 'image is-96x96 has-border-radius'" />
                </figure> -->
                <b-icon type="is-primary has-background-primary-light" class="p-5 has-icon-radius" pack="fas" icon="location-dot"></b-icon>
            </div>
            <div class="media-content">
                <div class="content has-text-left" :class="pageSelected == 'result' ? 'py-2' : 'py-0'">
                    <h5 class="title is-family-primary is-size-8 pb-1">Estádio Municipal 22 de Junho</h5>
                    <p class="subtitle is-size-6 is-family-secondary has-text-grey-light">Vila Nova de Famalicão, Portugal</p>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'DestinationInfo',
        props: ['pageSelected'],
    };
</script>

<style scoped>
    .is-75x75 {
        width: 75px;
        height: 75px;
    }
</style>
