<template>
    <div>
        <div>
            <div class="columns is-mobile is-multiline is-centered is-vcentered">
                <div class="column is-10-mobile is-10-desktop is-9-tablet mb-4 mt-2">
                    <h1 class="title is-5 has-text-left">Paragens de Autocarro</h1>
                </div>
            </div>
        </div>
        <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-10-mobile is-10-tablet is-10-desktop">
                <GmapAutocomplete @place_changed="setLocation" placeholder="Localização" slot-ref-name="stopsAutocomplete">
                    <template v-slot="{ attrs, listeners }">
                        <b-input
                            icon-right="close-circle"
                            icon-right-clickable
                            @icon-right-click="selectedLocation = null"
                            v-model="selectedLocation"
                            v-bind="attrs"
                            v-on="listeners"
                            ref="stopsAutocomplete"></b-input>
                    </template>
                </GmapAutocomplete>
            </div>
            <div class="column is-10-mobile is-10-tablet is-10-desktop mt-4">
                <div class="columns is-mobile is-multiline has-scroll is-centered mt-5">
                    <div v-for="(stop, index) of $store.getters['stops/getStops']" :key="index" class="column is-12-mobile is-12-tablet is-overflowX">
                        <StopInformation :stop="stop"></StopInformation>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StopInformation from '@/components/ui/StopInformation.vue';
    import { getStops } from '@/api/stops';

    export default {
        name: 'SearchBusStop',
        components: {
            StopInformation,
        },

        data() {
            return {
                selectedLocation: null,
            };
        },

        created() {
            this.selectedLocation = this.placeholderDest;
        },
        methods: {
            setLocation(location) {
                this.selectedLocation = location.formatted_address;
                this.$store.commit('map/setLocation', location);
                getStops({
                    ...{ calculateDistance: true, calcRealDistance: true, latitude: location.geometry.location.lat(), longitude: location.geometry.location.lng() },
                }).then((response) => {
                    this.$store.commit('stops/setStops', response.data.data);
                });
            },
        },
    };
</script>
