<template>
    <div>
        <article class="media">
            <div class="media-left">
                <figure>
                    <b-icon type="is-yellow has-background-yellow-light has-icon-radius py-5 px-5" pack="fas" icon="bus"></b-icon>
                </figure>
            </div>
            <div class="media-content is-clipped">
                <div class="content has-text-left">
                    <div class="columns is-mobile is-vcentered">
                        <div @click="$router.push({ name: 'BusStop', params: { id: bus.id } })" class="column is-6">
                            <h5 class="title is-size-6 is-family-primary pb-1 is-capitalized">{{ busName }}</h5>
                            <p class="subtitle is-7 is-family-secondary has-text-grey-light">{{ agencyName }}</p>
                        </div>
                        <div class="column is-6">
                            <figure>
                                <img class="image has-border-radius" :src="agencyPic" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </article>
        <div class="is-flex is-flex-direction-column is-align-items-flex-start mt-5 gap-4">
            <div v-if="nextStop" class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div class="has-background-blue-lighter has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-blue" pack="fas" size="icon-box--icon" icon="route"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">Próxima paragem: {{ nextStop }}</p>
            </div>

            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div class="has-background-red-lighter has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-red" pack="far" size="icon-box--icon" icon="clock"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">5 min</p>
            </div>

            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div class="has-background-green-light has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-green" pack="fas" size="icon-box--icon" icon="bus"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">{{ capacity }} {{ $t('components.transport.seats') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { addFavorites, removeFavorites } from '@/api/favorites';
    import _ from 'lodash';

    export default {
        name: 'BusInformation',
        data() {
            return {
                isLoading: false,
            };
        },
        props: {
            bus: {
                type: Object,
                default() {
                    return {};
                },
            },
        },

        computed: {
            busName() {
                return _.get(this.bus, 'name', null);
            },
            agencyPic() {
                return _.get(this.bus, 'agency.pictures[0].url', null);
            },
            agencyName() {
                return _.get(this.bus, 'agency.name', null);
            },
            capacity() {
                return `${_.get(this.bus, 'freeSeats', 0)} / ${_.get(this.bus, 'totalCapacity', 0)}`;
            },
            nextStop() {
                return _.get(this.bus, 'sensorBus.nextStop.name', null);
            },
        },
    };
</script>
<style scoped>
    .has-icon-euro-radius {
        border-radius: 0.5rem;
        /* width: 12px; */
    }
</style>
