import { vueInstance } from '@/main';
import { useAuthStore } from '@/store/auth';
import axios from 'axios';

const _axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

// intercept request
_axios.interceptors.request.use(
    (config) => {
        const token = useAuthStore().getToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // do something with request error
        return Promise.reject(error);
    },
);

// intercept response and if status  is 401 then logout
_axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status == 401) {
            useAuthStore().resetStore();
            vueInstance.$buefy.toast.open({
                message: vueInstance.$t('shared.tokenError'),
                position: 'is-top',
                type: 'is-danger',
                duration: 3000,
            });
            vueInstance.$router.push({ name: 'HomeDemo' });
        } else {
            console.log(error.message || error);
        }
        return Promise.reject(error);
    },
);

export default _axios;
