<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="is-flex is-flex-direction-row is-justify-content-space-between">
                <h1 class="title is-5 has-text-left">{{ $t('components.routes.title') }}</h1>
                <b-button @click="reverseOriginDestination" size="is-44x44" class="button is-blue-lighter has-shadow">
                    <b-icon type="is-primary" pack="fas" icon="arrow-right-arrow-left" style="transform: rotate(90deg)"></b-icon>
                </b-button>
            </div>
            <div class="scrollable-content gap-6">
                <div class="is-flex is-flex-direction-column gap-1">
                    <div class="is-flex is-flex-direction-row gap-3">
                        <img src="@/assets/icons/Elipses.svg" />
                        <GmapAutocomplete @place_changed="setOrigin" :placeholder="$t('components.search.yourlocation')" slot-ref-name="originAutocomplete" class="is-flex-grow-1">
                            <template v-slot="{ attrs, listeners }">
                                <b-input
                                    icon-right="close"
                                    icon-right-clickable
                                    @icon-right-click="onClearOrigin"
                                    v-model="selectedOrigin"
                                    v-bind="attrs"
                                    v-on="listeners"
                                    ref="originAutocomplete"></b-input>
                            </template>
                        </GmapAutocomplete>
                    </div>
                    <div class="is-flex is-flex-direction-row ml-2">
                        <img src="@/assets/icons/3circles.svg" />
                    </div>
                    <div class="is-flex is-flex-direction-row gap-3">
                        <img @click.stop="setStadium" src="@/assets/icons/Elipses.svg" />
                        <GmapAutocomplete @place_changed="setDestination" :placeholder="$t('components.search.destiny')" slot-ref-name="destAutocomplete" class="is-flex-grow-1">
                            <template v-slot="{ attrs, listeners }">
                                <b-input
                                    icon-right="close"
                                    icon-right-clickable
                                    @icon-right-click="onClearDestination"
                                    v-model="selectedDestination"
                                    v-bind="attrs"
                                    v-on="listeners"
                                    ref="destAutocomplete"></b-input>
                            </template>
                        </GmapAutocomplete>
                    </div>
                </div>
                <div class="is-flex is-flex-direction-column gap-4">
                    <h1 class="subtitle is-5 has-text-left mb-0">{{ $t('components.filters.transporttype') }}</h1>
                    <div class="transport-buttons">
                        <div>
                            <b-button
                                @click="setTravelMode('WALKING')"
                                size="is-60x60"
                                :class="travelMode == 'WALKING' ? 'is-primary submit-button' : 'has-background-grey-lighter'"
                                class="button has-shadow">
                                <b-icon pack="fas" icon="person-walking"></b-icon>
                            </b-button>
                            <p class="is-size-7 mt-3" :class="travelMode == 'WALKING' ? 'has-text-primary' : 'has-text-grey-light'">{{ $t('components.routes.walk') }}</p>
                        </div>
                        <div>
                            <b-button
                                @click="setTravelMode('TRANSIT')"
                                size="is-60x60"
                                :class="travelMode == 'TRANSIT' ? 'submit-button is-primary' : 'has-background-grey-lighter'"
                                class="button has-shadow">
                                <b-icon pack="fas" icon="bus"></b-icon>
                            </b-button>
                            <p class="is-size-7 mt-3" :class="travelMode == 'TRANSIT' ? 'has-text-primary' : 'has-text-grey-light'">{{ $t('components.routes.transit') }}</p>
                        </div>
                        <div>
                            <b-button
                                @click="setTravelMode('BICYCLING')"
                                size="is-60x60"
                                :class="travelMode == 'BICYCLING' ? ' submit-button is-primary' : 'has-background-grey-lighter'"
                                class="button has-shadow">
                                <b-icon pack="fas" icon="person-biking"></b-icon>
                            </b-button>
                            <p class="is-size-7 mt-3" :class="travelMode == 'BICYCLING' ? 'has-text-primary' : 'has-text-grey-light'">{{ $t('components.routes.cycling') }}</p>
                        </div>
                        <div>
                            <b-button
                                @click="setTravelMode('DRIVING')"
                                size="is-60x60"
                                :class="travelMode == 'DRIVING' ? ' submit-button is-primary' : 'has-background-grey-lighter'"
                                class="button has-shadow">
                                <b-icon pack="fas" icon="car"></b-icon>
                            </b-button>
                            <p class="is-size-7 mt-3" :class="travelMode == 'DRIVING' ? 'has-text-primary' : 'has-text-grey-light'">{{ $t('components.routes.car') }}</p>
                        </div>
                    </div>
                    <div v-if="travelMode == 'TRANSIT'" class="is-flex is-flex-direction-column gap-4 is-align-items-flex-start">
                        <b-select v-model="timeType" :placeholder="$t('components.routes.leaveNow')">
                            <option value="leaveNow">{{ $t('components.routes.leaveNow') }}</option>
                            <option value="departAt">{{ $t('components.routes.departAt') }}</option>
                            <option value="arrivedBy">{{ $t('components.routes.arriveAt') }}</option>
                        </b-select>
                        <b-datetimepicker
                            v-if="showTimePicker"
                            :min-datetime="new Date()"
                            :mobile-native="false"
                            v-model="selectedTime"
                            placeholder="Click to select..."
                            :icon-right="selectedTime ? 'close-circle' : ''"
                            icon-right-clickable
                            @icon-right-click="clearDateTime"
                            horizontal-time-picker>
                        </b-datetimepicker>
                    </div>
                </div>
                <b-button :disabled="isDisabled" @click="sendTo()" expanded class="submit-button has-text-weight-lighter has-shadow" type="is-primary">
                    <p class="is-size-6">{{ $t('components.routes.routesbutton') }}</p>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import TransportResults from '@/components/transport/TransportResults.vue';
    import AutoRouteResult from '@/components/routes/AutoRouteResult.vue';
    import { Geolocation } from '@capacitor/geolocation';
    import _ from 'lodash';
    import { useMapStore } from '@/store/map';
    import { useAuthStore } from '@/store/auth';
    import useGeolocation from '@/mixins/useGeolocation';

    export default {
        name: 'RouteResearch',
        components: {
            TransportResults,
            AutoRouteResult,
        },

        data() {
            return {
                showWeekNumber: false,
                enableSeconds: false,
                hourFormat: undefined, // Browser locale
                locale: undefined, // Browser locale
                firstDayOfWeek: undefined, // 0 - Sunday

                routeType: 'manual',
                destination: null,
                origin: null,
                selectedOrigin: null,
                selectedDestination: null,
                travelMode: 'DRIVING',
                selectedTime: new Date(),
                showTime: false,
                timeType: 'leaveNow',
                mapStore: useMapStore(),
                authStore: useAuthStore(),
                useGeolocation: useGeolocation(),
            };
        },
        computed: {
            placeholderOrigin() {
                return _.get(this.mapStore.getRouteOrigin, 'formatted_address', null);
            },
            placeholderDestination() {
                return _.get(this.mapStore.getRouteDestination, 'formatted_address', null);
            },
            showTimePicker() {
                return this.timeType != 'leaveNow';
            },
            isDisabled() {
                return !this.destination && !this.origin;
            },
        },
        created() {
            this.selectedDestination = _.get(this.mapStore.getRouteDestination, 'name', _.get(this.mapStore.getRouteDestination, 'formatted_address', ''));
            this.destination = this.mapStore.getRouteDestination;
        },
        methods: {
            clearDateTime() {
                this.selectedTime = null;
            },
            setStadium() {
                this.destination = {
                    name: 'Estádio do Sport Lisboa e Benfica',
                    formatted_address: 'Estádio do Sport Lisboa e Benfica',
                    geometry: {
                        location: {
                            lat: 38.752711,
                            lng: -9.1869627,
                        },
                    },
                };
                this.selectedDestination = this.destination.formatted_address;
                this.mapStore.setRouteDestination(this.destination);
            },
            async sendTo() {
                this.mapStore.setRouteResult(null);
                this.mapStore.setRouteTime(this.selectedTime);
                this.mapStore.setRouteTravelMode(this.travelMode);
                this.mapStore.setRouteTimeType(this.timeType);

                let position = null;

                if (!this.origin || !this.destination) {
                    position = await this.useGeolocation.getPosition();
                }

                if (!this.origin) {
                    this.mapStore.setRouteOrigin({
                        geometry: {
                            location: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            },
                        },
                    });
                } else {
                    this.mapStore.setRouteOrigin(this.origin);
                }

                if (!this.destination) {
                    this.mapStore.setRouteDestination({
                        geometry: {
                            location: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            },
                        },
                    });
                } else {
                    this.mapStore.setRouteDestination(this.destination);
                }

                if (['DRIVING', 'WALKING', 'BICYCLING'].includes(this.travelMode)) {
                    return this.$router.push({ name: 'PathDirection' });
                } else if (['TRANSIT'].includes(this.travelMode)) {
                    return this.$router.push({ name: 'TransitRoutes' });
                }
            },
            setOrigin(place) {
                this.origin = place;
                this.selectedOrigin = place.formatted_address;
            },
            setDestination(place) {
                this.destination = place;
                this.selectedDestination = place.formatted_address;
            },
            setTravelMode(mode) {
                this.travelMode = mode;
            },
            reverseOriginDestination() {
                this.selectedDestination = _.get(this.origin, 'formatted_address', '');
                this.selectedOrigin = _.get(this.destination, 'formatted_address', '');

                let aux = _.cloneDeep(this.origin);
                this.origin = _.cloneDeep(this.destination);
                this.destination = aux;
            },
            onClearDestination() {
                this.destination = null;
                this.selectedDestination = null;
                this.mapStore.setRouteDestination(null);
            },
            onClearOrigin() {
                this.origin = null;
                this.selectedOrigin = null;
                this.mapStore.setRouteOrigin(null);
            },
        },
    };
</script>

<style scoped>
    .is-60x60 {
        width: 3.75rem;
        height: 3.75rem;
    }

    .transport-buttons {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: space-between;
        gap: 1rem;
    }
</style>
