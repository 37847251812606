<template>
    <section class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.push({ name: isDesktop ? 'Main-Profile' : 'Profile' })" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">
                    {{ $t('views.profile.settings.title') }}
                </h1>
                <div></div>
            </div>
            <div class="is-flex is-flex-direction-column fill-height gap-6">
                <b-button
                    @click="$router.push({ name: isDesktop ? 'Main-Language' : 'Language' })"
                    expanded
                    class="button has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-blue-light has-background-blue-lighter has-icon-radius py-5 px-5" pack="fas" icon="globe"></b-icon>
                    {{ $t('views.profile.settings.language') }}
                    <b-icon class="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>

                <div v-if="false" class="card">
                    <header class="card-header py-2">
                        <b-icon class="ml-2 mr-2" type="is-blue-light has-background-blue-lighter has-icon-radius py-5 px-5" pack="fas" icon="moon"></b-icon>
                        <p class="card-header-title has-text-weight-normal has-text-grey is-family-primary">
                            {{ $t('views.profile.settings.darkmode') }}
                        </p>
                        <b-switch v-model="darkmode" @input="toggleDarkMode" class="is-size-7 mr-5"></b-switch>
                    </header>
                </div>
                <div v-if="false" class="card">
                    <header class="card-header py-2">
                        <b-icon class="ml-2 mr-2" type="is-blue-light has-background-blue-lighter has-icon-radius py-5 px-5" pack="fas" icon="wifi"></b-icon>
                        <p class="card-header-title has-text-weight-normal has-text-grey is-family-primary">
                            {{ $t('views.profile.settings.justwifi') }}
                        </p>
                        <b-switch class="is-size-7 mr-5"></b-switch>
                    </header>
                </div>
                <b-button
                    @click="$router.push({ name: isDesktop ? 'Main-Notifications' : 'Notifications' })"
                    expanded
                    class="button has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-blue-light has-background-blue-lighter has-icon-radius py-5 px-5" pack="fas" icon="bell"></b-icon>
                    {{ $t('views.profile.settings.notifications') }}
                    <b-icon class="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>
                <b-button
                    v-if="false"
                    @click="$router.push({ name: isDesktop ? 'Main-Privacy' : 'Privacy' })"
                    expanded
                    class="button has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-blue-light has-background-blue-lighter has-icon-radius py-5 px-5" pack="fas" icon="lock"></b-icon>
                    {{ $t('views.profile.settings.privacy') }}
                    <b-icon class="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>
                <b-button
                    v-if="false"
                    @click="$router.push({ name: isDesktop ? 'Main-Contacts' : 'Contacts' })"
                    expanded
                    class="button has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-blue-light has-background-blue-lighter has-icon-radius py-5 px-5" pack="fas" icon="comment-dots"></b-icon>
                    {{ $t('views.profile.settings.contacts') }}
                    <b-icon class="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>
            </div>
        </div>
    </section>
</template>
<script>
    import { useAppStore } from '@/store/app';
    import { useAuthStore } from '@/store/auth';
    import useDesktop from '@/mixins/useDesktop';

    export default {
        name: 'Settings',
        data() {
            return {
                darkmode: false,
                appStore: useAppStore(),
                authStore: useAuthStore(),
                useDesktop: useDesktop(),
            };
        },
        created() {
            this.darkmode = this.appStore.getDarkMode;
        },
        computed: {
            isDesktop() {
                return this.useDesktop.isDesktop;
            },
        },
        methods: {
            toggleDarkMode() {
                this.appStore.setDarkMode(this.darkmode);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';

    .card-header {
        border-radius: 15px;
        height: auto;
    }
    .card {
        border-radius: 15px;
    }
</style>
