import { render, staticRenderFns } from "./TransportResearch.vue?vue&type=template&id=20b3c29a&"
import script from "./TransportResearch.vue?vue&type=script&lang=js&"
export * from "./TransportResearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports