<template>
    <div @click="setSelectedSpot" class="column is-8-mobile is-8-tablet mt-2">
        <div :class="!isSelected ? 'has-background-white' : 'has-background-primary-light'" class="box has-radius">
            <h1 :class="!isSelected ? 'has-text-grey' : 'has-text-primary'" class="has-text-weight-medium is-size-7 has-text-left mt-1">{{ spot.name }}</h1>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
        props: {
            selectedFloor: {
                default: null,
            },
            spot: {
                default: null,
            },
            selectedSection: {
                default: null,
            },
        },
        computed: {
            isSelected() {
                return this.spot.name == this.$route.query.spot;
            },
        },
        methods: {
            setSelectedSpot() {
                // if (this.isSelected) return this.$store.commit('park/setSelectedPark', { spot: null, section: null, floor: null });
                // this.$store.commit('park/setSelectedPark', { spot: this.spot.id, section: this.selectedSection, floor: this.selectedFloor });

                this.$emit('selected-spot', this.spot);
                // add the spot.name to the $route query
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        spot: this.spot.name,
                    },
                });
            },
        },
    };
</script>

<style scoped>
    .box {
        border: 0.5px solid hsl(0, 0%, 71%);
        width: 100px;
        height: 49px;
    }
</style>
