<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-8">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.recent.title') }}</h1>
                <div></div>
            </div>

            <div class="scrollable-content mt-2">
                <template v-if="recents.length > 0">
                    <div v-for="(recent, index) of getRecents" :key="index">
                        <ParkInformation v-if="recent.type == 'parkingLot'" :park="recent.parkingLot"></ParkInformation>
                        <StopInformation v-if="recent.type == 'stop'" :stop="recent.stop"></StopInformation>
                        <hr class="mt-4 mb-0" />
                    </div>
                </template>
                <h1 v-else-if="recents.length > 0 && !isLoading">{{ $t('components.recent.noRecent') }}!</h1>
            </div>
        </div>
    </div>
</template>

<script>
    import ParkInformation from '@/components/ui/ParkInformation.vue';
    import StopInformation from '@/components/ui/StopInformation.vue';

    import _ from 'lodash';
    import { useRecentsStore } from '@/store/recents';
    import useParks from '@/mixins/useParks';
    import useStops from '@/mixins/useStops';

    export default {
        name: 'RecentNav',
        components: {
            ParkInformation,
            StopInformation,
        },
        data() {
            return {
                parks: [],
                stops: [],
                recentsStore: useRecentsStore(),
                useParks: useParks(),
                useStops: useStops(),
            };
        },
        created() {
            this.getParkList();
            this.getStopsList();
        },
        methods: {
            async getParkList() {
                const { data, meta } = await this.useParks.getParksByQuery(this.recentsQuery('parkingLot'));
                this.parks = data;
            },
            async getStopsList() {
                const { data, meta } = await this.useStops.getStopsByQuery(this.recentsQuery('stop'));
                this.stops = data;
            },
            recentsQuery(type) {
                return {
                    filters: {
                        id: {
                            $in: this.recents.filter((r) => r.type == type).map((recent) => recent.id),
                        },
                    },
                };
            },
        },
        computed: {
            recents() {
                return this.recentsStore.getRecents.slice(0, 8);
            },
            getRecents() {
                const recents = _.cloneDeep(this.recents);
                for (let recent of recents) {
                    if (recent.type == 'parkingLot') {
                        recent.parkingLot = this.parks.find((park) => park.id == recent.id);
                    } else if (recent.type == 'stop') {
                        recent.stop = this.stops.find((stop) => stop.id == recent.id);
                    }
                }
                return recents;
            },
        },
    };
</script>
