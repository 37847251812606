<template>
    <section class="hero is-fullheight is-relative is-clipped">
        <Map />
        <b-button
            v-if="hasSubscribedTrip"
            @click="sendTo('TrackInsideBus')"
            rounded
            type="is-medium is-grey-light has-text-primary "
            class="follow-button"
            icon-pack="fas"
            icon-left="bell" />

        <!-- <AllowLocation /> -->
        <InstallApp />

        <b-tag v-if="isMapFiltered" rounded size="is-medium" type="is-dark" class="filters-tag" aria-close-label="Close tag" closable @close="resetFilters">{{
            $t('components.mapSettings.appliedFilters')
        }}</b-tag>

        <div class="sidebuttons">
            <b-button @click="sendTo('MapSettings')" expanded type="is-medium is-primary has-text-white" class="mapsettings" icon-pack="fas" icon-right="map-location-dot" />
            <b-button @click="centerLocation" expanded type="is-medium is-primary has-text-white" class="location" icon-pack="fas" icon-right="location-crosshairs" />
        </div>
        <transition name="slide-fade">
            <div v-if="appStore.getIsShowOpen" class="collapse-body" :class="`${cardSize} ${hideBlueLine ? 'dark' : ''}`">
                <div class="columns is-mobile is-multiline is-centered mb-5 div-blue-line" @click="$router.push({ name: 'BottomMenu' }), appStore.setIsShowOpen(false)">
                    <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet">
                        <div>
                            <hr class="blueline mb-0" />
                        </div>
                    </div>
                </div>
                <router-view />
            </div>
        </transition>
        <nav ref="bottomMenu" role="navigation" :class="{ expanded: expandedMenu }">
            <b-button class="hide-touch menu-button" :class="expandedMenu ? 'ml-auto' : 'mx-auto'" @click="toggleMenu">
                <b-icon v-if="expandedMenu" pack="fas" icon="close"></b-icon>
                <b-icon v-else pack="fas" icon="bars"></b-icon>
            </b-button>
            <figure class="image hide-touch is-128x128 mx-auto" v-show="expandedMenu">
                <img src="@/assets/img/logo/ioCity_logo.png" />
            </figure>
            <div>
                <a @click="sendTo('Research')" class="p-0 m-0" :class="{ active: activeTab == 'research' }">
                    <b-icon :size="isDesktop ? '' : 'is-medium'" class="has-icon-radius py-5 px-5" pack="fas" icon="star"></b-icon>
                    <span v-if="expandedMenu && isDesktop" class="has-text-weight-semibold">{{ $t('views.bottomMenu.home') }}</span>
                </a>
                <a @click="sendTo('TransportResearch')" class="p-0 m-0" :class="{ active: activeTab == 'transport' }">
                    <b-icon :size="isDesktop ? '' : 'is-medium'" class="has-icon-radius py-5 px-5" pack="fas" icon="bus"></b-icon>
                    <span v-if="expandedMenu && isDesktop" class="has-text-weight-semibold">{{ $t('views.bottomMenu.transports') }}</span>
                </a>
                <a @click="sendTo('NearMe')" class="p-0 m-0" :class="{ active: activeTab == 'nearme' }">
                    <figure v-if="!isDesktop" class="image nearme is-clickable">
                        <img @load="onImgLoaded" class="is-rounded" :src="isDarkMode ? require('@/assets/img/icons/nearme_darkmode.png') : require('@/assets/img/icons/nearme.png')" />
                    </figure>
                    <b-icon v-if="isDesktop" :size="isDesktop ? '' : 'is-medium'" pack="fas" icon="location-crosshairs" class="has-icon-radius py-5 px-5"></b-icon>
                    <span v-if="expandedMenu && isDesktop" class="has-text-weight-semibold">{{ $t('views.bottomMenu.nearMe') }}</span>
                </a>

                <a @click="sendTo('ParkingResearch')" class="p-0 m-0" :class="{ active: activeTab == 'park' }">
                    <b-icon :size="isDesktop ? '' : 'is-medium'" class="has-icon-radius py-5 px-5" pack="fas" icon="p"></b-icon>
                    <span v-if="expandedMenu && isDesktop" class="has-text-weight-semibold">{{ $t('views.bottomMenu.parks') }}</span>
                </a>
                <a @click="sendTo('RouteResearch')" class="p-0 m-0" :class="{ active: activeTab == 'route' }">
                    <b-icon :size="isDesktop ? '' : 'is-medium'" class="has-icon-radius py-5 px-5" pack="fas" icon="route"></b-icon>
                    <span v-if="expandedMenu && isDesktop" class="has-text-weight-semibold">{{ $t('views.bottomMenu.routes') }}</span>
                </a>
            </div>
            <div @click.stop="sendTo(isDesktop ? 'Main-Profile' : 'Profile')" class="mt-auto is-flex is-flex-direction-row is-justify-content-flex-start gap-4 cursor-pointer">
                <figure
                    @click.stop="sendTo(isDesktop ? 'Main-Profile' : 'Profile')"
                    class="image profile-picture cursor-pointer"
                    :style="{
                        'background-image': `url(${getPic})`,
                    }"></figure>
                <span v-if="expandedMenu && isDesktop" class="has-text-weight-semibold">{{ authStore.getUser.name }}</span>
            </div>
        </nav>
    </section>
</template>

<script>
    import Research from '@/components/search/Research.vue';
    import TransportResearch from '@/components/transport/TransportResearch.vue';
    import ParkingResearch from '@/components/parking/ParkingResearch.vue';
    import NearMe from '@/components/nearme/NearMe.vue';
    import RouteResearch from '@/components/routes/RouteResearch.vue';
    import Map from '@/views/Map/Map.vue';
    import PathDirection from '@/components/directions/PathDirection.vue';
    import ArrivedPark from '@/components/parking/ArrivedPark.vue';
    import RouteDirection from '@/components/directions/RouteDirection.vue';
    import RouteDetails from '@/components/routes/RouteDetails.vue';
    import CompletedRoute from '@/components/routes/CompletedRoute.vue';
    import MapSettings from '@/components/map/MapSettings.vue';
    import AllowNotification from '@/components/notifications/AllowNotification.vue';
    import AllowMicrophone from '@/components/notifications/AllowMicrophone.vue';
    import ErrorNotification from '@/components/notifications/ErrorNotification.vue';
    import ContinueRoute from '@/components/notifications/ContinueRoute.vue';
    import NoConnection from '@/components/notifications/NoConnection.vue';
    import NoGPS from '@/components/notifications/NoGPS.vue';
    import Connection from '@/components/notifications/Connection.vue';
    import CalcutateRoute from '@/components/notifications/CalcutateRoute.vue';
    import AllowLocation from '@/components/notifications/AllowLocation.vue';
    import NotificationsList from '@/components/notifications/NotificationsList.vue';
    import InstallApp from '@/components/notifications/InstallApp.vue';

    //MUDAR PARA LOGIN
    import IncorrectData from '@/components/notifications/IncorrectData.vue';
    import NotRegistered from '@/components/notifications/NotRegistered.vue';
    import LogoutConfirm from '@/components/notifications/LogoutConfirm.vue';
    import RegisteredEmail from '@/components/notifications/RegisteredEmail.vue';
    import _ from 'lodash';
    import useDesktop from '@/mixins/useDesktop';

    import { useAppStore } from '@/store/app';
    import { useAuthStore } from '@/store/auth';
    import { useMapStore } from '@/store/map';
    import { useTripStore } from '@/store/trip';

    export default {
        name: 'BottomMenu',
        components: {
            Research,
            TransportResearch,
            ParkingResearch,
            NearMe,
            RouteResearch,
            Map,
            PathDirection,
            ArrivedPark,
            RouteDirection,
            RouteDetails,
            CompletedRoute,
            MapSettings,
            AllowNotification,
            AllowMicrophone,
            IncorrectData,
            NotRegistered,
            ErrorNotification,
            ContinueRoute,
            NoConnection,
            NoGPS,
            LogoutConfirm,
            RegisteredEmail,
            Connection,
            CalcutateRoute,
            AllowLocation,
            NotificationsList,
            InstallApp,
        },
        data() {
            return {
                sound: true,
                isDarkMode: false,
                expandedMenu: true,
                appStore: useAppStore(),
                authStore: useAuthStore(),
                mapStore: useMapStore(),
                useDesktop: useDesktop(),
                tripStore: useTripStore(),
            };
        },
        create() {
            this.isDarkMode = this.appStore.getDarkMode;
        },
        computed: {
            hasSubscribedTrip() {
                return !!this.tripStore.getSubscriptionId;
            },
            isMapFiltered() {
                return this.mapStore.getShowFavorites || !this.mapStore.getShowParks || !this.mapStore.getShowStops;
            },
            getPic() {
                return _.get(this.authStore.getUser, 'picture.formats.thumbnail.url', _.get(this.authStore.getUser, 'picture.url', require('@/assets/img/logo/profile.png')));
            },
            getNearMeDesktop() {
                if (['NearMe'].includes(this.$route.name)) {
                    return 'is-white has-background-primary has-text-white';
                }
                return ' has-background-transparent';
            },
            getResearchType() {
                //if window width is less than 768px

                if (['Research', 'ResearchFavorites', 'ResearchRecents', 'ResearchResults'].includes(this.$route.name)) {
                    return 'is-primary has-background-white';
                }
                return 'is-white';
            },
            getResearchTypeDesktop() {
                if (['Research', 'ResearchFavorites', 'ResearchRecents', 'ResearchResults'].includes(this.$route.name)) {
                    return 'is-white has-background-primary has-text-white';
                }
                return ' has-background-transparent';
            },
            getTrasnportType() {
                if (['TransportResearch', 'TransportResults', 'TrackInsideBus'].includes(this.$route.name)) {
                    return 'is-primary has-background-white';
                }
                return 'is-white';
            },
            getTrasnportTypeDesktop() {
                if (['TransportResearch', 'TransportResults', 'TrackInsideBus'].includes(this.$route.name)) {
                    return 'is-white has-background-primary has-text-white';
                }
                return ' has-background-transparent';
            },
            getParkType() {
                if (['ParkingResearch', 'ParkDetails'].includes(this.$route.name)) {
                    return 'is-primary has-background-white';
                }
                return 'is-white';
            },
            getParkTypeDesktop() {
                if (['ParkingResearch', 'ParkDetails'].includes(this.$route.name)) {
                    return 'is-white has-background-primary has-text-white';
                }
                return ' has-background-transparent';
            },
            getRouteType() {
                if (['RouteResearch', 'AutomaticRoutes'].includes(this.$route.name)) {
                    return 'is-primary has-background-white';
                }
                return 'is-white';
            },
            getRouteTypeDesktop() {
                if (['RouteResearch', 'AutomaticRoutes'].includes(this.$route.name)) {
                    return 'is-white has-background-primary has-text-white';
                }
                return ' has-background-transparent';
            },
            cardSize() {
                // get the meta "cardSize" in the route
                return this.appStore.getCardSize;
            },
            activeTab() {
                // get the meta "activeTab" in current route or parent route
                return this.$route.meta.activeTab || this.$route.matched[0].meta.activeTab;
            },
            isDesktop() {
                return this.useDesktop.isDesktop;
            },
            hideBlueLine() {
                return this.$route.matched.some((route) => route.meta.hideBlueLine);
            },
        },
        created() {
            // commit on store
            this.appStore.setIsShowOpen(this.$route.matched.length > 1);
        },
        methods: {
            onImgLoaded() {
                let navHeight = document.querySelector('nav').offsetHeight;
                const figureNearMe = document.querySelector('nav figure.nearme').offsetHeight;
                navHeight += figureNearMe;

                document.documentElement.style.setProperty('--navHeight', `${navHeight}px`);
            },
            sendTo(name) {
                if (name == this.$route.name) {
                    this.$router.push({ name: 'BottomMenu' });
                    this.appStore.setIsShowOpen(false);
                    return;
                }
                this.appStore.setIsShowOpen(true);
                this.$router.push({ name });
            },
            centerLocation() {
                this.mapStore.toggleCenterCoords();
            },
            resetFilters() {
                this.mapStore.setShowFavorites(false);
                this.mapStore.setShowParks(true);
                this.mapStore.setShowStops(true);
                this.mapStore.setShowRoutes(true);
            },
            toggleMenu() {
                this.expandedMenu = !this.expandedMenu;
            },
        },
    };
</script>

<style scoped lang="scss">
    //import _variables.scss
    @import '@/assets/scss/_variables.scss';

    section {
        justify-content: flex-end;
    }

    .collapse-body {
        background-color: white;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top-left-radius: 2.5rem;
        border-top-right-radius: 2.5rem;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);

        margin-bottom: -2rem;
        padding-bottom: 2rem;

        max-height: 80vh;
        max-height: 90dvh;

        &.dark {
            background-color: $grey-darker;
        }

        &.x-large {
            height: 80vh;
            height: 90dvh;
        }

        &.large {
            height: 47rem;
        }

        &.medium {
            height: 38rem;
        }

        &.medium-small {
            height: 34rem;
        }

        &.small {
            height: 31rem;
        }
    }

    .slide-fade-enter-to,
    .slide-fade-leave {
        transform: translateY(0%);
    }

    .slide-fade-leave-active,
    .slide-fade-enter-active {
        transition: all 0.5s;
    }

    .slide-fade-leave-to,
    .slide-fade-enter {
        transform: translateY(100%);
    }

    nav {
        min-height: 1rem;
        max-height: 5.625rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top-right-radius: 1.5rem;
        border-top-left-radius: 1.5rem;
        transition: transform all 0.3s ease-out;
        background-color: $primary !important;
        // add shadow
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);

        & > div {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            a {
                color: white;

                &.active:not(:nth-of-type(3)) {
                    background-color: $white;
                    color: $primary;
                    border-radius: 0.75rem;
                }
            }
        }
        .nearme {
            position: relative;
            transform: translateY(-50%);

            img {
                max-width: 90%;
                max-height: unset !important;
                margin-inline: auto;
            }
        }
    }
    .profile-picture {
        width: 4.5rem;
        height: 4.5rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 999999px;
        position: fixed;
        top: 1.5rem;
        right: 1.5rem;
        img {
            object-fit: cover;
        }
    }

    .sidebuttons {
        position: absolute;
        right: 0;
        top: 10rem;

        .mapsettings {
            border-top-right-radius: 0em 0em;
            border-bottom-right-radius: 0em 0em;
            border-bottom-left-radius: 0em 0em;
        }

        .location {
            border-top-right-radius: 0em 0em;
            border-top-left-radius: 0em 0em;
            border-bottom-right-radius: 0em 0em;
            margin-top: 1px;
        }
    }
    .div-blue-line {
        cursor: pointer;
    }

    .follow-button {
        position: fixed;
        top: 1.5rem;
        left: 1.5rem;
    }

    .filters-tag {
        position: fixed;
        top: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: 60em) {
        .hide-touch {
            display: none !important;
        }
    }

    @media (min-width: 60em) {
        .hide-desktop {
            display: none !important;
        }

        .slide-fade-enter-to,
        .slide-fade-leave {
            transform: translateX(0%);
        }
        .slide-fade-leave-to,
        .slide-fade-enter {
            transform: translateX(-100%);
        }
        section {
            flex-direction: row;
            justify-content: flex-start;
        }
        .collapse-body {
            position: sticky;
            order: 1;
            border-top-left-radius: 0;
            border-bottom-right-radius: 1.5rem;
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
            width: 30rem;
            padding-top: 2rem;
            &.x-large,
            &.large,
            &.medium,
            &.medium-small,
            &.small {
                height: 100%;
                max-height: 100%;
            }

            .div-blue-line {
                position: absolute;
                top: 50%;
                right: 0;
                transform: rotate(90deg) translateY(-75%);
            }
        }
        nav {
            order: 0;
            position: sticky;
            height: 100%;
            max-height: unset;
            width: 5rem;
            padding: 1.5rem 0.75rem;
            background-color: $white-ter !important;
            border-radius: 0;
            transition: width 0.3s ease-out;
            box-shadow: -9px 0px 20px rgba(0, 0, 0, 0.25);

            display: flex;
            flex-direction: column;
            gap: 2rem;

            .menu-button {
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .profile-picture {
                position: static;
                width: 3rem;
                height: 3rem;
                margin-inline: auto;
            }

            & > div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                a {
                    color: $grey !important;
                    width: 100%;
                    display: grid;
                    place-items: center;
                    grid-template-columns: 1fr 2fr;
                    // gap: 0.5rem;

                    // on hover
                    &:hover {
                        color: $primary !important;
                    }

                    // on active
                    &.active {
                        background-color: $primary !important;
                        color: $white !important;
                        border-radius: 0 2rem 2rem 0 !important;
                        margin-left: -1.5rem !important;
                        padding-left: 0.75rem !important;
                    }

                    & > span:nth-of-type(1) {
                        justify-self: center;
                    }

                    & > span:nth-of-type(2) {
                        justify-self: flex-start;
                    }

                    &:nth-of-type(1) {
                        order: 1;
                    }
                    &:nth-of-type(2) {
                        order: 2;
                    }
                    &:nth-of-type(3) {
                        order: 0;
                    }
                    &:nth-of-type(4) {
                        order: 3;
                    }
                    &:nth-of-type(5) {
                        order: 4;
                    }
                }
            }

            &.expanded {
                width: 17rem;
                .profile-picture {
                    margin: unset;
                }
            }
        }
    }
</style>
