<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.transport.title') }}</h1>
                <div></div>
            </div>
            <div class="scrollable-content">
                <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"> <b-icon custom-class="fa-spin" pack="fas" icon="spinner" size="is-large"> </b-icon></b-loading>
                <TransportSchedule v-if="trip" :trip="trip" />
                <b-button v-if="!hasSubscribed" :loading="isLoadingSubscribe" @click="followTransport" expanded class="mt-4 has-text-weight-lighter has-shadow" type="is-primary">
                    <p class="is-size-6">{{ $t('views.route.follow') }}</p>
                </b-button>
                <p v-else class="mt-4 has-text-weight-bold">
                    {{ $t('views.route.followed') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '@/plugins/axios';
    import TransportSchedule from '@/components/ui/TransportSchedule.vue';
    import qs from 'qs';
    import { useAuthStore } from '@/store/auth';
    import { useNotifications } from '@/mixins/useNotifications';
import { useTripStore } from '@/store/trip';

    export default {
        components: {
            TransportSchedule,
        },
        data() {
            return {
                trip: null,
                isLoading: false,
                isLoadingSubscribe: false,
                hasSubscribed: false,
            };
        },
        async created() {
            this.fetchTrip();
            this.fetchSubscription();
        },
        methods: {
            async fetchTrip() {
                this.isLoading = true;

                const query = {
                    populate: {
                        transport: {
                            populate: {
                                agency: {
                                    populate: {
                                        pictures: true,
                                    },
                                },
                            },
                        },
                        route: true,
                        stop_times: {
                            filters: {
                                id: {
                                    $in: [this.$route.query.from, this.$route.query.to],
                                },
                            },
                        },
                    },
                };

                await axios.get(`/mobility-trips/${this.$route.query.trip}?${qs.stringify(query)}`).then((response) => {
                    this.isLoading = false;
                    this.trip = response.data.data;
                    const fromStopTime = this.trip.stop_times.find((stopTime) => stopTime.id == this.$route.query.from);
                    const toStopTime = this.trip.stop_times.find((stopTime) => stopTime.id == this.$route.query.to);
                    this.trip.stop_times = [fromStopTime, toStopTime];
                });
            },
            async fetchSubscription() {
                let token = await useNotifications().getToken();
                const query = {
                    filters: {
                        trip: this.$route.query.trip,
                        fromStopTime: this.$route.query.from,
                        toStopTime: this.$route.query.to,
                        token,
                    },
                };

                await axios.get(`/subscription-transports?${qs.stringify(query)}`).then((response) => {
                    this.hasSubscribed = response.data.data.length > 0;
                    if (this.hasSubscribed) {
                        this.tripStore.setSubscriptionId(response.data.data[0].id);
                    }
                });
            },
            async followTransport() {
                this.isLoadingSubscribe = true;
                let token = await useNotifications().getToken();

                await axios
                    .post('/subscription-transports', {
                        data: {
                            trip: this.trip.id,
                            fromStopTime: this.trip.stop_times[0].id,
                            toStopTime: this.trip.stop_times[1].id,
                            user: this.authStore.getUser?.id || null,
                            token,
                            language: this.$i18n.locale,
                        },
                    })
                    .then((response) => {
                        this.hasSubscribed = true;
                        this.tripStore.setSubscriptionId(response.data.data.id);
                    }).catch((error) => {
                        throw error;
                    });

                this.isLoadingSubscribe = false;
            },
        },
        setup() {
            const authStore = useAuthStore();
            const tripStore = useTripStore();
            return {
                authStore,
                tripStore,
            };
        },
    };
</script>

<style lang="scss" scoped></style>
