<template>
    <GmapMap key="mapMode" class="map-size" :zoom="mapZoom" ref="mapRef" :center="centerLocation" :options="options" @click="$router.push({ name: 'BottomMenu' }), appStore.setIsShowOpen(false)">
        <div v-if="mapStore.getShowParks == true">
            <GmapMarker
                :key="index"
                v-for="(park, index) in filteredParks"
                :position="{ lat: park.latitude, lng: park.longitude }"
                :clickable="true"
                :draggable="false"
                :icon="getMarkers(park)"
                :zIndex="getZindex(park.id, 'ParkDetails')"
                @click="$router.push({ name: 'ParkDetails', params: { id: park.id } }), appStore.setIsShowOpen(true)" />
        </div>

        <div v-if="mapStore.getShowStops == true">
            <GmapMarker
                :key="index"
                v-for="(stops, index) in filteredStops"
                :position="{ lat: stops.lat, lng: stops.lng }"
                :clickable="true"
                :draggable="false"
                :icon="getBusMarkers(stops)"
                :class="$route.name == { name: 'BusStop', params: { id: stops.id } } ? 'active' : 'not-active'"
                :zIndex="getZindex(stops.id, 'BusStop')"
                @click="$router.push({ name: 'BusStop', params: { id: stops.id } }), appStore.setIsShowOpen(true)" />
        </div>

        <div v-if="mapStore.getShowRoutes">
            <GmapMarker
                v-for="(bus, index) in buses"
                :key="index"
                @click="$router.push({ name: 'BusDetails', params: { id: bus.transport.id } })"
                :position="{ lat: bus.latitude, lng: bus.longitude }"
                :clickable="true"
                :draggable="false"
                :icon="busStop" />
        </div>
        <gmap-info-window :options="infoOptions" :position="myCarCoords" :opened="myCarLocation && myCarLocation.location && myCarLocation.location.lat != 0" @closeclick="parkStore.resetSavedSpot()">
            <h1 class="is-size-5 has-text-weight-semibold">{{ $t('views.map.carHere') }}</h1>
            <h1>{{ myCarLocation.text }}</h1>
        </gmap-info-window>
        <DirectionsRenderer v-if="mapStore.getShowDirections" travelMode="DRIVING" :origin="startLocation" :destination="endLocation" />
    </GmapMap>
</template>

<script>
    import DirectionsRenderer from '@/views/Map/DirectionsRenderer';
    import _ from 'lodash';

    import { useMapStore } from '@/store/map';
    import useGeolocation from '@/mixins/useGeolocation';
    import { useAppStore } from '@/store/app';
    import { useParkStore } from '@/store/park';
    import { useStopStore } from '@/store/stop';
    import { useAuthStore } from '@/store/auth';
    import useParks from '@/mixins/useParks';
    import useStops from '@/mixins/useStops';
    import { getBuses } from '@/api/bus';

    export default {
        components: {
            DirectionsRenderer,
        },
        data() {
            return {
                mapStore: useMapStore(),
                appStore: useAppStore(),
                parkStore: useParkStore(),
                stopStore: useStopStore(),
                authStore: useAuthStore(),
                useGeolocation: useGeolocation(),
                useParks: useParks(),
                useStops: useStops(),
                parks: [],
                stops: [],
                buses: [],
                markerOptions: {
                    url: require('@/assets/img/map/trainstop.svg'),
                    size: { width: 60, height: 90, f: 'px', b: 'px' },
                    scaledSize: { width: 30, height: 45, f: 'px', b: 'px' },
                },

                //Bus Experimental
                busStop: {
                    url: require('@/assets/img/map/redBus.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },

                busPosition: { lat: 41.430573, lng: -8.512663 },

                options: {
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: false,
                    streetViewControl: true,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUI: false,
                    mapMarker: '',
                    gestureHandling: 'greedy',
                    styles: [],
                },
                startLocation: null,
                endLocation: null,
                markers: [],
                redMarker: {
                    url: require('@/assets/img/map/redPark.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                redMarkerActive: require('@/assets/img/map/redPark.svg'),
                orangeMarker: {
                    url: require('@/assets/img/map/orangePark.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                orangeMarkerActive: {
                    url: require('@/assets/img/map/orangePark.svg'),
                },
                yellowMarker: {
                    url: require('@/assets/img/map/yellowPark.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                yellowMarkerActive: {
                    url: require('@/assets/img/map/yellowPark.svg'),
                },
                greenMarker: {
                    url: require('@/assets/img/map/greenPark.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                greenMarkerActive: require('@/assets/img/map/greenPark.svg'),
                busMarker: {
                    url: require('@/assets/img/map/busstop.svg'),
                    scaledSize: {
                        width: 50,
                        height: 50,
                    },
                },
                busMarkerActive: {
                    url: require('@/assets/img/map/busstop.svg'),
                },
                trainMarker: {
                    url: require('@/assets/img/map/trainstop.svg'),
                    scaledSize: {
                        width: 50,
                        height: 50,
                    },
                },
                greenBusMarker: {
                    url: require('@/assets/img/map/greenBus.svg'),
                    scaledSize: {
                        width: 60,
                        height: 60,
                    },
                },
                yellowBusMarker: {
                    url: require('@/assets/img/map/greenBus.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                redBusMarker: {
                    url: require('@/assets/img/map/greenBus.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                orangeBusMarker: {
                    url: require('@/assets/img/map/greenBus.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                infoOptions: {
                    //optional: offset infowindow so it visually sits nicely on top of our marker
                    pixelOffset: {
                        width: 0,
                        height: -80,
                    },
                },
                infoWinOpen: true,
                mapZoom: 15,
                myCarLocation: {
                    text: '',
                    location: {
                        lat: 0,
                        lng: 0,
                    },
                },
            };
        },
        created() {
            this.myCarLocation =
                typeof this.parkStore.getSavedSpot == 'object'
                    ? this.parkStore.getSavedSpot
                    : {
                          text: '',
                          location: {
                              lat: 0,
                              lng: 0,
                          },
                      };
            // this.getBuses();
        },
        async mounted() {
            this.$refs.mapRef.$mapPromise.then((map) => {
                // create a loop until map.getBounds() is not null
                const interval = setInterval(() => {
                    if (map.getBounds()) {
                        let currentInterval = this.mapStore.getIntervalId;

                        try {
                            clearInterval(currentInterval);
                        } catch (error) {
                            console.log(error);
                        }

                        currentInterval = setInterval(() => {
                            this.getBuses();
                            this.searchHere();
                        }, 5 * 60 * 1000); // 5 minutes

                        this.mapStore.setIntervalId(currentInterval);
                        this.getBuses();
                        this.searchHere();
                        clearInterval(interval);
                    }
                }, 100);

                let boundsChangedTimeout = null;

                // listen for dragend event
                map.addListener('dragend', () => {
                    // execute searchHere() if it has not been executed in the last 500ms
                    if (boundsChangedTimeout) clearTimeout(boundsChangedTimeout);
                    boundsChangedTimeout = setTimeout(() => {
                        this.searchHere();
                    }, 500);
                });

                let zoomChangedTimeout = null;

                // listen for zoom out event
                map.addListener('zoom_changed', (a) => {
                    // execute searchHere() if it has not been executed in the last 500ms
                    if (zoomChangedTimeout) clearTimeout(zoomChangedTimeout);
                    zoomChangedTimeout = setTimeout(() => {
                        this.searchHere();
                    }, 500);
                });
            });
        },
        computed: {
            centerLocation() {
                if (_.has(this.mapStore.getCenterCoords, 'lat') && _.has(this.mapStore.getCenterCoords, 'lng')) {
                    return {
                        lat: this.mapStore.getCenterCoords.lat,
                        lng: this.mapStore.getCenterCoords.lng,
                    };
                }
            },
            myCarCoords() {
                return _.get(this.myCarLocation, 'location', {
                    lat: 0,
                    lng: 0,
                });
            },
            centerOnMe() {
                return this.mapStore.centerOnMe;
            },
            customCenter() {
                return this.mapStore.getCenterCoords;
            },
            filteredParks() {
                if (!this.mapStore.getShowFavorites) return this.parks;
                return this.parks.filter((park) => park.favoriteId);
            },
            filteredStops() {
                if (!this.mapStore.getShowFavorites) return this.stops;
                return this.stops.filter((stop) => stop.favoriteId);
            },
        },
        watch: {
            'parkStore.getSavedSpot': {
                handler: function (newValue) {
                    this.myCarLocation = newValue;
                },
                deep: true,
            },

            async centerOnMe() {
                const { coords } = await this.useGeolocation.getPosition();
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.panTo({ lat: coords.latitude, lng: coords.longitude });
                    map.setZoom(15);
                    this.searchHere();
                });
            },
            customCenter() {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.panTo({ lat: this.customCenter.lat, lng: this.customCenter.lng });
                    this.searchHere();
                });
            },
            '$store.getters': {
                handler: function (getters) {
                    if (getters['darkmode/getDarkMode']) {
                        this.options.styles = [
                            { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
                            { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
                            { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
                            {
                                featureType: 'administrative.locality',
                                elementType: 'labels.text.fill',
                                stylers: [{ color: '#d59563' }],
                            },
                            {
                                featureType: 'poi',
                                elementType: 'labels.text.fill',
                                stylers: [{ color: '#d59563' }],
                            },
                            {
                                featureType: 'poi.park',
                                elementType: 'geometry',
                                stylers: [{ color: '#263c3f' }],
                            },
                            {
                                featureType: 'poi.park',
                                elementType: 'labels.text.fill',
                                stylers: [{ color: '#6b9a76' }],
                            },
                            {
                                featureType: 'road',
                                elementType: 'geometry',
                                stylers: [{ color: '#38414e' }],
                            },
                            {
                                featureType: 'road',
                                elementType: 'geometry.stroke',
                                stylers: [{ color: '#212a37' }],
                            },
                            {
                                featureType: 'road',
                                elementType: 'labels.text.fill',
                                stylers: [{ color: '#9ca5b3' }],
                            },
                            {
                                featureType: 'road.highway',
                                elementType: 'geometry',
                                stylers: [{ color: '#746855' }],
                            },
                            {
                                featureType: 'road.highway',
                                elementType: 'geometry.stroke',
                                stylers: [{ color: '#1f2835' }],
                            },
                            {
                                featureType: 'road.highway',
                                elementType: 'labels.text.fill',
                                stylers: [{ color: '#f3d19c' }],
                            },
                            {
                                featureType: 'transit',
                                elementType: 'geometry',
                                stylers: [{ color: '#2f3948' }],
                            },
                            {
                                featureType: 'transit.station',
                                elementType: 'labels.text.fill',
                                stylers: [{ color: '#d59563' }],
                            },
                            {
                                featureType: 'water',
                                elementType: 'geometry',
                                stylers: [{ color: '#17263c' }],
                            },
                            {
                                featureType: 'water',
                                elementType: 'labels.text.fill',
                                stylers: [{ color: '#515c6d' }],
                            },
                            {
                                featureType: 'water',
                                elementType: 'labels.text.stroke',
                                stylers: [{ color: '#17263c' }],
                            },
                        ];
                    } else {
                        this.options.styles = null;
                    }
                },
                deep: true,
            },
        },
        methods: {
            getMarkers(park) {
                if (this.$route.params.id == park.id && this.$route.name == 'ParkDetails') {
                    if (park.freeLots / park.totalCapacity >= 0.6) {
                        return this.greenMarkerActive;
                    } else if (park.freeLots / park.totalCapacity >= 0.3) {
                        return this.yellowMarkerActive;
                    } else {
                        return this.redMarkerActive;
                    }
                } else {
                    if (park.freeLots / park.totalCapacity >= 0.6) {
                        return this.greenMarker;
                    } else if (park.freeLots / park.totalCapacity >= 0.3) {
                        return this.yellowMarker;
                    } else {
                        return this.redMarker;
                    }
                }
            },
            getBusMarkers(stops) {
                if (this.$route.params.id == stops.id && this.$route.name == 'BusStop') {
                    return this.busMarkerActive;
                } else {
                    return this.busMarker;
                }
            },

            getZindex(park_id, type) {
                if (this.$route.params.id == park_id && this.$route.name == type) {
                    return 1;
                } else {
                    return null;
                }
            },
            searchHere() {
                this.$refs.mapRef.$mapPromise.then(async (map) => {
                    const centerCoords = {
                        lat: map.getCenter().lat(),
                        lng: map.getCenter().lng(),
                    };
                    const bounds = map.getBounds();
                    const NE = {
                        lat: bounds.getNorthEast().lat(),
                        lng: bounds.getNorthEast().lng(),
                    };
                    const distance = this.useGeolocation.calculateDistance(centerCoords, NE);

                    this.useParks
                        .getParksByQuery({
                            latitude: centerCoords.lat,
                            longitude: centerCoords.lng,
                            calculateDistance: true,
                            calcRealDistance: false,
                            filters: {
                                distance: {
                                    $lte: distance,
                                },
                            },
                            pagination: {
                                pageSize: 100,
                            },
                        })
                        .then(({ data, meta }) => {
                            this.parks = data;
                        });

                    this.useStops
                        .getStopsByQuery({
                            latitude: centerCoords.lat,
                            longitude: centerCoords.lng,
                            calculateDistance: true,
                            calcRealDistance: false,
                            filters: {
                                distance: {
                                    $lte: distance,
                                },
                            },
                            pagination: {
                                pageSize: 100,
                            },
                        })
                        .then(({ data, meta }) => {
                            this.stops = data;
                        });
                });
            },
            getBuses() {
                getBuses({
                    populate: ['transport'],
                    pagination: {
                        pageSize: 100,
                    },
                }).then((response) => {
                    this.buses = response.data.data;
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .map-size {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        height: 100dvh;
    }
</style>
