<template>
    <div class="hero is-fullheight">
        <div class="hero-body">
            <div class="logo-name is-flex">
                <b-button @click="$router.push({ name: 'Login' })" size="is-40x40" class="button is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"> </b-icon>
                </b-button>
            </div>
            <div class="container" :class="errorOnSubmit ? 'has-opacity' : ''" @click="errorOnSubmit = false">
                <figure class="image logo mx-auto is-hidden-desktop">
                    <img src="@/assets/img/logo/ioCity_logo.png" />
                </figure>
                <div class="columns is-flex is-align-items-center">
                    <div class="column is-8-desktop is-hidden-touch">
                        <figure>
                            <img src="@/assets/img/logo/loginIOcity.png" />
                        </figure>
                    </div>
                    <div class="column is-4-desktop">
                        <ValidationObserver v-slot="{ handleSubmit }">
                            <h1 class="title is-4 has-text-secondary has-text-weight-semibold has-text-left is-hidden-touch">{{ $t('views.initial.newpassword.reset') }}</h1>

                            <form @submit.prevent="handleSubmit(onSubmit)">
                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-10-mobile is-4-tablet is-12-desktop mb-3">
                                        <ValidationProvider vid="confirm" v-slot="{ errors }" rules="required|min:8|not-hashed" :name="$t('views.initial.newpassword.newpassword')">
                                            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                                                <b-input
                                                    v-model="form.password"
                                                    required
                                                    type="password"
                                                    name="current-password"
                                                    password-reveal
                                                    :placeholder="$t('views.initial.newpassword.newpassword')"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-10-mobile is-4-tablet is-12-desktop mb-3">
                                        <ValidationProvider v-slot="{ errors }" rules="required|min:8|not-hashed|password-equal:@confirm" :name="$t('views.initial.newpassword.newpassword')">
                                            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                                                <b-input
                                                    v-model="form.passwordConfirmation"
                                                    required
                                                    type="password"
                                                    name="current-password"
                                                    password-reveal
                                                    :placeholder="$t('views.initial.newpassword.confirm')"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-7-mobile is-4-tablet is-7-desktop pb-3">
                                        <b-button native-type="submit" expanded class="has-text-weight-lighter has-shadow submit-button" type="is-primary">
                                            {{ $t('views.initial.newpassword.change') }}
                                        </b-button>
                                    </div>
                                </div>
                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-12 pt-2">
                                        <h1 class="has-text-grey has-text-weight-light is-size-7 is-underlined is-clickable" @click="$router.push({ name: 'Login' })" :loading="isLoading">
                                            {{ $t('views.initial.newpassword.cancel') }}
                                        </h1>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { resetPassword } from '@/api/auth';

    const initialState = () => ({
        isLoading: false,
        form: {
            password: '',
            passwordConfirmation: '',
            code: '',
        },
    });
    export default {
        name: 'Login',
        data() {
            return {
                ...initialState(),
            };
        },

        /*    created() {
            this.code = this.$route.query.code;

        }, */
        methods: {
            async onSubmit() {
                this.isLoading = true;
                this.form.code = this.$route.query.code;
                await resetPassword(this.form)
                    .then(() => {
                        //buefy toast message
                        this.$buefy.toast.open({
                            message: this.$t(`validations.newpassword`),
                            type: 'is-success',
                            position: 'is-top',
                        });
                        this.$router.push({ name: 'BottomMenu' });
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            message: this.$t(`shared.commonError`),
                            position: 'is-top',
                            type: 'is-danger',
                        });
                    });
                this.isLoading = false;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .logo-name {
        max-width: 20%;
        position: absolute;
        top: 3%;
        left: 3%;
    }
</style>
