<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-4">
            <div class="is-flex is-justify-content-space-between is-align-items-center my-2">
                <h1 class="title is-5 has-text-left">{{ $t('components.search.title') }}</h1>
            </div>
            <p class="has-text-left is-size-9-touch is-size-6-desktop">{{ $t('components.search.paragraph') }}</p>
            <b-button expanded @click="$router.push({ name: 'ResearchFavorites' })" class="button has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                <b-icon class="mr-5" type="is-pink has-background-pink-light has-icon-radius py-5 px-5" pack="fas" icon="heart"></b-icon>
                {{ $t('components.search.favorite') }}
                <b-icon class="is-grey" pack="fas" icon="angle-right"></b-icon>
            </b-button>
            <b-button expanded @click="$router.push({ name: 'ResearchRecents' })" class="button has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                <b-icon class="mr-5" type="is-yellow has-background-yellow-light has-icon-radius py-5 px-5" pack="fas" icon="clock-rotate-left"></b-icon>
                {{ $t('components.search.recent') }}
                <b-icon class="is-grey" pack="fas" icon="angle-right"></b-icon>
            </b-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Research',
        data() {
            return {};
        },
    };
</script>

<style scoped>
    .slide-fade-enter-to,
    .slide-fade-leave-from {
        transform: translateY(0%);
    }

    .slide-fade-leave-active,
    .slide-fade-enter-active {
        transition: transform 2s;
    }

    .slide-fade-leave-to,
    .slide-fade-enter-from {
        transform: translateY(100%);
    }
</style>
