import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
// import store from './store_old';
import Buefy from 'buefy';
import './assets/scss/app.scss';
import pinia from './plugins/pinia';
import i18n from './plugins/i18n';

// VEE VALIDATE
//Vee-Validate
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';

localize(i18n.locale);
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});
extend('required', {
    message: (_, values) => i18n.t('validations.required', values),
});

extend('password-equal', {
    params: ['target'],
    validate(value, { target }) {
        return value == target;
    },
    message: (_, values) => i18n.t('validations.password-equal', values),
});

extend('not-hashed', {
    validate(value) {
        return value.split('$').length < 5;
    },
    message: (_, values) => i18n.t('validations.not-hashed', values),
});
extend('must-be-checked', {
    validate: (value) => {
        return value == true;
    },
    message: (_, values) => i18n.t('validations.must-be-checked', values),
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// FONT AWESOME
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas, far, fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// BUEFY
Vue.use(Buefy, {
    defaultIconComponent: 'font-awesome-icon',
    defaultIconPack: 'fas',
});

// SENTRY
import * as Sentry from '@sentry/vue';
if (process.env.VUE_APP_SENTRY && process.env.NODE_ENV != 'development') {
    Sentry.setTag('io_version', process.env.VUE_APP_VERSION);
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        trackComponents: true,
        logErrors: true,
        attachProps: true,
        attachStacktrace: true,
    });
}

// FIREBASE
import { initializeApp } from "firebase/app";
if (process.env.VUE_APP_FIREBASE_API_KEY) {
	initializeApp({
		apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
		authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
		projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
		appId: process.env.VUE_APP_FIREBASE_APP_ID,
	});
}

// GOOGLE MAPS
import GmapVue from 'gmap-vue';
Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GMAPS_KEY,
        libraries: 'places',
        installComponents: true,
    },
});

Vue.config.productionTip = false;

const vueInstance = new Vue({
    router,
    pinia,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

export { vueInstance };
