<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-10">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.parking.details') }}</h1>
                <div></div>
            </div>
            <StopInformation v-if="stop" :stop="stop"></StopInformation>
            <b-button @click="stopDestination" expanded class="has-text-weight-lighter has-shadow submit-button" type="is-primary">
                <p class="is-size-6">{{ $t('components.parking.button') }}</p>
            </b-button>
        </div>
    </div>
</template>

<script>
    import StopInformation from '@/components/ui/StopInformation';
    import useStops from '@/mixins/useStops';
    import { useMapStore } from '@/store/map';
    import { useRecentsStore } from '@/store/recents';

    export default {
        name: 'BusStop',
        components: {
            StopInformation,
        },
        data() {
            return {
                stop: null,
                useStops: useStops(),
                recentsStore: useRecentsStore(),
                mapStore: useMapStore(),
            };
        },
        async created() {
            await this.getStop();
            this.setRecents();
        },
        watch: {
            async '$route.params.id'(newValue) {
                await this.getStop();
                this.setRecents();
            },
        },
        methods: {
            setRecents() {
                this.recentsStore.addRecent({
                    type: 'stop',
                    id: this.$route.params.id,
                });
            },
            stopDestination() {
                this.mapStore.setRouteDestination({
                    name: this.stop.name,
                    formatted_address: this.stop.lat + ', ' + this.stop.lng,
                    geometry: {
                        location: {
                            lat: this.stop.lat,
                            lng: this.stop.lng,
                        },
                    },
                });
                this.$router.push({ name: 'RouteResearch' });
            },
            async getStop() {
                const data = await this.useStops.getStopById(this.$route.params.id);
                this.stop = data;
            },
        },
    };
</script>
