<template>
    <div class="hero is-fullheight">
        <div class="hero-body">
            <figure class="logo-name is-hidden-touch">
                <img src="@/assets/img/logo/ioCity_Logo_name.png" />
            </figure>
            <div class="container" :class="errorOnSubmit ? 'has-opacity' : ''" @click="errorOnSubmit = false">
                <figure class="image logo mx-auto is-hidden-desktop">
                    <img src="@/assets/img/logo/ioCity_logo.png" />
                </figure>
                <div class="columns is-flex is-align-items-center">
                    <div class="column is-8-desktop is-hidden-touch">
                        <figure>
                            <img src="@/assets/img/logo/loginIOcity.png" />
                        </figure>
                    </div>
                    <div class="column is-4-desktop">
                        <ValidationObserver v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(formSubmit)">
                                <h1 class="title is-4 has-text-secondary has-text-weight-semibold has-text-left is-hidden-touch">{{ $t('views.initial.home.register') }}</h1>

                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-10-mobile is-4-tablet is-12-desktop">
                                        <ValidationProvider :name="$t('views.initial.register.name')" rules="required" v-slot="{ errors }">
                                            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                                                <b-input v-model="form.name" type="name" name="name" :placeholder="$t('views.initial.register.name')"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                    <div class="column is-10-mobile is-4-tablet is-12-desktop">
                                        <ValidationProvider :name="$t('views.initial.register.email')" rules="required|email" v-slot="{ errors }">
                                            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                                                <b-input v-model="form.email" type="email" name="email" :placeholder="$t('views.initial.register.email')"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                    <div class="column is-10-mobile is-4-tablet is-12-desktop">
                                        <ValidationProvider vid="confirm" :name="$t('views.initial.register.password')" rules="required|min:8|not-hashed" v-slot="{ errors }">
                                            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                                                <b-input v-model="form.password" type="password" name="new-password" password-reveal :placeholder="$t('views.initial.login.password')"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                    <div class="column is-10-mobile is-4-tablet is-12-desktop mb-3">
                                        <ValidationProvider :name="$t('views.initial.register.confirm')" rules="required|min:8|not-hashed|password-equal:@confirm" v-slot="{ errors }">
                                            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                                                <b-input v-model="form.confirm" type="password" name="new-password" password-reveal :placeholder="$t('views.initial.register.confirm')"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                    <div class="column is-10-mobile is-4-tablet is-12-desktop mb-3">
                                        <ValidationProvider rules="required|must-be-checked" v-slot="{ errors }" class="is-flex">
                                            <b-checkbox v-model="form.consented" :class="errors[0] ? 'has-text-danger' : ''"></b-checkbox
                                            ><span class="has-text-left" :class="errors[0] ? 'has-text-danger' : ''"
                                                >{{ $t('views.initial.register.accept')
                                                }}<a @click="openTerms = true" :class="errors[0] ? 'has-text-danger' : ''" class="has-text-primary is-underlined">
                                                    {{ $t('views.initial.register.terms') }}</a
                                                >
                                                {{ $t('views.initial.register.confirmRead') }}
                                                <a @click="openPrivacy = true" :class="errors[0] ? 'has-text-danger' : ''" class="has-text-primary is-underlined">
                                                    {{ $t('views.initial.register.privacyPolicy') }}</a
                                                ></span
                                            >
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-7-mobile is-4-tablet is-6-desktop pb-3">
                                        <b-button
                                            native-type="submit"
                                            expanded
                                            class="has-text-weight-lighter has-shadow submit-button"
                                            type="is-primary"
                                            :class="isLoading == true ? 'is-loading' : ''">
                                            {{ $t('views.initial.register.register') }}
                                        </b-button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                        <div class="columns is-mobile is-centered is-multiline">
                            <div class="column is-10-mobile is-4-tablet is-12-desktop mt-5">
                                <h4 class="has-text-grey has-text-weight-light is-size-7">
                                    {{ $t('views.initial.register.account') }}
                                </h4>
                                <a @click="$router.push({ name: 'Login' })" class="has-text-primary has-text-weight-medium is-size-7">
                                    {{ $t('views.initial.register.dologin') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav v-if="errorOnSubmit == true" class="navbar is-fixed-bottom rounded" role="navigation">
                <div class="has-background-white container is-expanded rounded is-relative pb-5">
                    <div class="columns is-mobile is-multiline is-centered">
                        <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet mt-6">
                            <b-icon type="is-yellow is-large has-background-yellow-light has-border-radius py-6 px-6" pack="fas" icon="envelope"></b-icon>
                        </div>
                    </div>
                    <div class="columns is-mobile is-multiline is-centered is-vcentered mb-4 mt-6">
                        <div class="column is-10-mobile is-10-tablet">
                            <h1 class="title is-5">{{ $t('components.notifications.oops') }}</h1>
                        </div>
                        <div class="column is-10-mobile is-10-tablet">
                            <p class="subtitle is-6 has-text-weight-light px-5">{{ $t('components.notifications.registered') }}</p>
                        </div>
                        <div class="column is-9-mobile is-9-tablet is-6-desktop mt-5">
                            <b-button @click="$router.push({ name: 'Login' })" expanded class="has-text-weight-lighter has-shadow mb-4 submit-button" type="is-primary">
                                {{ $t('components.notifications.login') }}
                            </b-button>
                            <h1 @click="errorOnSubmit = false" class="has-text-grey is-size-6 is-underlined">
                                {{ $t('components.notifications.tryEmail') }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nav>
            <nav v-if="openTerms" class="navbar is-fixed-bottom rounded" role="navigation">
                <div class="has-background-white container is-expanded rounded is-relative pb-5">
                    <div class="columns is-mobile is-multiline is-centered">
                        <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet mt-6">
                            <b-icon type="is-primary is-large has-background-primary-light has-border-radius py-6 px-6" pack="fas" icon="file"></b-icon>
                        </div>
                    </div>
                    <div class="columns is-mobile is-multiline is-centered is-vcentered mb-4 mt-6">
                        <div class="column is-10-mobile is-10-tablet">
                            <h1 class="title is-5">Termos e Condições</h1>
                        </div>
                        <div class="column is-10-mobile is-10-tablet">
                            <p class="subtitle is-6 has-text-weight-light px-5">
                                {{ $t('components.notifications.registered') }}{{ $t('components.notifications.registered') }}{{ $t('components.notifications.registered')
                                }}{{ $t('components.notifications.registered') }}{{ $t('components.notifications.registered') }}{{ $t('components.notifications.registered') }}
                            </p>
                        </div>
                        <div class="column is-9-mobile is-9-tablet is-6-desktop mt-5">
                            <b-button @click="openTerms = false" expanded class="has-text-weight-lighter has-shadow mb-4 submit-button" type="is-primary">
                                {{ $t('views.initial.register.close') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </nav>
            <nav v-if="openPrivacy" class="navbar is-fixed-bottom rounded" role="navigation">
                <div class="has-background-white container is-expanded rounded is-relative pb-5">
                    <div class="columns is-mobile is-multiline is-centered">
                        <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet mt-6">
                            <b-icon type="is-primary is-large has-background-primary-light has-border-radius py-6 px-6" pack="fas" icon="lock"></b-icon>
                        </div>
                    </div>
                    <div class="columns is-mobile is-multiline is-centered is-vcentered mb-4 mt-6">
                        <div class="column is-10-mobile is-10-tablet">
                            <h1 class="title is-5">Política de Privacidade</h1>
                        </div>
                        <div class="column is-10-mobile is-10-tablet">
                            <p class="subtitle is-6 has-text-weight-light px-5">
                                {{ $t('components.notifications.registered') }}{{ $t('components.notifications.registered') }}{{ $t('components.notifications.registered')
                                }}{{ $t('components.notifications.registered') }}{{ $t('components.notifications.registered') }}{{ $t('components.notifications.registered') }}
                            </p>
                        </div>
                        <div class="column is-9-mobile is-9-tablet is-6-desktop mt-5">
                            <b-button @click="openPrivacy = false" expanded class="has-text-weight-lighter has-shadow mb-4 submit-button" type="is-primary">
                                {{ $t('views.initial.register.close') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    import { registerUser } from '@/api/auth.js';
    import auth from '@/mixins/auth.js';
    const initialState = () => ({
        isLoading: false,
        form: {
            name: '',
            email: '',
            password: '',
            confirm: '',
            consented: null,
        },
    });
    export default {
        name: 'Register',
        mixins: [auth],
        data() {
            return {
                ...initialState(),
                isLoading: false,
                errorOnSubmit: false,
                openTerms: false,
                openPrivacy: false,
            };
        },
        methods: {
            formSubmit() {
                this.isLoading = true;
                registerUser(this.form)
                    .then((response) => {
                        this.onLoginSuccess(response.data);
                    })
                    .catch((error) => {
                        if (error.response.data.error.message == 'Email or Username are already taken') {
                            this.errorOnSubmit = true;
                        } else
                            this.$buefy.toast.open({
                                message: this.$t(`shared.commonError`),
                                position: 'is-top',
                                type: 'is-danger',
                            });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/_variables.scss';

    .logo {
        max-width: 250px;
    }
    .navbar {
        box-shadow: 0px 0px 20px #00000033;
    }
    .logo-name {
        max-width: 20%;
        position: absolute;
        top: 3%;
        left: 0;
    }
    @media (min-width: 50em) {
        .rounded {
            border-radius: 30px !important;
            display: block;
        }
        .navbar.is-fixed-bottom {
            position: fixed;
            width: 50%;
            bottom: auto;
            margin-inline: auto;
        }
    }
</style>
