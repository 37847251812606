<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
    import { tokenAuth } from './api/auth';
    import { useAppStore } from './store/app';
    import { localeChanged } from 'vee-validate';
    import { useAuthStore } from './store/auth';
    import { useNotifications } from '@/mixins/useNotifications';

    export default {
        mixins: [],
        data() {
            return {
                appStore: useAppStore(),
                authStore: useAuthStore(),
            };
        },
        created() {
            const htmlClassName = 'dark-mode';
            if (this.appStore.getDarkMode) {
                document.documentElement.classList.add(htmlClassName);
            } else {
                document.documentElement.classList.remove(htmlClassName);
            }

            this.$i18n.locale = this.appStore.getLocale;
            localeChanged();

            if (this.authStore.getToken)
                tokenAuth().then(({ data }) => {
                    this.authStore.setUser(data.user);
                    this.authStore.setToken(data.jwt);
                });
        },
        mounted() {
            useNotifications().requestPermission();
        },
    };
</script>
