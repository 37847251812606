<template>
    <div class="navbar has-background-white rounded">
        <div class="columns is-mobile is-multiline is-centered mb-5 mt-2">
            <div class="column is-1-mobile is-2-tablet">
                <b-button @click="$router.go(-1)" size="is-40x40" class="button is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
            </div>
            <div class="column is-7-mobile is-8-tablet">
                <h1 class="title is-5 mt-2 has-text-center">{{ $t('components.path.details') }}</h1>
            </div>
            <div class="column is-1-mobile is-2-tablet"></div>
        </div>
        <RouteResult class="has-scroll-medium px-5" />
    </div>
</template>
<script>
    import RouteResult from '@/components/routes/RouteResult.vue';

    export default {
        name: 'RouteDetails',
        components: {
            RouteResult,
        },
    };
</script>
