<template>
    <div>
        <div class="columns is-mobile is-multiline is-centered is-vcentered is-gapless">
            <div class="column is-2-mobile is-2-tablet">
                <b-icon
                    :type="`is-${types[transportType].color} has-background-${types[transportType].color}-light`"
                    class="px-5 py-5 has-icon-radius"
                    pack="fas"
                    :icon="types[transportType].icon"></b-icon>
            </div>
            <div class="column is-6-mobile is-6-tablet has-text-left">
                <p class="is-size-6 ml-4 has-text-grey-light">
                    <strong class="has-text-weight-semibold">{{ types[transportType].name }}</strong> (3km)
                </p>
                <p class="subtitle is-size-7 ml-4 has-text-grey-light mt-1">Até à Rua das Alfaces</p>
            </div>
            <div v-if="transportType == 'subway' || transportType == 'bus' || transportType == 'walk' || transportType == 'car'" class="column is-4-mobile is-4-tablet">
                <h1 class="title is-size-4 has-text-weight-bold has-text-right">4</h1>
                <p class="subtitle is-size-6 has-text-right has-text-grey-light">min</p>
            </div>
            <div v-else class="column is-4-mobile is-4-tablet"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RouteStep',
        props: ['transportType'],
        data() {
            return {
                types: {
                    subway: {
                        name: this.$t('components.routes.train'),
                        icon: 'subway',
                        color: 'purple',
                    },
                    bus: {
                        name: this.$t('components.routes.bus'),
                        icon: 'bus',
                        color: 'yellow',
                    },
                    car: {
                        name: this.$t('components.routes.car'),
                        icon: 'car',
                        color: 'pink',
                    },
                    walk: {
                        name: this.$t('components.routes.walk'),
                        icon: 'person-walking',
                        color: 'green',
                    },
                    location: {
                        name: this.$t('components.routes.destiny'),
                        icon: 'location-dot',
                        color: 'primary',
                    },
                },
            };
        },
    };
</script>
