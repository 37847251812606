import { defineStore } from 'pinia';
import _ from 'lodash';

export const useMapStore = defineStore({
    id: 'map',
    state: () => ({
        showParks: true,
        showStops: true,
        showFavorites: false,
        showDirections: false,
        showRoutes: true,
        centerCoords: { lat: 41.4064449, lng: -8.5154625 },
        _toggleCenterCoords: false,
        route: {
            origin: null,
            destination: null,
            travelMode: null,
            result: null,
            time: new Date(),
            timeType: 'leaveNow',
        },
        intervalId: null,
    }),
    getters: {
        getShowParks(state) {
            return state.showParks;
        },
        getShowStops(state) {
            return state.showStops;
        },
        getShowFavorites(state) {
            return state.showFavorites;
        },
        getCenterCoords(state) {
            return state.centerCoords;
        },
        getShowDirections(state) {
            return state.showDirections;
        },
        centerOnMe(state) {
            return state._toggleCenterCoords;
        },
        getRouteOrigin(state) {
            return state.route.origin;
        },
        getRouteDestination(state) {
            return state.route.destination;
        },
        getRouteTime(state) {
            return state.route.time;
        },
        getRouteResult(state) {
            return state.route.result;
        },
        getRouteTravelMode(state) {
            return state.route.travelMode;
        },
        getRouteTimeType(state) {
            return state.route.timeType;
        },
        getShowRoutes(state) {
            return state.showRoutes;
        },
        getIntervalId(state) {
            return state.intervalId;
        },
    },
    actions: {
        setShowParks(value) {
            this.showParks = value;
        },
        setShowStops(value) {
            this.showStops = value;
        },
        setShowFavorites(value) {
            this.showFavorites = value;
        },
        setCenterCoords(value) {
            this.centerCoords = value;
        },
        setShowDirections(value) {
            this.showDirections = value;
        },
        toggleCenterCoords() {
            this._toggleCenterCoords = !this._toggleCenterCoords;
        },
        setRouteOrigin(value) {
            this.route.origin = value;
        },
        setRouteDestination(value) {
            this.route.destination = value;
        },
        setRouteTime(value) {
            this.route.time = value;
        },
        setRouteResult(value) {
            this.route.result = _.cloneDeep(value);
        },
        setRouteTravelMode(value) {
            this.route.travelMode = value;
        },
        setRouteTimeType(value) {
            this.route.timeType = value;
        },
        setShowRoutes(value) {
            this.showRoutes = value;
        },
        setIntervalId(value) {
            this.intervalId = value;
        },
    },
    persist: true,
});
