import axios from '@/plugins/axios';
export function uploadFile(file) {
    let form = new FormData();
    form.append('files', file);
    return axios.post('/upload', form, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}
