<template>
    <div class="hero is-fullheight">
        <div class="hero-body">
            <div class="logo-name is-flex">
                <b-button @click="$router.push({ name: 'Login' })" size="is-40x40" class="button is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"> </b-icon>
                </b-button>
            </div>
            <div class="container" :class="errorOnSubmit ? 'has-opacity' : ''" @click="errorOnSubmit = false">
                <figure class="image logo mx-auto is-hidden-desktop">
                    <img src="@/assets/img/logo/ioCity_logo.png" />
                </figure>
                <div class="columns is-flex is-align-items-center">
                    <div class="column is-8-desktop is-hidden-touch">
                        <figure>
                            <img src="@/assets/img/logo/loginIOcity.png" />
                        </figure>
                    </div>
                    <div class="column is-4-desktop">
                        <ValidationObserver v-slot="{ handleSubmit }">
                            <h1 class="title is-4 has-text-secondary has-text-weight-semibold has-text-left is-hidden-touch">{{ $t('views.initial.forgetpassword.title') }}</h1>
                            <form @submit.prevent="handleSubmit(onSubmit)">
                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-10-mobile is-4-tablet is-12-desktop">
                                        <ValidationProvider vid="confirm" v-slot="{ errors }" rules="required|email" :name="$t('views.initial.forgetpassword.email')">
                                            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                                                <b-input v-model="form.email" required type="email" name="email" :placeholder="$t('views.initial.forgetpassword.email')"> </b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-7-mobile is-4-tablet is-7-desktop pb-3">
                                        <b-button :loading="isLoading" native-type="submit" expanded class="has-text-weight-lighter has-shadow submit-button" type="is-primary">
                                            {{ $t('views.initial.forgetpassword.send') }}
                                        </b-button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { forgotPassword } from '@/api/auth';

    const initialState = () => ({
        isLoading: false,
        form: {
            email: '',
        },
    });
    export default {
        name: 'ForgotPassword',
        data() {
            return {
                ...initialState(),
            };
        },
        methods: {
            async onSubmit() {
                this.isLoading = true;
                await forgotPassword(this.form)
                    .then(() => {
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            message: this.$t('validations.sendSucess'),
                            type: 'is-success',
                            position: 'is-top',
                        });
                    })
                    .catch(() => {
                        this.$buefy.toast.open({
                            message: this.$t('validations.commonError'),
                            position: 'is-top',
                            type: 'is-danger',
                        });
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/_variables.scss';

    .logo-name {
        max-width: 20%;
        position: absolute;
        top: 3%;
        left: 3%;
    }
</style>
