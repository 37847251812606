import { addFavorites, getFavorites, removeFavorites } from '@/api/favorites';
import useGeolocation from './useGeolocation';
import _ from 'lodash';

export const populates = ['stop', 'parkingLot', 'trip.route', 'stop_times.stop', 'trip.transport.agency.pictures'];

export default function useFavorites() {
    return {
        async getFavorites(query = {}) {
            const { coords } = await useGeolocation().getPosition();
            const { data, meta } = await getFavorites(
                _.merge(
                    {
                        populate: populates,
                        ...(coords ? { calculateDistance: true, calcRealDistance: true, latitude: coords.latitude, longitude: coords.longitude } : {}),
                    },
                    query,
                ),
            ).then(({ data }) => data);
            return { data, meta };
        },
        /**
         *
         * @param {Object} data
         * @param {String} data.stop
         * @param {String} data.parkingLot
         * @param {String} data.trip
         * @param {String} data.stop_times
         * @param {String} data.type
         * @returns {Promise<Object>}
         */
        async addFavorite(data) {
            return await addFavorites(data, {
                populate: populates,
            }).then(({ data }) => data.data);
        },
        /**
         * @param {String} id
         * @returns {Promise<Object>}
         */
        async removeFavorite(id) {
            return await removeFavorites(id);
        },
    };
}
