import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { getApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useAppStore } from '@/store/app';

export const useNotifications = () => {
    return {
        async requestPermission() {
            if (Capacitor.isNativePlatform()) nativeNotifications();
            else webNotifications();
        },
        async getToken() {
            let token = null;
            try {
                if (Capacitor.isNativePlatform()) {
                    token = getTokenNative();
                } else {
                    token = await getTokenWeb();
                }
            } catch (error) {
                console.error(error);
            }

            return token;
        },
    };
};

async function nativeNotifications() {
    const result = await PushNotifications.requestPermissions();
    if (!result || result.receive !== 'granted') return;

    PushNotifications.register();

    PushNotifications.addListener('registration', (token) => {
        const appStore = useAppStore();
        appStore.setNotificationsToken(token.value);
    });

    // PushNotifications.addListener('registrationError', (error) => {
    //     console.log('Error: ' + JSON.stringify(error));
    // });

    // PushNotifications.addListener('pushNotificationReceived', (notification) => {
    //     console.log('Push received: ' + JSON.stringify(notification));
    // });

    // PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    //     console.log('Push action performed: ' + JSON.stringify(notification));
    // });
}

async function webNotifications() {
    const app = getApp();
    const messaging = getMessaging(app);
    Notification.requestPermission()
        .then((permission) => {
            if (permission == 'granted') {
                getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY }).catch((err) => {
                    // console.error('An error occurred while retrieving token. ', err);
                    throw err;
                });
            }
        })
        .catch((err) => {
            // console.error(err);
            throw err;
        });

    // onMessage(messaging, (payload) => {
    //     console.log('Message received. ', payload);
    //     // ...
    // });
}

function getTokenNative() {
    return useAppStore().getNotificationsToken;
}

async function getTokenWeb() {
    const app = getApp();
    const messaging = getMessaging(app);
    const token = await getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY });
    return token;
}
