<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-8">
            <div class="is-flex is-justify-content-space-between is-align-items-center my-2">
                <h1 class="title is-5 has-text-left">{{ $t('components.nearme.title') }}</h1>
            </div>

            <div class="is-flex">
                <b-button
                    @click="setSelectedNearType('parkingLot')"
                    expanded
                    :class="selectedNearType == 'parkingLot' ? 'submit-button is-primary' : 'has-background-grey-lighter'"
                    class="button is-small has-shadow mx-2">
                    <p class="is-size-6">{{ $t('components.search.parks') }}</p>
                </b-button>
                <b-button
                    @click="setSelectedNearType('stop')"
                    expanded
                    :class="selectedNearType == 'stop' ? 'submit-button  is-primary' : 'has-background-grey-lighter'"
                    class="button is-small has-shadow mx-2">
                    <p class="is-size-6">{{ $t('components.search.stops') }}</p>
                </b-button>
            </div>
            <div v-if="nearList.length == 0 && !isLoading" class="is-flex is-flex-direction-column is-align-items-center">
                <b-icon class="has-icon-radius" type="is-primary has-background-primary-light is-large p-6" pack="fas" icon="person-digging"></b-icon>
                <h5 class="title is-family-primary is-size-8 pb-5 mt-5">{{ $t('components.nearme.noResults') }}</h5>
            </div>
            <div v-else class="scrollable-content">
                <div v-for="(near, index) in filterNearList" :key="index" class="">
                    <ParkInformation v-if="near.nearType == 'parkingLot'" :park="near"></ParkInformation>
                    <StopInformation v-if="near.nearType == 'stop'" :stop="near"></StopInformation>
                    <hr class="mt-4 mb-0" />
                </div>
            </div>
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true">
                <b-icon pack="fas" icon="spinner" size="is-large" custom-class="fa-spin"> </b-icon>
            </b-loading>
        </div>
    </div>
</template>

<script>
    import ParkInformation from '@/components/ui/ParkInformation.vue';
    import StopInformation from '@/components/ui/StopInformation.vue';
    import { useAuthStore } from '@/store/auth';
    import useGeolocation from '@/mixins/useGeolocation';
    import useParks from '@/mixins/useParks';
    import useStops from '@/mixins/useStops';

    export default {
        name: 'NearMe',
        components: {
            ParkInformation,
            StopInformation,
        },
        data() {
            return {
                isLoading: true,
                selectedNearType: '',
                authStore: useAuthStore(),
                useGeolocation: useGeolocation(),
                useParks: useParks(),
                useStops: useStops(),
                parks: [],
                stops: [],
            };
        },
        async created() {
            // execute both functions at the same time
            await Promise.all([this.getParks(), this.getStops()]);
            this.isLoading = false;
        },
        computed: {
            filterNearList() {
                if (!this.selectedNearType) {
                    return this.nearList;
                } else {
                    return this.nearList.filter((near) => near.nearType == this.selectedNearType);
                }
            },
            nearList() {
                return [...this.parks, ...this.stops]
                    .sort((a, b) => {
                        return a.distance.distance.value - b.distance.distance.value;
                    })
                    .slice(0, 19);
            },
        },
        methods: {
            async getParks() {
                let { data } = await this.useParks.getParksByQuery({
                    filters: {
                        distance: {
                            $lte: 10,
                        },
                    },
                });
                this.parks = data.map((park) => {
                    return {
                        ...park,
                        nearType: 'parkingLot',
                    };
                });
            },
            async getStops() {
                let { data } = await this.useStops.getStopsByQuery({
                    filters: {
                        distance: {
                            $lte: 10,
                        },
                    },
                });
                this.stops = data.map((stop) => {
                    return {
                        ...stop,
                        nearType: 'stop',
                    };
                });
            },
            setSelectedNearType(type) {
                this.selectedNearType = type == this.selectedNearType ? '' : type;
            },
        },
    };
</script>
