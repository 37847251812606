<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.search.favorite') }}</h1>
                <div></div>
            </div>

            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center gap-4">
                <b-button @click="setSelectedFavType('parkingLot')" expanded :class="selectedFavType == 'parkingLot' ? 'is-primary' : 'has-background-grey-lighter'" class="button is-small has-shadow">
                    <p class="is-size-6">{{ $t('components.search.parks') }}</p>
                </b-button>
                <b-button @click="setSelectedFavType('stop')" expanded :class="selectedFavType == 'stop' ? 'submit-button  is-primary' : 'has-background-grey-lighter'" class="is-small has-shadow">
                    <p class="is-size-6">{{ $t('components.search.stops') }}</p>
                </b-button>
                <b-button
                    :class="selectedFavType == 'trip+stop_times' ? 'submit-button is-primary' : 'has-background-grey-lighter'"
                    expanded
                    @click="setSelectedFavType('trip+stop_times')"
                    class="is-small has-shadow">
                    <p class="is-size-6">{{ $t('components.search.route') }}</p>
                </b-button>
            </div>

            <b-loading :active="isLoading"></b-loading>

            <div ref="scrollable_content" v-show="favorites.length > 0" class="scrollable-content">
                <div v-for="(favorite, index) of favorites" :key="index">
                    <ParkInformation v-if="favorite.type == 'parkingLot'" :park="favorite.parkingLot"></ParkInformation>
                    <StopInformation v-if="favorite.type == 'stop'" :stop="favorite.stop"></StopInformation>
                    <TransportSchedule v-if="favorite.type == 'trip+stop_times'" :trip="favorite.trip"></TransportSchedule>
                    <hr class="mt-4 mb-0" />
                </div>
            </div>
            <div v-show="favorites.length == 0" class="scrollable-content">
                <h1>{{ $t('components.search.notFavorites') }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
    import DestinationInfo from '@/components/ui/DestinationInfo.vue';
    import ParkInformation from '@/components/ui/ParkInformation.vue';
    import TransportSchedule from '@/components/ui/TransportSchedule.vue';
    import StopInformation from '../ui/StopInformation.vue';
    import useFavorites from '@/mixins/useFavorites';
    import useScroll from '@/mixins/useScroll';

    export default {
        name: 'Favorite',
        components: {
            DestinationInfo,
            ParkInformation,
            StopInformation,
            TransportSchedule,
        },

        data() {
            return {
                isLoading: false,
                selectedFavType: '',
                favorites: [],
                useFavorites: useFavorites(),
                useScroll: null,
                page: 1,
                pageCount: 1,
            };
        },
        async created() {
            await this.fetchFavorites();
        },
        mounted() {
            this.useScroll = useScroll(this.$refs.scrollable_content);
            this.useScroll.onScrollBottom(() => {
                if (this.page >= this.pageCount) return;
                this.page++;
                this.fetchFavorites();
            });
        },
        watch: {
            selectedFavType(newValue, oldValue) {
                if (newValue != oldValue) {
                    this.fetchFavorites();
                }
            },
        },
        methods: {
            async fetchFavorites() {
                this.isLoading = true;
                const { data, meta } = await this.useFavorites.getFavorites({
                    filters: {
                        ...(this.selectedFavType && { type: this.selectedFavType }),
                    },
                    pagination: {
                        page: this.page,
                    },
                });
                this.pageCount = meta.pagination.pageCount;
                if (this.page == 1) this.favorites = data;
                else this.favorites = [...this.favorites, ...data];
                this.isLoading = false;
            },
            setSelectedFavType(type) {
                this.page = 1;
                if (this.selectedFavType == type) this.selectedFavType = '';
                else this.selectedFavType = type;
            },
        },
    };
</script>
