<template>
    <nav class="navbar is-fixed-bottom rounded" role="navigation">
        <div class="navbar has-background-white container is-expanded rounded is-relative pb-5">
            <div class="columns is-mobile is-multiline is-centered">
                <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet mt-6">
                    <img src="@/assets/img/icons/nogps.svg" class="image is-96x96" />
                </div>
            </div>
            <div class="columns is-mobile is-multiline is-centered is-vcentered mb-4 mt-6">
                <div class="column is-10-mobile is-10-tablet">
                    <h1 class="title is-5">{{ $t('components.notifications.noGPS') }}</h1>
                </div>
                <div class="column is-10-mobile is-10-tablet">
                    <p class="subtitle is-6 has-text-weight-light px-5">{{ $t('components.notifications.noGPSParagraph') }}</p>
                </div>
                <div class="column is-9-mobile is-9-tablet mt-5">
                    <b-button expanded class="has-text-weight-lighter has-shadow mb-4" type="is-primary">
                        {{ $t('components.notifications.tryagain') }}
                    </b-button>
                    <a class="has-text-grey is-size-6">
                        {{ $t('components.notifications.leave') }}
                    </a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'NoGPS',
    };
</script>
