import axios from '@/plugins/axios';
import qs from 'qs';
import _ from 'lodash';

export function getParks(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/parking-lots?${query}`);
}

export function getPark(id, query = {}) {
    query = qs.stringify(query);
    return axios.get(`/parking-lots/${id}?${query}`);
}
