import axios from '@/plugins/axios';
import qs from 'qs';

export function getStops(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/mobility-stops?${query}`);
}

export function getStop(id, query = {}) {
    query = qs.stringify(query);
    return axios.get(`/mobility-stops/${id}?${query}`);
}

export function searchStops(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/mobility-stops/search?${query}`);
}
