<template>
    <section class="section is-fullheight">
        <div class="container is-fullheight-per is-flex is-flex-direction-column is-justify-content-flex-start">
            <div class="columns is-mobile is-multiline is-vcentered">
                <div class="column is-1-mobile is-1-tablet is-2-desktop">
                    <b-button @click="$router.go(-1)" size="is-40x40" class="button is-primary is-pulled-left">
                        <b-icon type="is-white py-1	px-1" pack="fas" icon="angle-left"> </b-icon>
                    </b-button>
                </div>
                <div class="column is-10-mobile is-11-tablet is-8-desktop">
                    <h1 class="title is-5">
                        {{ $t('views.schedule.bus') }}
                    </h1>
                </div>
                <div class="column is-1-mobile is-11-tablet is-8-desktop"></div>
            </div>
            <div class="columns is-overflowX is-mobile is-multiline is-centered mt-3">
                <div class="column is-11-mobile is-11-tablet">
                    <TransportSchedule class="box has-border-radius" transportType="bus"></TransportSchedule>
                    <TransportSchedule class="box has-border-radius" transportType="bus"></TransportSchedule>
                    <TransportSchedule class="box has-border-radius" transportType="bus"></TransportSchedule>
                    <TransportSchedule class="box has-border-radius" transportType="bus"></TransportSchedule>
                </div>
            </div>
            <div class="columns is-mobile is-multiline is-vcentered">
                <div class="column is-6-mobile is-6-tablet is-2-desktop is-offset-one-quarter">
                    <b-button expanded class="has-text-weight-lighter has-shadow" type="is-primary">
                        <p class="is-size-6">{{ $t('views.schedule.go') }}</p>
                    </b-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import TransportSchedule from '@/components/ui/TransportSchedule.vue';

    export default {
        name: 'TrainSchedule',
        components: {
            TransportSchedule,
        },
    };
</script>
