import axios from '@/plugins/axios';
import qs from 'qs';

export function getBuses(query = {}) {
    return axios.get(`/sensor-bus-locations?${qs.stringify(query)}`);
}

export function getBus(id, query = {}) {
    return axios.get(`/mobility-transports/${id}?${qs.stringify(query)}`);
}
