<template>
    <section class="columns is-mobile is-multiline is-centered fill-height">
        <div v-if="park" class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.push({ name: 'ParkMap' })" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">
                    {{ $t('views.parkreservation.title') }}
                </h1>
                <div></div>
            </div>

            <div class="columns is-multiline is-mobile is-centered">
                <figure class="column is-7-mobile is-3-tablet">
                    <img class="has-border-radius" :src="parkPicture" />
                </figure>
                <div class="column is-10-mobile is-10-tablet">
                    <h1 class="title is-5">{{ park.name }}</h1>
                    <p class="subtitle is-6 has-text-grey-light">{{ park.address }}</p>
                    <p class="is-size-9 has-text-grey-light has-text-weight-light">
                        <b-icon class="mr-3 ml-1" type="is-orange is-small has-background-orange-light has-icon-euro-radius py-3 px-3" pack="fas" icon="euro"></b-icon
                        >{{ park.price > 0 ? park.price + '€/hora' : $t('components.parking.free') }}
                    </p>
                </div>
                <div class="box mt-4">
                    <div class="columns is-mobile">
                        <div class="column is-2-mobile">
                            <b-icon type="is-primary is-medium has-background-primary-light has-icon-euro-radius py-5 px-5" pack="fas" icon="car"></b-icon>
                        </div>
                        <div class="column is-10-mobile ml-6">
                            <p class="is-size-6 has-text-grey-dark has-text-weight-medium has-text-left">Lugar</p>
                            <p class="is-size-6 has-text-grey-light has-text-weight-light has-text-left">{{ $route.query.spot || '' }}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p class="is-size-6 has-text-grey-dark has-text-weight-medium">{{ $t('views.parkreservation.dateReservation') }}</p>
                    <b-datetimepicker
                        class="mt-3"
                        :min-datetime="new Date()"
                        :mobile-native="false"
                        v-model="selectedDate"
                        placeholder="Click to select..."
                        :icon-right="selectedDate ? 'close-circle' : ''"
                        icon-right-clickable
                        @icon-right-click="clearDateTime"
                        horizontal-time-picker>
                    </b-datetimepicker>
                </div>
                <div class="column is-10-mobile is-10-tablet mt-3">
                    <p class="is-size-6 has-text-grey-dark has-text-weight-medium">{{ $t('views.parkreservation.duration') }}</p>
                    <div class="grid-30-70 is-align-items-center mt-3">
                        <p class="is-size-6 has-text-grey-dark has-text-weight-light has-text-left">{{ $t('components.parking.hours') }}:</p>
                        <b-numberinput type="is-white has-text-primary" v-model="hours" placeholder="0" :min="0"></b-numberinput>
                    </div>
                    <div class="grid-30-70 is-align-items-center mt-3">
                        <p class="is-size-6 has-text-grey-dark has-text-weight-light has-text-left">{{ $t('components.parking.minutes') }}:</p>
                        <b-numberinput type="is-white has-text-primary" v-model="minutes" step="15" placeholder="0" :min="0" max="59"></b-numberinput>
                    </div>
                </div>
                <div v-if="park.price > 0" class="column is-8-mobile is-8-tablet mt-3">
                    <p class="is-size-5 has-text-grey-dark has-text-weight-medium mt-3">
                        Total:<strong class="ml-3">{{ total }}€</strong>
                    </p>
                </div>
                <div class="column is-8-mobile is-8-tablet mt-3">
                    <b-button @click="$router.push({ name: 'BottomMenu' })" expanded class="has-text-weight-lighter has-shadow" type="is-primary">
                        {{ $t('views.parkreservation.confirm') }}
                    </b-button>
                    <b-button @click="$router.push({ name: 'BottomMenu' })" expanded class="has-text-weight-lighter has-shadow my-4" type="is-ghost">
                        {{ $t('views.parkreservation.cancel') }}
                    </b-button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import useParks from '@/mixins/useParks.js';
    import _ from 'lodash';
    export default {
        name: 'Profile',

        data() {
            return {
                selectedDate: new Date(),
                hours: 0,
                minutes: 15,
                time: new Date(),
                file: null,
                park: null,
                useParks: useParks(),
            };
        },
        created() {
            this.useParks.getParkById(this.$route.query.park).then((park) => {
                this.park = park;
            });
        },
        computed: {
            parkPicture() {
                return _.get(this.park, 'pictures[0].url', '');
            },
            total() {
                let f = this.hours * this.park.price + this.minutes * (this.park.price / 60);
                return parseFloat(f).toFixed(2);
            },
        },
        methods: {
            clearDateTime() {
                this.selectedDate = null;
            },
        },
    };
</script>

<style scoped>
    .has-icon-euro-radius {
        border-radius: 8px;
    }
    .box {
        border-radius: 15px;
    }

    figure {
        aspect-ratio: 1;
    }

    figure > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
</style>
