<template>
    <section class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.push({ name: isDesktop ? 'Main-Profile' : 'Profile' })" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">
                    {{ $t('views.profile.help.title') }}
                </h1>
                <div></div>
            </div>

            <b-collapse class="card mt-6" animation="slide" :open="false" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title has-text-left has-text-weight-medium">
                            {{ $t('views.profile.help.schedule') }}
                        </p>
                        <a class="card-header-icon">
                            <b-icon pack="fas" :icon="props.open ? 'angle-up' : 'angle-down'"> </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content is-size-7-mobile is-size-6-tablet">
                    <div class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris.</div>
                </div>
            </b-collapse>
        </div>
    </section>
</template>

<script>
    import useDesktop from '@/mixins/useDesktop';

    export default {
        name: 'Favorite',
        data() {
            return {
                useDesktop: useDesktop(),
            };
        },
        computed: {
            isDesktop() {
                return this.useDesktop.isDesktop;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';

    .card-header {
        border-radius: 15px;
        height: 56px;
        font-size: 90%;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
    }
    .card {
        border-radius: 15px;
    }
</style>
