<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-8">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.routes.details') }}</h1>
                <div></div>
            </div>

            <div class="is-flex is-align-items-center is-justify-content-center">
                <h1 class="has-text-grey has-text-weight-bold is-small py-1 mx-2">{{ origin }}</h1>
                <b-icon type="is-grey" size="is-small" icon-pack="fas" icon="angle-right"></b-icon>
                <h1 class="has-text-grey has-text-weight-bold is-small py-1 mx-2">{{ destination }}</h1>
            </div>

            <div class="scrollable-content gap-2">
                <div class="step" v-for="(step, s) in mapStore.getRouteResult.routes[selectedRoute].legs[0].steps" :key="s">
                    <b-icon :type="getTravelMode(step).color" class="px-5 py-5 has-icon-radius justify-self-center" pack="fas" :icon="getTravelMode(step).icon"></b-icon>
                    <div class="has-text-left mt-1 is-flex is-flex-direction-row">
                        <p class="is-size-6 has-text-grey-light">
                            <strong class="has-text-weight-semibold">{{ getTravelMode(step).title }}</strong> <span class="distance">({{ getStepInformation(step).distance }})</span>
                        </p>
                        <p class="subtitle is-size-7 ml-4 has-text-grey-light mt-1">{{ getStepInformation(step).instructions }}</p>
                    </div>
                    <div class="ml-auto">
                        <h1 class="title is-size-5 has-text-weight-bold has-text-right">{{ getStepInformation(step).duration }}</h1>
                        <p class="subtitle is-size-7 has-text-right has-text-grey-light">min</p>
                    </div>

                    <b-icon class="justify-self-center" type="is-grey-light" pack="fas" icon="ellipsis-vertical"></b-icon>
                </div>

                <div class="step">
                    <b-icon type="is-primary has-background-primary-light" class="px-5 py-5 has-icon-radius justify-self-center" pack="fas" icon="location-dot"></b-icon>
                    <p class="is-size-6 has-text-grey-light has-text-left">
                        <strong class="has-text-weight-semibold">{{ destination }}</strong>
                    </p>
                </div>
                <b-button @click="openGoogleMaps" class="button has-text-primary has-text-weight-bold mt-2">{{ $t('components.path.navegation') }} </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { useMapStore } from '@/store/map';
    export default {
        data() {
            return {
                selectedRoute: this.$route.params.id,
                routeOrigin: null,
                routeDestination: null,
                mapStore: useMapStore(),
            };
        },
        computed: {
            destination() {
                return _.get(this.mapStore.getRouteDestination, 'name', this.$t('components.search.yourlocation'));
            },
            origin() {
                return _.get(this.mapStore.getRouteOrigin, 'name', this.$t('components.search.yourlocation'));
            },
        },
        created() {
            this.routeOrigin = this.mapStore.getRouteOrigin.geometry.location;
            this.routeDestination = this.mapStore.getRouteDestination.geometry.location;
        },
        methods: {
            getSteps(step) {
                if (step.travel_mode == 'TRANSIT') {
                    let hasBorder = false;
                    if (['#ffffff', '#fff'].includes(_.get(step, 'transit_details.line.color', '').toLowerCase())) hasBorder = true;
                    return {
                        style: `background-color: ${step.transit_details.line.color} !important; color: ${step.transit_details.line.text_color} !important;${
                            hasBorder ? 'border: 1px solid #b5b5b5' : ''
                        }`,
                        text: step.transit_details.line.short_name + ' - ' + step.transit_details.line.agencies[0].name,
                    };
                } else if (step.travel_mode == 'WALKING') {
                    return {
                        text: '',
                        style: 'background-color: transparent',
                    };
                }
            },
            getTravelMode(step) {
                if (step.travel_mode == 'TRANSIT') {
                    return {
                        icon: 'subway',
                        color: 'is-purple has-background-purple-light',
                        title: step.transit_details.line.agencies[0].name,
                    };
                } else if (step.travel_mode == 'WALKING') {
                    return {
                        icon: 'person-walking',
                        color: 'is-green has-background-green-light',
                        title: 'A pé',
                    };
                }
            },
            getStepInformation(step) {
                return {
                    distance: step.distance.text,
                    duration: step.duration.text.split(' ')[0],
                    instructions: step.instructions,
                };
            },
            openGoogleMaps() {
                let origLat = typeof this.routeOrigin.lat == 'function' ? this.routeOrigin.lat() : this.routeOrigin.lat;
                let origLng = typeof this.routeOrigin.lng == 'function' ? this.routeOrigin.lng() : this.routeOrigin.lng;
                let destLat = typeof this.routeDestination.lat == 'function' ? this.routeDestination.lat() : this.routeDestination.lat;
                let destLng = typeof this.routeDestination.lng == 'function' ? this.routeDestination.lng() : this.routeDestination.lng;
                let travelmode = this.mapStore.getRouteTravelMode;

                if (navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPod') != -1 || navigator.platform.indexOf('iPad') != -1) this.url = `maps://?q=${destLat},${destLng}'`;
                else this.url = `https://www.google.com/maps/dir/?api=1&travelmode=${travelmode.toLowerCase()}&layer=traffic&destination=${destLat},${destLng}&origin=${origLat},${origLng}`;

                window.open(this.url, '_system');
            },
        },
    };
</script>

<style scoped lang="scss">
    .justify-self-center {
        justify-self: center;
    }
    div.step {
        /* create a grid 2-8-2 and the next row 2-10-0 */
        display: grid;
        grid-template-columns: 2fr 8fr 2fr;
        grid-gap: 0.5rem;
    }
    span.distance {
        white-space: nowrap;
    }
</style>
