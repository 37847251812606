<template>
    <div>
        <div class="columns is-overflowX is-mobile is-multiline is-gapless is-centered mt-2">
            <div class="column is-12-mobile is-11-tablet">
                <RouteStep transportType="subway"></RouteStep>
            </div>
            <div class="column is-12-mobile is-10-tablet my-2">
                <img src="@/assets/icons/3circles.svg" class="image is-32x32 ml-3" />
            </div>
            <div class="column is-12-mobile is-11-tablet">
                <RouteStep transportType="walk"></RouteStep>
            </div>

            <div class="column is-11-mobile is-10-tablet my-2">
                <img src="@/assets/icons/3circles.svg" class="image is-32x32 ml-3" />
            </div>
            <div class="column is-11-mobile is-11-tablet">
                <RouteStep transportType="bus"></RouteStep>
            </div>
            <div class="column is-11-mobile is-10-tablet my-2">
                <img src="@/assets/icons/3circles.svg" class="image is-32x32 ml-3" />
            </div>
            <div class="column is-11-mobile is-11-tablet">
                <RouteStep transportType="car"></RouteStep>
            </div>
            <div class="column is-11-mobile is-10-tablet my-2">
                <img src="@/assets/icons/3circles.svg" class="image is-32x32 ml-3" />
            </div>
            <div class="column is-11-mobile is-11-tablet">
                <RouteStep transportType="location"></RouteStep>
            </div>
        </div>
    </div>
</template>

<script>
    import RouteStep from '@/components/ui/RouteStep.vue';

    export default {
        name: 'RouteResult',
        components: {
            RouteStep,
        },
    };
</script>
