<template>
    <div class="hero is-fullheight">
        <div class="hero-body">
            <figure class="logo-name is-hidden-touch">
                <img src="@/assets/img/logo/ioCity_Logo_name.png" />
            </figure>
            <div class="container" :class="errorOnSubmit ? 'has-opacity' : ''" @click="errorOnSubmit = false">
                <figure class="image logo mx-auto is-hidden-desktop">
                    <img src="@/assets/img/logo/ioCity_logo.png" />
                </figure>
                <div class="columns is-flex is-align-items-center">
                    <div class="column is-8-desktop is-hidden-touch">
                        <figure>
                            <img src="@/assets/img/logo/loginIOcity.png" />
                        </figure>
                    </div>
                    <div class="column is-4-desktop">
                        <ValidationObserver v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(onSubmit)">
                                <h1 class="title is-4 has-text-secondary has-text-weight-semibold has-text-left is-hidden-touch">{{ $t('views.initial.home.login') }}</h1>

                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-10-mobile is-4-tablet is-12-desktop">
                                        <ValidationProvider v-slot="{ errors }" rules="required|email" :name="$t('views.initial.login.email')">
                                            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                                                <b-input v-model="form.identifier" required name="email" :placeholder="$t('views.initial.login.email')"> </b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-10-mobile is-4-tablet is-3-desktop is-12-desktop mb-3">
                                        <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('views.initial.login.password')">
                                            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                                                <b-input v-model="form.password" type="password" name="current-password" password-reveal :placeholder="$t('views.initial.login.password')"> </b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-7-mobile is-4-tablet is-6-desktop pb-3">
                                        <b-button native-type="submit" expanded class="submit-button has-text-weight-lighter has-shadow" :loading="isLoading" type="is-primary">
                                            {{ $t('views.initial.login.enter') }}
                                        </b-button>
                                    </div>
                                </div>
                                <div class="columns is-mobile is-centered is-multiline">
                                    <div class="column is-12 pt-2">
                                        <h4 class="has-text-grey is-underlined has-text-weight-light is-size-7 is-clickable" @click="$router.push({ name: 'ForgotPassword' })">
                                            {{ $t('views.initial.login.forget') }}
                                        </h4>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>

                        <div class="is-flex is-justify-content-center mt-5">
                            <b-button @click="googleLogin" class="is-40x40">
                                <figure class="image is-24x24">
                                    <img src="@/assets/icons/google.svg" alt="Google" />
                                </figure>
                            </b-button>
                            <b-button @click="facebookLogin" class="mx-6 is-40x40" type="is-facebook">
                                <b-icon type="is-white" pack="fab" icon="facebook-f"></b-icon>
                            </b-button>
                            <b-button @click="appleLogin" class="is-40x40" type="is-black">
                                <b-icon type="is-white" pack="fab" icon="apple"></b-icon>
                            </b-button>
                        </div>

                        <h4 class="mt-5 has-text-grey has-text-weight-light is-size-7">
                            {{ $t('views.initial.login.account') }}
                        </h4>

                        <a @click="$router.push({ name: 'Register' })" class="has-text-primary has-text-weight-medium is-size-7">
                            {{ $t('views.initial.login.register') }}
                        </a>
                    </div>
                </div>
            </div>
            <nav v-if="errorOnSubmit" class="navbar is-fixed-bottom rounded" role="navigation">
                <div class="has-background-white container is-expanded rounded is-relative pb-5">
                    <div class="columns is-mobile is-multiline is-centered">
                        <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet mt-6">
                            <b-icon type="is-yellow is-large has-background-yellow-light has-border-radius py-6 px-6" pack="fas" icon="lock"></b-icon>
                        </div>
                    </div>
                    <div class="columns is-mobile is-multiline is-centered is-vcentered mb-4 mt-6">
                        <div class="column is-10-mobile is-10-tablet">
                            <h1 class="title is-5">{{ $t('components.notifications.oops') }}</h1>
                        </div>
                        <div class="column is-10-mobile is-10-tablet">
                            <p class="subtitle is-6 has-text-weight-light px-5">{{ $t('components.notifications.dataParagraph') }}</p>
                        </div>
                        <div class="column is-9-mobile is-9-tablet is-6-desktop mt-5">
                            <b-button @click="errorOnSubmit = false" expanded class="has-text-weight-lighter has-shadow mb-4 submit-button" type="is-primary">
                                {{ $t('components.notifications.tryagain') }}
                            </b-button>
                            <h1 @click="$router.push({ name: 'ForgotPassword' })" class="has-text-grey is-underlined is-size-6 is-clickable">
                                {{ $t('components.notifications.forgotPassword') }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    import auth from '@/mixins/auth.js';
    import { login } from '@/api/auth';

    const initialState = () => ({
        isLoading: false,
        form: {
            identifier: 'beatrizpereira@iotech.pt',
            password: '12345678',
        },
    });

    export default {
        name: 'Login',
        mixins: [auth],

        data() {
            return {
                ...initialState(),
                isLoading: false,
                errorOnSubmit: false,
            };
        },
        methods: {
            onSubmit() {
                this.isLoading = true;
                login(this.form)
                    .then((response) => {
                        this.onLoginSuccess(response.data);
                    })
                    .catch((error) => {
                        if (error.response.data.error.message == 'Invalid identifier or password') {
                            this.errorOnSubmit = true;
                            this.isLoading = false;
                        } else
                            this.$buefy.toast.open({
                                message: 'An error has ocurred',
                                position: 'is-top',
                                type: 'is-danger',
                            });
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/_variables.scss';

    .logo {
        max-width: 290px;
    }

    .navbar {
        box-shadow: 0px 0px 20px #00000033;
    }
    .logo-name {
        max-width: 20%;
        position: absolute;
        top: 3%;
        left: 0;
    }
    @media (min-width: 50em) {
        .rounded {
            border-radius: 30px !important;
            display: block;
        }
        .navbar.is-fixed-bottom {
            position: fixed;
            width: 50%;
            bottom: auto;
            margin-inline: auto;
        }
    }
</style>
