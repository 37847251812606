<template>
    <section class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">
                    {{ $t('views.parkmap.title') }}
                </h1>
                <div></div>
            </div>

            <b-carousel-list
                :repeat="true"
                :has-drag="false"
                v-model="selectedFloor"
                class="is-shadowless park-carousel"
                :items-to-show="1"
                :data="floors"
                :arrow-hover="false"
                :indicator="false"
                :autoplay="false">
                <template #item="floor">
                    <h1 class="title is-5 has-text-weight-medium">{{ floor.name }}</h1>
                    <p class="subtitle is-size-9 mt-1">89 {{ $t('views.parkmap.freespot') }}</p>
                    <div class="columns is-overflowX is-mobile is-vcentered mt-3">
                        <div v-for="(section, k) in floor.sections" :key="k" class="column is-4-mobile is-3-tablet">
                            <b-button @click="onSelectedSection(section)" :type="selectedSection.name == section.name ? 'is-primary has-shadow' : 'is-grey is-borderless'" class="button is-small">
                                <p class="is-size-6">{{ section.name }}</p>
                            </b-button>
                        </div>
                    </div>
                    <div v-for="(subsection, k) in selectedSection.subsections" :key="k" class="columns is-mobile mt-4">
                        <div class="column is-3-mobile is-2-tablet">
                            <ParkSecction :selectedFloor="floors[selectedFloor].id" :selectedSection="selectedSection.id" :subSection="subsection"></ParkSecction>
                        </div>
                    </div>
                </template>
            </b-carousel-list>
            <div class="is-flex is-flex-direction-column gap-4 mt-auto">
                <b-button
                    :disabled="!$route.query.spot"
                    @click="$router.push({ name: 'ParkReservation', query: { ...$route.query } })"
                    expanded
                    class="has-text-weight-lighter has-shadow"
                    type="is-primary">
                    <p class="is-size-6">{{ $t('views.parkmap.dial') }}</p>
                </b-button>
                <b-button @click="$router.push({ name: 'BottomMenu' })" expanded class="has-text-weight-lighter has-shadow" type="is-primary">
                    <p class="is-size-6">{{ $t('views.parkmap.withoutdial') }}</p>
                </b-button>
                <!-- <b-button @click="$router.push({ name: 'ParkMap' })" expanded class="has-text-weight-semibold" type="has-text-primary">
                    <p class="is-size-7">{{ $t('views.parkmap.random') }}</p>
                </b-button> -->
            </div>
        </div>
    </section>
</template>

<script>
    import ParkSecction from '@/components/parking/ParkSecction.vue';
    export default {
        name: 'ParkMap',
        components: {
            ParkSecction,
        },
        data() {
            return {
                selectedFloor: 0,
                selectedSection: null,

                floors: [
                    {
                        id: 1,
                        name: '1º andar',
                        sections: [
                            {
                                id: 1,
                                name: 'Secção A',
                                subsections: [
                                    {
                                        id: 1,
                                        name: 'A1',
                                        spots: [
                                            {
                                                id: 1,
                                                name: 'A130',
                                            },
                                            {
                                                id: 2,
                                                name: 'A131',
                                            },
                                        ],
                                    },
                                    {
                                        id: 2,
                                        name: 'A2',
                                        spots: [
                                            {
                                                id: 3,
                                                name: 'A230',
                                            },
                                            {
                                                id: 4,
                                                name: 'A231',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                id: 2,
                                name: 'Secção B',
                                subsections: [
                                    {
                                        id: 3,
                                        name: 'B1',
                                        spots: [
                                            {
                                                id: 5,
                                                name: 'B130',
                                            },
                                            {
                                                id: 6,
                                                name: 'B131',
                                            },
                                            {
                                                id: 7,
                                                name: 'B132',
                                            },
                                        ],
                                    },
                                    {
                                        id: 4,
                                        name: 'B2',
                                        spots: [
                                            {
                                                id: 8,
                                                name: 'B230',
                                            },
                                            {
                                                id: 9,
                                                name: 'B231',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                name: 'Secção C',
                                subsections: [
                                    {
                                        name: 'C1',
                                        spots: [
                                            {
                                                name: 'C130',
                                            },
                                            {
                                                name: 'C131',
                                            },
                                            {
                                                name: 'C132',
                                            },
                                        ],
                                    },
                                    {
                                        name: 'C2',
                                        spots: [
                                            {
                                                name: 'C230',
                                            },
                                            {
                                                name: 'C231',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                name: 'Secção D',
                                subsections: [
                                    {
                                        name: 'D1',
                                        spots: [
                                            {
                                                name: 'D130',
                                            },
                                            {
                                                name: 'D131',
                                            },
                                            {
                                                name: 'D132',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 2,
                        name: '2º andar',
                        sections: [
                            {
                                name: 'Secção A',
                                subsections: [
                                    {
                                        name: 'A1',
                                        spots: [
                                            {
                                                name: 'A130',
                                            },
                                            {
                                                name: 'A131',
                                            },
                                        ],
                                    },
                                    {
                                        name: 'A2',
                                        spots: [
                                            {
                                                name: 'A230',
                                            },
                                            {
                                                name: 'A231',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                name: 'Secção B',
                                subsections: [
                                    {
                                        name: 'B1',
                                        spots: [
                                            {
                                                name: 'B130',
                                            },
                                            {
                                                name: 'B131',
                                            },
                                        ],
                                    },
                                    {
                                        name: 'B2',
                                        spots: [
                                            {
                                                name: 'B230',
                                            },
                                            {
                                                name: 'B231',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };
        },
        created() {
            this.selectedSection = this.floors[0].sections[0];
        },
        methods: {
            onSelectedSection(section) {
                this.selectedSection = section;
            },
        },
    };
</script>
