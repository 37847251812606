<template>
    <div>
        <div class="navbar">
            <div class="columns is-mobile is-multiline is-vcentered mb-5 mt-2">
                <div class="column is-1-mobile is-1-tablet is-2-desktop">
                    <b-button @click="$router.go(-1)" size="is-40x40" class="button is-primary is-pulled-left ml-5">
                        <b-icon type="is-white py-1	px-1" pack="fas" icon="angle-left"> </b-icon>
                    </b-button>
                </div>
                <div class="column is-10-mobile is-11-tablet is-8-desktop">
                    <h1 class="title is-5 has-text-center">{{ $t('components.results.title') }}</h1>
                </div>
                <div class="column is-1-mobile is-11-tablet is-8-desktop"></div>
            </div>
        </div>
        <div class="columns is-mobile is-multiline is-centered mt-3">
            <div class="column is-11-mobile is-11-tablet">
                <DestinationInfo pageSelected="result"></DestinationInfo>
            </div>
        </div>
        <div class="columns is-mobile is-multiline is-centered mt-2">
            <div class="column is-12-mobile is-12-tablet is-centered">
                <div class="columns is-mobile is-centered">
                    <div class="column is-3-mobile is-3-tablet">
                        <b-button @click="changeResultType('parks')" size="is-50x50" :class="selectedResultType == 'parks' ? 'is-primary' : 'has-background-grey-lighter'" class="button has-shadow">
                            <b-icon pack="fas" icon="p"></b-icon>
                        </b-button>
                        <p class="is-size-7 mt-3" :class="selectedResultType == 'parks' ? 'has-text-primary' : 'has-text-grey-light'">{{ $t('components.results.parks') }}</p>
                    </div>
                    <div class="column is-3-mobile is-3-tablet">
                        <b-button @click="changeResultType('train')" size="is-50x50" :class="selectedResultType == 'train' ? 'is-primary' : 'has-background-grey-lighter'" class="button has-shadow">
                            <b-icon pack="fas" icon="train-subway"></b-icon>
                        </b-button>
                        <p class="is-size-7 mt-3" :class="selectedResultType == 'train' ? 'has-text-primary' : 'has-text-grey-light'">{{ $t('components.results.train') }}</p>
                    </div>
                    <div class="column is-3-mobile is-3-tablet">
                        <b-button @click="changeResultType('bus')" size="is-50x50" :class="selectedResultType == 'bus' ? 'is-primary' : 'has-background-grey-lighter'" class="button has-shadow">
                            <b-icon pack="fas" icon="bus"></b-icon>
                        </b-button>
                        <p class="is-size-7 mt-3" :class="selectedResultType == 'bus' ? 'has-text-primary' : 'has-text-grey-light'">{{ $t('components.results.bus') }}</p>
                    </div>
                    <div class="column is-3-mobile is-3-tablet">
                        <b-button @click="changeResultType('bus')" size="is-50x50" :class="selectedResultType == 'bus' ? 'is-primary' : 'has-background-grey-lighter'" class="button has-shadow">
                            <b-icon pack="fas" icon="bus"></b-icon>
                        </b-button>
                        <p class="is-size-7 mt-3" :class="selectedResultType == 'bus' ? 'has-text-primary' : 'has-text-grey-light'">{{ $t('components.results.bus') }}</p>
                    </div>
                </div>
            </div>
            <div class="column is-11-mobile is-12-tablet is-centered mt-3"><ParkInformation /><ParkInformation /><ParkInformation /></div>
            <!--         <div class="column is-10-mobile is-10-tablet">
                <b-collapse :open="false" class="card has-shadow" animation="slide" aria-id="contentIdForA11y3">
                    <template #trigger="props">
                        <div class="card-header p-2" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                            <b-icon class="mr-5" type="is-pink has-background-pink-light has-icon-radius py-5 px-5" pack="fas" icon="p"></b-icon>
                            <p class="card-header-title has-text-weight-normal">{{ $t('components.results.parking') }}</p>
                            <a class="card-header-icon">
                                <b-icon type="is-grey" :icon="props.open ? 'angle-up' : 'angle-down'"> </b-icon>
                            </a>
                        </div>
                    </template>
                    <div class="card-content">
                        <div class="content">
                            <ParkInfoSimple />
                        </div>
                    </div>
                </b-collapse>
            </div>
            <div class="column is-10-mobile is-10-tablet">
                <b-collapse :open="false" class="card has-shadow" animation="slide" aria-id="contentIdForA11y3">
                    <template #trigger="props">
                        <div class="card-header p-2" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                            <b-icon class="mr-5" type="is-purple has-background-purple-light has-icon-radius py-5 px-5" pack="fas" icon="train-subway"></b-icon>
                            <p class="card-header-title has-text-weight-normal">{{ $t('components.results.train') }}</p>
                            <a class="card-header-icon">
                                <b-icon type="is-grey" :icon="props.open ? 'angle-up' : 'angle-down'"> </b-icon>
                            </a>
                        </div>
                    </template>
                    <div class="card-content">
                        <div class="content">
                            <TransportSchedule transportType="subway"></TransportSchedule>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <div class="column is-10-mobile is-10-tablet">
                <b-collapse :open="false" class="card has-shadow" animation="slide" aria-id="contentIdForA11y3">
                    <template #trigger="props">
                        <div class="card-header p-2" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                            <b-icon class="mr-5" type="is-yellow has-background-yellow-light has-icon-radius p-5" pack="fas" icon="bus"></b-icon>
                            <p class="card-header-title has-text-weight-normal">{{ $t('components.results.bus') }}</p>
                            <a class="card-header-icon">
                                <b-icon type="is-grey" :icon="props.open ? 'angle-up' : 'angle-down'"> </b-icon>
                            </a>
                        </div>
                    </template>

                    <div class="card-content">
                        <div class="content">
                            <TransportSchedule transportType="bus" />
                        </div>
                    </div>
                </b-collapse>
            </div> -->
        </div>
    </div>
</template>

<script>
    import DestinationInfo from '@/components/ui/DestinationInfo.vue';
    import TransportSchedule from '@/components/ui/TransportSchedule.vue';
    import ParkInfoSimple from '@/components/ui/ParkInfoSimple.vue';
    import ParkInformation from '@/components/ui/ParkInformation.vue';
    export default {
        name: 'ResearchResults',
        components: {
            DestinationInfo,
            TransportSchedule,
            ParkInfoSimple,
            ParkInformation,
        },
        data() {
            return {
                selectedResultType: 'parks',
            };
        },
        methods: {
            changeResultType(type) {
                this.selectedResultType = type;
            },
        },
    };
</script>

<style scoped>
    .card,
    .card-header {
        border-radius: 14px;
    }
    .is-50x50 {
        width: 50px;
        height: 50px;
    }
</style>
