import { defineStore } from 'pinia';

const loc = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

export const useAppStore = defineStore({
    id: 'app',
    state: () => ({
        selectedMenu: 'NearMe',
        isShowOpen: false,
        isDarkModeActive: false,
        locale: loc.toLowerCase().includes('pt') ? 'pt' : 'en',
        cardSize: 'x-large',
        hasLocationPermission: false,
        userDeclinedLocationPermission: false,
        hasAppInstalled: false,
        userDeclinedAppInstall: false,
        notificationsToken: '',
    }),
    getters: {
        getSelectedMenu(state) {
            return state.selectedMenu;
        },
        getIsShowOpen(state) {
            return state.isShowOpen;
        },
        getDarkMode(state) {
            return state.isDarkModeActive;
        },
        getLocale(state) {
            return state.locale;
        },
        getCardSize(state) {
            return state.cardSize;
        },
        getHasLocationPermission(state) {
            return state.hasLocationPermission;
        },
        getUserDeclinedLocationPermission(state) {
            return state.userDeclinedLocationPermission;
        },
        getHasAppInstalled(state) {
            return state.hasAppInstalled;
        },
        getUserDeclinedAppInstall(state) {
            return state.userDeclinedAppInstall;
        },
        getNotificationsToken(state) {
            return state.notificationsToken;
        }
    },
    actions: {
        setSelectedMenu(menu) {
            this.selectedMenu = menu;
        },
        setIsShowOpen(isShowOpen) {
            this.isShowOpen = isShowOpen;
        },
        setDarkMode(darkmode) {
            const htmlClassName = 'dark-mode';
            this.isDarkModeActive = darkmode;
            if (darkmode) {
                document.documentElement.classList.add(htmlClassName);
            } else {
                document.documentElement.classList.remove(htmlClassName);
            }
        },
        setLocale(locale) {
            this.locale = locale;
        },
        setCardSize(cardSize) {
            this.cardSize = cardSize;
        },
        setHasLocationPermission(hasLocationPermission) {
            this.hasLocationPermission = hasLocationPermission;
        },
        setUserDeclinedLocationPermission(userDeclinedLocationPermission) {
            this.userDeclinedLocationPermission = userDeclinedLocationPermission;
        },
        setHasAppInstalled(hasAppInstalled) {
            this.hasAppInstalled = hasAppInstalled;
        },
        setUserDeclinedAppInstall(userDeclinedAppInstall) {
            this.userDeclinedAppInstall = userDeclinedAppInstall;
        },
        setNotificationsToken(notificationsToken) {
            this.notificationsToken = notificationsToken;
        }
    },
    persist: true,
});
