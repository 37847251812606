<template>
    <div>
        <article class="media">
            <div class="media-left">
                <figure>
                    <img :src="getParkPic" class="image is-55x55 has-border-radius" />
                </figure>
            </div>
            <div class="media-content is-clipped">
                <div class="content has-text-left">
                    <div v-if="this.authStore.isGuest == false" class="columns is-mobile">
                        <div @click="$router.push({ name: 'ParkDetails', params: { id: park.id } })" class="column is-10">
                            <h5 class="title is-size-6 is-family-primary pb-1">{{ park.name }}</h5>
                            <p class="subtitle is-7 is-family-secondary has-text-grey-light">{{ park.address }}</p>
                        </div>

                        <div class="column is-2">
                            <b-button
                                :loading="isLoading"
                                @click="setFavorite"
                                :type="favorite ? 'has-text-pink' : 'has-text-grey-light'"
                                class="is-borderless is-white is-small"
                                :class="{ 'fa-spin-pulse': isLoading }"
                                icon-pack="fas"
                                :icon-right="!isLoading ? 'heart' : 'spinner'" />
                            <b-button
                                @click="$router.push({ name: 'ParkDetails', params: { id: park.id } })"
                                type="has-text-primary"
                                class="is-borderless is-white is-small"
                                icon-pack="fas"
                                icon-right="plus" />
                        </div>
                    </div>
                    <div v-else class="columns is-mobile is-vcentered">
                        <div @click="$router.push({ name: 'ParkDetails', params: { id: park.id } })" class="column is-7">
                            <h5 class="title is-size-6 is-family-primary pb-1">{{ park.name }}</h5>
                            <p class="subtitle is-7 is-family-secondary has-text-grey-light">{{ park.address }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>
<script>
    import { addFavorites, removeFavorites } from '@/api/favorites';
    import _ from 'lodash';
    export default {
        name: 'ParkInfoSimple',
        data() {
            return {
                isLoading: false,
                favoriteObj: {
                    parkingLot: 'string or id',
                    type: 'parkingLot',
                    user: this.authStore.getUser.id,
                },
            };
        },

        methods: {
            async setFavorite() {
                this.isLoading = true;
                this.favoriteObj.parkingLot = this.park.id;

                if (!this.favorite) {
                    await addFavorites(this.favoriteObj).then((response) => {
                        this.$store.commit('favorites/addToFavorites', response.data.data);
                    });
                } else {
                    await removeFavorites(this.favorite.id).then((response) => {
                        this.$store.commit('favorites/removeFromFavorites', this.favorite);
                    });
                }

                this.isLoading = false;
            },
        },
        computed: {
            favorite() {
                return this.$store.getters['favorites/getFavorites'].find((favorite) => favorite.type == 'parkingLot' && favorite['parkingLot'].id == this.park.id);
            },
            getParkPic() {
                if (this.park.pictures) {
                    if (this.park?.pictures[0]?.formats?.small?.url) {
                        return this.park.pictures[0].formats.small.url;
                    } else {
                        return this.park.pictures[0].url;
                    }
                } else return require('@/assets/img/examples/parque.jpg');
            },
            distance() {
                return _.get(this.park.distance, 'distance.text', null);
            },
            duration() {
                return _.get(this.park.distance, 'duration.text', '').split(' ')[0];
            },
        },
        props: {
            park: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
    };
</script>
<style scoped lang="scss">
    @import '@/assets/scss/variables.scss';
    .has-icon-euro-radius {
        border-radius: 8px;
        width: 12px;
    }
    .has-border-radius {
        border-radius: 13px;
    }
    .is-55x55 {
        width: 55px;
        height: 55px;
    }
</style>
