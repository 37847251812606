<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-4">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.transport.title') }}</h1>
                <div></div>
            </div>
            <div class="is-flex is-flex-direction-row gap-2 is-justify-content-space-between is-align-items-center">
                <b-tag type="is-light is-medium">{{ selectedDate }}</b-tag>
                <b-tag type="is-light is-medium">{{ selectedHour }}</b-tag>
                <b-button @click="$router.push({ name: 'TransportFilters' })" size="is-44x44" class="is-white has-shadow is-flex">
                    <b-icon type="is-grey-light is-medium" pack="fas" icon="sliders"></b-icon>
                </b-button>
            </div>
            <div class="scrollable-content">
                <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"> <b-icon custom-class="fa-spin" pack="fas" icon="spinner" size="is-large"> </b-icon></b-loading>
                <template v-if="trips.length > 0">
                    <div v-for="(trip, index) in trips" :key="index">
                        <TransportSchedule :trip="trip" :index="index"></TransportSchedule>
                        <hr class="mt-4 mb-0" />
                    </div>
                </template>
                <p v-else-if="trips.length == 0 && !isLoading" class="mt-4">{{ $t('components.results.noResults') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import TransportSchedule from '@/components/ui/TransportSchedule.vue';
    import { getSchedules } from '@/api/routes';
    import moment from 'moment';
    import _ from 'lodash';
    import { useTripStore } from '@/store/trip';

    export default {
        name: 'TransportResults',
        components: {
            TransportSchedule,
        },
        data() {
            return {
                trips: [],
                isLoading: false,
                tripStore: useTripStore(),
            };
        },
        created() {
            this.getSchedules();
        },
        computed: {
            selectedDate() {
                return `${this.$t('components.bussearch.day')}: ${moment(this.$route.query.time).format('DD/MM/YYYY')}`;
            },
            selectedHour() {
                return `${this.$t('components.bussearch.hour')}: ${moment(this.$route.query.time).format('HH:mm')}`;
            },
            query() {
                return this.tripStore.getQuery;
            },
        },
        methods: {
            async getSchedules() {
                this.isLoading = true;
                await getSchedules({
                    from: this.$route.query.from,
                    to: this.$route.query.to,
                    time: this.$route.query.time,
                    timeType: this.$route.query.timeType,
                    agencies: _.get(this.query, 'agencies', []),
                    transportTypes: _.get(this.query, 'transportTypes', []),
                    sort: _.get(this.query, 'sort', 'departure_time'),
                }).then((response) => {
                    this.isLoading = false;
                    // this.tripStore.setTrips(response.data.data);
                    this.trips = response.data.data;
                    this.tripStore.setAgencies(
                        this.trips
                            .map((trip) => {
                                return trip.transport.agency;
                            })
                            .filter((value, index, self) => {
                                // remove duplicate objects
                                return self.findIndex((t) => t.id == value.id) == index;
                            }),
                    );
                    this.tripStore.setTransportTypes(
                        this.trips
                            .map((trip) => {
                                return trip.transport.type;
                            })
                            .filter((value, index, self) => self.indexOf(value) == index),
                    );
                });
            },
        },
    };
</script>

<style scoped>
    .has-icon-euro-radius {
        border-radius: 8px;
        width: 16px;
    }
</style>
