import { useBreakpoints } from '@vueuse/core';
import { computed } from 'vue';

export default function useDesktop() {
    const isDesktop = computed(() => {
        return useBreakpoints({ desktop: 840 }).greaterOrEqual('desktop').value;
    });

    return { isDesktop };
}
