<template>
    <nav class="navbar is-fixed-bottom rounded" role="navigation">
        <div class="navbar has-background-grey-darker container is-expanded rounded is-relative">
            <div class="columns is-mobile is-multiline is-centered">
                <div @click="$router.push({ name: 'BottomMenu' })">
                    <hr class="whiteline mb-0" />
                </div>
            </div>
            <div class="columns is-mobile is-multiline is-centered">
                <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet">
                    <b-button @click="$router.push({ name: 'RouteDetails' })" class="info" type="has-text-white has-background-primary has-icon-radius" icon-right="info" />
                    <p class="title is-size-5 has-text-weight-medium has-text-grey-light mt-5">Nome do destino</p>
                    <p class="subtitle is-size-6 has-text-weight-light has-text-grey-light">Rua do destino</p>
                </div>
            </div>
            <div class="columns is-mobile is-multiline is-centered mt-5">
                <b-icon type="is-white has-icon-radius py-5 px-5" pack="fas" icon="subway"></b-icon>
                <b-icon type="is-grey-light has-icon-radius py-5 px-4" size="is-small" pack="fas" icon="ellipsis"></b-icon>
                <b-icon type="is-white  has-background-primary has-icon-radius py-5 px-5" pack="fas" icon="person-walking"></b-icon>
                <b-icon type="is-grey-light has-icon-radius py-5 px-4" size="is-small" pack="fas" icon="ellipsis"></b-icon>
                <b-icon type="is-white has-icon-radius py-5 px-5" pack="fas" icon="bus"></b-icon>
                <b-icon type="is-grey-light has-icon-radius py-5 px-4" size="is-small" pack="fas" icon="ellipsis"></b-icon>
                <b-icon type="is-white has-icon-radius py-5 px-5" pack="fas" icon="location-dot"></b-icon>
            </div>
            <div @click="$router.push({ name: 'CompletedRoute' })" class="navbar has-background-white rounded mt-5 py-6">
                <div class="columns is-mobile is-vcentered is-flex is-justify-content-center px-6">
                    <div class="column is-3-mobile is-3-tablet">
                        <p class="is-size-5 has-text-grey-light">{{ $t('components.path.distance') }}</p>
                        <p class="subtitle is-size-5 has-text-grey-dark has-text-weight-semibold">3 km</p>
                    </div>
                    <div class="column is-6-mobile is-6-tablet">
                        <div class="columns is-multiline is-flex is-justify-content-center">
                            <div class="column is-8-mobile is-12-tablet">
                                <b-icon type="is-green" pack="fas" icon="person-walking"></b-icon>
                            </div>
                            <div class="column is-10-mobile is-12-tablet">
                                <b-progress type="is-green" :value="40"></b-progress>
                            </div>
                        </div>
                    </div>
                    <div class="column is-3-mobile is-3-tablet">
                        <p class="is-size-5 has-text-grey-light has-text-right">{{ $t('components.path.time') }}</p>
                        <p class="subtitle is-size-5 has-text-grey-dark has-text-weight-semibold has-text-right">1 min</p>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'RouteDirection',
        props: ['transportType'],
        data() {
            return {
                types: {
                    subway: {
                        name: 'Comboio',
                        icon: 'subway',
                        color: 'purple',
                    },
                    bus: {
                        name: 'Autocarro',
                        icon: 'bus',
                        color: 'yellow',
                    },
                    car: {
                        name: 'Carro',
                        icon: 'car',
                        color: 'pink',
                    },
                    walk: {
                        name: 'A pé',
                        icon: 'person-walking',
                        color: 'green',
                    },
                    location: {
                        name: 'Destino',
                        icon: 'location-dot',
                        color: 'primary',
                    },
                },
            };
        },
    };
</script>

<style scoped>
    .info {
        position: absolute;
        transform: translate(200%, -100%);
        padding-right: 20px;
        padding-left: 20px;
    }
</style>
