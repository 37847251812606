<template>
    <article @click="goToSubscribe" class="is-flex is-flex-direction-column gap-4">
        <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center gap-2">
            <figure>
                <img class="image is-55x55 has-border-radius" :src="getAgencyPic" />
            </figure>
            <div class="is-flex-grow-1 has-text-left is-flex is-flex-direction-column is-justify-content-space-between is-align-self-flex-start">
                <h5 class="title is-size-6 is-family-primary pb-1 mt-2">{{ getAgencyName }}</h5>
                <p class="subtitle is-7 is-family-secondary has-text-grey-light">{{ trip.route.desc }}</p>
            </div>
            <div class="fit-content">
                <h5 class="title is-size-5 has-text-weight-bold has-text-right">
                    <span v-if="getDuration.hours > 0">{{ getDuration.hours }}<span class="is-size-7">h</span></span>
                    <span v-if="getDuration.minutes > 0">{{ getDuration.minutes }}<span class="is-size-7">min</span></span>
                </h5>
            </div>
            <div v-if="this.authStore.isGuest == false" class="fit-content">
                <b-button
                    :loading="isLoading"
                    @click="setFavorite"
                    :type="trip.favoriteId ? 'has-text-pink' : 'has-text-grey-light'"
                    class="is-borderless is-white is-small"
                    :class="{ 'fa-spin-pulse': isLoading }"
                    icon-pack="fas"
                    :icon-right="!isLoading ? 'heart' : 'spinner'" />
            </div>
        </div>
        <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
            <div class="is-flex is-flex-direction-row is-align-items-flex-start gap-1">
                <img :src="require('@/assets/icons/yellowE.svg')" />
                <div class="is-flex is-flex-direction-column">
                    <p class="is-size-7 has-text-left has-text-weight-semibold">{{ getFromName }}</p>
                    <p class="is-size-9 has-text-left">{{ getFromTime }}h</p>
                </div>
            </div>
            <b-icon type="is-yellow" class="is-small" pack="fas" icon="arrow-right-long"></b-icon>
            <div class="is-flex is-flex-direction-row is-align-items-flex-start gap-1">
                <div class="is-flex is-flex-direction-column is-align-items-flex-end">
                    <p class="is-size-7 has-text-left has-text-weight-semibold">{{ getToName }}</p>
                    <p class="is-size-9 has-text-left">{{ getToTime }}h</p>
                </div>
                <img :src="require('@/assets/icons/yellowE.svg')" />
            </div>
        </div>
        <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div class="has-background-orange-light has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-orange" pack="fas" size="icon-box--icon" icon="euro-sign"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">{{ getPrice }} €</p>
            </div>
            <div v-if="index == 0" class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div class="has-background-red-lighter has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-red" pack="far" size="icon-box--icon" icon="clock"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">5 min</p>
            </div>
            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div class="has-background-green-light has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-success" pack="fas" size="icon-box--icon" icon="bus"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">{{ getFreeSeats }} {{ $t('components.transport.seats') }}</p>
            </div>
            <!-- <b-button size="is-small" type="is-primary">Acompanhar autocarro</b-button> -->
        </div>
    </article>
</template>

<script>
    import _ from 'lodash';
    import moment from 'moment';
    import useFavorites from '@/mixins/useFavorites';
    import { useAuthStore } from '@/store/auth';

    export default {
        name: 'TransportSchedule',
        data() {
            return {
                isLoading: false,
                useFavorites: useFavorites(),
                authStore: useAuthStore(),
            };
        },
        props: {
            trip: {
                type: Object,
                default() {
                    return {};
                },
            },
            index: {
                type: Number,
                default: 0,
            },
        },
        computed: {
            getAgencyPic() {
                return _.get(this.trip, 'transport.agency.pictures[0].formats.small.url', _.get(this.trip, 'transport.agency.pictures[0].url', require('@/assets/img/examples/parque.jpg')));
            },
            getAgencyName() {
                return _.get(this.trip, 'transport.agency.name', '');
            },
            getFromName() {
                return _.get(this.getFromStop, 'stop.name', '');
            },
            getToName() {
                return _.get(this.getToStop, 'stop.name', '');
            },
            getFromTime() {
                let time = _.get(this.getFromStop, 'departure_time', '');
                return moment(time, 'HH:mm:ss').format('HH:mm');
            },
            getToTime() {
                let time = _.get(this.getToStop, 'arrival_time', '');
                return moment(time, 'HH:mm:ss').format('HH:mm');
            },
            getFromStop() {
                return _.get(this.trip, 'stop_times[0]', {});
            },
            getToStop() {
                return _.get(this.trip, 'stop_times[1]', {});
            },
            getFreeSeats() {
                return `${_.get(this.trip, 'transport.totalCapacity', '?')}`;
            },
            getDuration() {
                const departureTimeMoment = moment(this.getFromTime, 'HH:mm');
                const arrivalTimeMoment = moment(this.getToTime, 'HH:mm');
                if (arrivalTimeMoment.isBefore(departureTimeMoment)) {
                    arrivalTimeMoment.add(1, 'day');
                }
                const duration = moment.duration(arrivalTimeMoment.diff(departureTimeMoment));
                const hours = duration.hours();
                const minutes = duration.minutes();

                return {
                    hours,
                    minutes,
                };
            },
            getPrice() {
                return this.trip.price;
            },
        },

        methods: {
            async setFavorite() {
                this.isLoading = true;
                if (!this.trip.favoriteId) {
                    let favorite = await this.useFavorites.addFavorite({
                        type: 'trip+stop_times',
                        trip: this.trip.id,
                        stop_times: [this.getFromStop.id, this.getToStop.id],
                    });
                    this.trip.favoriteId = favorite.id;
                } else {
                    await this.useFavorites.removeFavorite(this.trip.favoriteId);
                    this.trip.favoriteId = null;
                }
                this.isLoading = false;
            },
            goToSubscribe() {
                this.$router.push({
                    name: 'TransportSubscribe',
                    query: {
                        trip: this.trip.id,
                        from: this.getFromStop.id,
                        to: this.getToStop.id,
                    },
                });
            },
        },
    };
</script>
<style scoped>
    .has-icon-euro-radius {
        border-radius: 0.5rem;
    }
    .has-border-radius {
        border-radius: 0.75rem;
        border: 1px solid #e8e8e8;
    }
    .is-55x55 {
        width: 55px;
        height: 55px;
        object-fit: contain;
    }
</style>
