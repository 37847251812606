import { defineStore } from 'pinia';
import _ from 'lodash';

const defaultQuery = {
    from: null,
    to: null,
    time: null,
    timeType: null,
    transportTypes: [],
    agencies: [],
    sort: 'departure_time',
};

export const useTripStore = defineStore({
    id: 'trip',
    state: () => ({
        trips: [],
        query: _.cloneDeep(defaultQuery),
        agencies: [],
        transportTypes: [],
        subscriptionId: null,
    }),
    getters: {
        getTrips(state) {
            return state.trips;
        },
        getQuery(state) {
            return state.query;
        },
        getAgencies(state) {
            return state.agencies;
        },
        getTransportTypes(state) {
            return state.transportTypes;
        },
        getSubscriptionId(state) {
            return state.subscriptionId;
        },
    },
    actions: {
        setTrips(trips) {
            this.trips = trips;
        },
        setQuery(query) {
            this.query = query;
        },
        setAgencies(agencies) {
            this.agencies = agencies;
        },
        setTransportTypes(transportTypes) {
            this.transportTypes = transportTypes;
        },
        setSubscriptionId(id) {
            this.subscriptionId = id;
        },
    },
    persist: {
        paths: ['subscriptionId'],
    },
});
