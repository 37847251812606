<template>
    <section class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.push({ name: 'BottomMenu' })" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">
                    {{ $t('views.profile.profile.title') }}
                </h1>
                <div></div>
            </div>
            <div class="is-flex is-flex-direction-column is-align-items-center gap-4">
                <figure class="has-border-radius">
                    <b-loading :is-full-page="true" v-model="picLoading" :can-cancel="true"></b-loading>
                    <img :src="getPic" />
                    <b-field v-if="authStore.isGuest == false" class="file m-0" :class="{ 'has-name': !!file }">
                        <b-upload @input="onFileInput" v-model="file" class="file-label">
                            <span class="editbutton file-cta has-icon-radius">
                                <b-icon class="file-icon" pack="fas" icon="pencil"></b-icon>
                            </span>
                        </b-upload>
                    </b-field>
                </figure>

                <h1 v-if="authStore.isGuest == false" class="title is-5">{{ userName }}</h1>
                <h1 v-else class="title is-5">{{ $t('views.profile.profile.guest') }}</h1>
            </div>
            <div class="is-flex is-flex-direction-column gap-4">
                <b-button
                    v-if="authStore.isGuest == false"
                    @click="$router.push({ name: isDesktop ? 'Main-PersonalData' : 'PersonalData' })"
                    expanded
                    class="has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-green has-background-green-light has-icon-radius py-5 px-5" pack="fas" icon="user"></b-icon>
                    {{ $t('views.profile.profile.personal') }}
                    <b-icon class="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>
                <b-button @click="$router.push({ name: isDesktop ? 'Main-Settings' : 'Settings' })" expanded class="has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-purple has-background-purple-light has-icon-radius py-5 px-5" pack="fas" icon="gears"></b-icon>
                    {{ $t('views.profile.profile.settings') }}
                    <b-icon class="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>
                <b-button @click="$router.push({ name: isDesktop ? 'Main-Help' : 'Help' })" expanded class="has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-yellow has-background-yellow-light has-icon-radius py-5 px-5" pack="fas" icon="question"></b-icon>
                    {{ $t('views.profile.profile.help') }}
                    <b-icon class="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>
            </div>
            <div>
                <b-button @click="logOut" expanded :loading="isLoading" class="has-text-weight-lighter has-shadow" type="is-primary">{{ $t('views.profile.profile.logout') }}</b-button>
            </div>
            <p class="mt-auto is-size-7">v{{ version }}</p>
        </div>
    </section>
</template>
<script>
    import { uploadFile } from '@/api/upload';
    import { updateUser } from '@/api/auth';
    import { useAuthStore } from '@/store/auth';
    import useDesktop from '@/mixins/useDesktop';
    import _ from 'lodash';

    export default {
        name: 'Profile',
        data() {
            return {
                file: null,
                isLoading: false,
                picLoading: false,
                authStore: useAuthStore(),
                version: process.env.VUE_APP_VERSION,
                useDesktop: useDesktop(),
            };
        },
        created() {},
        computed: {
            getPic() {
                return _.get(this.authStore.getUser, 'picture.formats.medium.url', _.get(this.authStore.getUser, 'picture.url', require('@/assets/img/logo/profile.png')));
            },
            isDesktop() {
                return this.useDesktop.isDesktop;
            },
            userName() {
                return _.get(this.authStore.getUser, 'name', '');
            },
        },
        methods: {
            onFileInput() {
                this.picLoading = true;
                uploadFile(this.file)
                    .then((response) => {
                        updateUser({
                            picture: response.data[0].id,
                        })
                            .then((response) => {
                                this.authStore.setUser(response.data);
                                this.$buefy.toast.open({
                                    message: 'Atualizado com sucesso!',
                                    position: 'is-top',
                                    type: 'is-success',
                                });
                            })
                            .catch((error) => {
                                this.$buefy.toast.open({
                                    message: this.$t(`shared.commonError`),
                                    position: 'is-top',
                                    type: 'is-danger',
                                });
                            });
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            message: this.$t(`shared.commonError`),
                            position: 'is-top',
                            type: 'is-danger',
                        });
                    })
                    .finally(() => {
                        this.picLoading = false;
                    });
            },
            logOut() {
                this.isLoading = true;
                this.authStore.resetStore();
                // this.$store.commit('map/setUserLocation', false);
                // this.$store.commit('recents/resetRecent');
                // this.$store.commit('transportRoutes/resetScheduleQuery');
                // this.$store.commit('transportRoutes/resetSearch');
                this.$router.push({ name: 'HomeDemo' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';

    .image {
        width: 200%;
    }
    .editbutton {
        width: 50px;
        height: 50px;
        border-color: #7070706b;
        background-color: #eeeeee;
    }

    figure {
        position: relative;
        width: 10rem;
        height: 10rem;
        border-radius: 1.5rem;
        overflow: hidden;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .file {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(0%, 0%);
        }
    }
</style>
