<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="is-flex is-flex-direction-column is-align-items-center mt-4">
                <b-icon class="location" type="is-white has-background-primary has-icon-radius py-5 px-5" pack="fas" icon="location-dot"></b-icon>

                <h1 class="title is-size-4 has-text-weight-semibold my-5">{{ $t('components.parking.arrived') }}</h1>
                <p class="title is-size-5 has-text-weight-medium">{{ destination }}:</p>
                <p class="subtitle is-size-6 has-text-weight-light has-text-grey-light mb-0">{{ destinationAddress }}</p>
                <hr class="fill-width mt-4 mb-0" />
            </div>
            <div class="is-flex is-flex-direction-column is-align-items-center gap-6 fill-width-child">
                <p class="title is-size-5 has-text-weight-semibold has-text-primary mb-0">{{ $t('components.parking.selectSpot') }}</p>

                <div class="is-flex is-flex-direction-row gap-4">
                    <b-button @click="setSelectedWay('manual')" expanded :class="selectedWay == 'manual' ? 'is-primary' : 'has-background-grey-lighter'" class="button has-shadow">
                        <p class="is-size-6-mobile is-size-6-tablet">{{ $t('components.parking.manual') }}</p>
                    </b-button>
                    <b-button @click="setSelectedWay('GPS')" expanded :class="selectedWay == 'GPS' ? 'is-primary' : 'has-background-grey-lighter'" class="button has-shadow">
                        <p class="is-size-6">{{ $t('components.parking.location') }}</p>
                    </b-button>
                </div>
                <div>
                    <div v-if="selectedWay == 'manual'" class="is-flex is-justify-content-space-between gap-4">
                        <b-input class="is-flex-grow-1" type="text" expanded placeholder="ex: A123" v-model="stopText"> </b-input>
                        <b-button :disabled="toggleDisabled" @click="chooseSpot" size="is-40x40" class="button is-primary">
                            <b-icon type="is-white" pack="fas" icon="angle-right"> </b-icon>
                        </b-button>
                    </div>
                    <div v-else class="is-flex is-justify-content-space-between">
                        <b-button outlined @click="useLocation" expanded class="button is-primary"
                            >{{ $t('components.parking.useMyLocation') }}
                            <b-icon type="is-primary ml-4" pack="fas" icon="angle-right"> </b-icon>
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { useMapStore } from '@/store/map';
    import useParks from '@/mixins/useParks';
    import { useAppStore } from '@/store/app';
    import { useParkStore } from '@/store/park';
    import useGeolocation from '@/mixins/useGeolocation';

    export default {
        name: 'ArrivedPark',
        data() {
            return {
                stopText: '',
                selectedWay: 'manual',
                mapStore: useMapStore(),
                useParks: useParks(),
                appStore: useAppStore(),
                parkStore: useParkStore(),
                useGeolocation: useGeolocation(),
                park: null,
            };
        },
        created() {
            this.fetchPark();
        },
        computed: {
            destination() {
                return _.get(this.mapStore.getRouteDestination, 'name', this.$t('components.search.yourlocation'));
            },
            destinationAddress() {
                return _.get(this.mapStore.getRouteDestination, 'formatted_address', null);
            },
            toggleDisabled() {
                return !this.stopText;
            },
        },
        methods: {
            setSelectedWay(type) {
                if (this.selectedWay == type) this.selectedWay = 'GPS';
                else this.selectedWay = type;
            },
            async fetchPark() {
                this.isLoading = true;
                this.park = await this.useParks.getParkById(this.$route.params.id);
                this.isLoading = false;
            },
            chooseSpot() {
                // this.$store.commit('park/setSelectedSpot', this.name);
                this.parkStore.setSavedSpot({
                    text: this.stopText,
                    location: {
                        lat: this.park.latitude,
                        lng: this.park.longitude,
                    },
                });
                this.$router.push({ name: 'ParkingSpot', params: { id: this.$route.params.id } });
            },
            async useLocation() {
                // this.$store.commit('map/setUserLocation', true);
                const { coords } = await this.useGeolocation.getPosition();
                this.parkStore.setSavedSpot({
                    text: this.park.name,
                    location: {
                        lat: coords.latitude,
                        lng: coords.longitude,
                    },
                });
                this.$router.push({ name: 'ParkingSpot', params: { id: this.$route.params.id } });
            },
        },
    };
</script>

<style scoped></style>
