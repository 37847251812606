<template>
    <section class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-8">
            <div class="top-bar">
                <b-button @click="$router.push({ name: isDesktop ? 'Main-Profile' : 'Profile' })" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0 has-text-centered">
                    {{ $t('views.profile.personaldata.title') }}
                </h1>
                <div></div>
            </div>
            <ValidationObserver slim v-slot="{ handleSubmit, invalid }">
                <form @submit.prevent="handleSubmit(onSubmit)" class="is-flex is-flex-direction-column is-align-items-center gap-6 fill-height">
                    <ValidationProvider tag="div" :name="$t('views.initial.register.name')" rules="required" v-slot="{ errors }">
                        <b-input expanded v-model="form.name" type="text" name="name" :placeholder="$t('views.initial.register.name')" :disabled="disabled == 1"></b-input>
                        <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                    <ValidationProvider tag="div" :name="$t('views.initial.register.email')" rules="required|email" v-slot="{ errors }">
                        <b-input class="has-shadow" v-model="form.email" type="email" name="email" :placeholder="$t('views.initial.register.email')" :disabled="disabled == 1"></b-input>
                        <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                    <b-datepicker
                        expanded
                        :mobile-native="false"
                        :max-date="new Date()"
                        v-model="form.birthdate"
                        class="has-text-grey"
                        name="bday"
                        :placeholder="$t('views.initial.register.birthDate')"
                        :disabled="disabled == 1"></b-datepicker>
                    <b-select v-model="form.gender" :disabled="disabled == 1" name="sex" expanded :placeholder="$t('views.profile.personaldata.gender')">
                        <option value="F">{{ $t('views.profile.personaldata.female') }}</option>
                        <option value="M">{{ $t('views.profile.personaldata.male') }}</option>
                        <option value="O">{{ $t('views.profile.personaldata.other') }}</option>
                    </b-select>
                    <div class="mt-auto mb-8">
                        <b-button v-show="disabled" expanded class="has-text-weight-lighter has-shadow" type="is-primary" @click="disabled = false">{{
                            $t('views.profile.personaldata.editbutton')
                        }}</b-button>
                        <b-button :loading="isLoading" v-show="!disabled" :disabled="invalid" expanded native-type="submit" class="has-text-weight-lighter has-shadow" type="is-primary">{{
                            $t('views.profile.personaldata.finishbutton')
                        }}</b-button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </section>
</template>

<script>
    import { updateUser } from '@/api/auth';

    import { useAuthStore } from '@/store/auth';
    import useDesktop from '@/mixins/useDesktop';

    export default {
        name: 'PersonalData',
        data() {
            return {
                disabled: true,
                isLoading: false,
                authStore: useAuthStore(),
                form: {
                    email: '',
                    name: '',
                    birthdate: new Date(),
                    gender: null,
                },
                useDesktop: useDesktop(),
            };
        },
        created() {
            this.form = {
                email: this.authStore.getUser.email,
                name: this.authStore.getUser.name,
                birthdate: new Date(this.authStore.getUser.birthdate),
                gender: this.authStore.getUser.gender,
            };
        },
        computed: {
            isDesktop() {
                return this.useDesktop.isDesktop;
            },
        },
        methods: {
            onSubmit() {
                this.isLoading = true;
                this.disabled = false;
                updateUser(this.form)
                    .then((response) => {
                        this.authStore.setUser(response.data);
                        //this.$router.push({ name: 'Profile' });

                        this.$buefy.toast.open({
                            message: this.$t('views.profile.personaldata.updateSuccess'),
                            position: 'is-top',
                            type: 'is-success',
                        });
                    })
                    .catch((error) => {
                        if (error.response.data.error.message == 'Email is already taken')
                            this.$buefy.toast.open({
                                message: this.$t(`views.profile.personaldata.api.emailTaken`),
                                position: 'is-top',
                                type: 'is-danger',
                            });
                        else
                            this.$buefy.toast.open({
                                message: this.$t(`shared.commonError`),
                                position: 'is-top',
                                type: 'is-danger',
                            });
                        throw error;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.disabled = true;
                    });
            },
        },
    };
</script>
<style scoped lang="scss">
    section {
        padding-top: 1.75rem;
    }
    form > * {
        width: 100%;
    }
</style>
