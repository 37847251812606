<template>
    <article>
        <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center gap-2">
            <figure class="fit-content">
                <b-icon type="is-yellow has-background-yellow-light has-icon-radius py-5 px-5" pack="fas" icon="bus"></b-icon>
            </figure>
            <div
                @click="$router.push({ name: 'BusStop', params: { id: stop.id } })"
                class="is-flex-grow-1 has-text-left is-flex is-flex-direction-column is-justify-content-space-between is-align-self-flex-start">
                <h5 class="title is-size-6 is-family-primary pb-1 mt-1">{{ stop.name }}</h5>
                <p class="subtitle is-7 is-family-secondary has-text-grey-light">{{ stop.address }}</p>
            </div>
            <div class="fit-content">
                <h5 class="title is-size-5 has-text-weight-bold has-text-right">{{ duration }}</h5>
                <p class="subtitle is-size-7 has-text-right has-text-grey-light">min</p>
            </div>
            <div v-if="authStore.isGuest == false" class="fit-content">
                <b-button
                    :loading="isLoading"
                    @click="setFavorite"
                    :type="stop.favoriteId ? 'has-text-pink' : 'has-text-grey-light'"
                    class="is-borderless is-white is-small"
                    :class="{ 'fa-spin-pulse': isLoading }"
                    icon-pack="fas"
                    :icon-right="!isLoading ? 'heart' : 'spinner'" />
            </div>
        </div>
        <div class="mt-4 tooltip-icons">
            <a class="is-flex is-flex-direction-row is-align-items-center" aria-label="reply">
                <div class="has-background-blue-lighter has-icon-euro-radius mr-1 icon-box">
                    <b-icon type="is-blue" pack="fas" size="icon-box--icon" icon="route"></b-icon>
                </div>
                <p class="is-size-9 has-text-grey-light is-family-secondary">{{ distance }}</p>
            </a>
        </div>
    </article>
</template>

<script>
    import useFavorites from '@/mixins/useFavorites';
    import { useAuthStore } from '@/store/auth';
    import _ from 'lodash';

    export default {
        name: 'StopInformation',
        data() {
            return {
                isLoading: false,
                useFavorites: useFavorites(),
                authStore: useAuthStore(),
            };
        },
        props: {
            stop: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        methods: {
            async setFavorite() {
                this.isLoading = true;
                if (!this.stop.favoriteId) {
                    let favorite = await this.useFavorites.addFavorite({
                        stop: this.stop.id,
                        type: 'stop',
                    });
                    this.stop.favoriteId = favorite.id;
                } else {
                    await this.useFavorites.removeFavorite(this.stop.favoriteId);
                    this.stop.favoriteId = null;
                }
                this.isLoading = false;
            },
        },
        computed: {
            distance() {
                return _.get(this.stop.distance, 'distance.text', null);
            },
            duration() {
                return _.get(this.stop.distance, 'duration.text', '').split(' ')[0];
            },
        },
    };
</script>
<style scoped lang="scss">
    .has-icon-euro-radius {
        border-radius: 0.5rem;
    }

    .tooltip-icons {
        display: grid;
        // grid of columns 2x2
        grid-template-columns: repeat(2, 1fr);
        // gap between rows and columns
        gap: 0.5rem;

        & > a {
            // place it on the second column
            grid-column: 2;
        }
    }
</style>
