import { defineStore } from 'pinia';
import _ from 'lodash';

const defaultQuery = {};

export const useStopStore = defineStore({
    id: 'stop',
    state: () => ({
        stops: [],
    }),
    getters: {
        getStops(state) {
            return state.stops;
        },
    },
    actions: {
        setStops(stops) {
            this.stops = stops;
        },
    },
    persist: false,
});
