import { getPark, getParks } from '@/api/parks';
import useGeolocation from './useGeolocation';
import _ from 'lodash';

export const populates = ['pictures'];

export default function useParks() {
    const getParkById = async (id) => {
        const { coords } = await useGeolocation().getPosition();
        const { data } = await getPark(id, {
            populate: populates,
            calculateDistance: true,
            calcRealDistance: true,
            latitude: coords.latitude,
            longitude: coords.longitude,
        }).then(({ data }) => data);
        return data;
    };

    const getParksByQuery = async (query = {}) => {
        const { coords } = await useGeolocation().getPosition();

        if (!_.has(query, 'calculateDistance')) query.calculateDistance = true;
        if (!_.has(query, 'calcRealDistance')) query.calcRealDistance = true;
        if (!query.latitude) query.latitude = coords.latitude;
        if (!query.longitude) query.longitude = coords.longitude;
        if (!query.populate) query.populate = populates;

        const { data, meta } = await getParks(query).then(({ data }) => data);
        return { data, meta };
    };

    return {
        getParkById,
        getParksByQuery,
    };
}
