<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <figure class="logo-name is-hidden-touch">
                <img src="@/assets/img/logo/ioCity_Logo_name.png" />
            </figure>
            <div class="container">
                <div class="is-hidden-desktop">
                    <figure class="image logo mx-auto">
                        <img src="@/assets/img/logo/ioCity_logo.png" />
                    </figure>
                    <h1 class="title is-4 has-text-primary has-text-weight-medium">
                        {{ $t('views.initial.home.welcome') }}
                    </h1>
                    <div class="columns is-mobile is-centered">
                        <div class="column is-9-mobile is-4-tablet is-3-desktop">
                            <b-button :loading="isLoading" @click="onSubmit" class="has-text-weight-medium submit-button" size="is-size-5" expanded type="is-primary">
                                {{ $t('views.initial.home.login') }} Demo
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="columns is-hidden-touch">
                    <div class="column is-8">
                        <figure>
                            <img src="@/assets/img/logo/loginIOcity.png" />
                        </figure>
                    </div>
                    <div class="column is-4 is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-space-evenly">
                        <div class="has-text-left">
                            <h1 class="title is-4 has-text-secondary has-text-weight-semibold">{{ $t('views.initial.home.welcome') }} 👋🏻</h1>
                            <b-button :loading="isLoading" @click="onSubmit" class="has-text-weight-medium submit-button px-10 fill-width my-4" size="is-size-5" type="is-primary">
                                {{ $t('views.initial.home.login') }} Demo
                            </b-button>
                            <b-button :loading="isLoading" @click="openBackoffice" class="has-text-weight-medium submit-button px-10 fill-width my-4" size="is-size-5" type="is-primary">
                                {{ $t('views.initial.home.login') }} Backoffice
                            </b-button>
                            <b-button :loading="isLoading" @click="openDashboard" class="has-text-weight-medium submit-button px-10 fill-width my-4" size="is-size-5" type="is-primary">
                                {{ $t('views.initial.home.login') }} Dashboard
                            </b-button>
                        </div>

                        <div class="is-flex is-align-items-center">
                            <figure>
                                <img src="@/assets/img/logo/norte2020.png" />
                            </figure>
                            <figure>
                                <img src="@/assets/img/logo/pt2020.png" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { login } from '@/api/auth';
    import { useAuthStore } from '@/store/auth';

    export default {
        name: 'HomeDemo',
        data() {
            return {
                isLoading: false,
                form: {
                    identifier: 'demoemail@iotech.pt',
                    password: 'demoPassword',
                },
                authStore: useAuthStore(),
            };
        },
        methods: {
            onSubmit() {
                this.isLoading = true;
                login(this.form)
                    .then(({ data }) => {
                        this.authStore.setToken(data.jwt);
                        this.authStore.setUser(data.user);
                        this.$router.push({ name: 'BottomMenu' });
                    })
                    .catch((error) => {
                        console.error(error);
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            message: 'An error has ocurred',
                            position: 'is-top',
                            type: 'is-danger',
                        });
                    });
            },
            openBackoffice() {
                window.open('https://backoffice.iocity.iotech.pt', '_blank');
            },
            openDashboard() {
                window.open('https://dashboard.iocity.iotech.pt', '_blank');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    .logo {
        max-width: 18rem;
        margin-bottom: 3rem;
    }
    .logo-name {
        max-width: 20%;
        position: absolute;
        top: 3%;
        left: 0;
    }
</style>
