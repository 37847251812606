import { render, staticRenderFns } from "./RouteDirection.vue?vue&type=template&id=62644f60&scoped=true&"
import script from "./RouteDirection.vue?vue&type=script&lang=js&"
export * from "./RouteDirection.vue?vue&type=script&lang=js&"
import style0 from "./RouteDirection.vue?vue&type=style&index=0&id=62644f60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62644f60",
  null
  
)

export default component.exports