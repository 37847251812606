import axios from '@/plugins/axios';
import qs from 'qs';

export function login(data) {
    let query = qs.stringify({
        populate: ['picture'],
    });
    return axios.post('/auth/local/?' + query, data);
}

export function googleLogin(data) {
    return axios.post('/auth/google', data);
}

export function facebookLogin(data) {
    return axios.post('/auth/facebook', data);
}

export function appleLogin(data) {
    return axios.post('/auth/apple', data);
}

export function tokenAuth() {
    let query = qs.stringify({
        populate: ['picture'],
    });
    return axios.get(`/users/me?${query}`);
}

export function updateUser(data) {
    let query = qs.stringify({
        populate: ['picture'],
    });
    return axios.put('/auth/me?' + query, data);
}

export function registerUser(data) {
    let query = qs.stringify({
        populate: ['picture'],
    });
    return axios.post('/auth/local/register?' + query, data);
}

export function forgotPassword(data) {
    const query = qs.stringify();
    return axios.post(`/auth/forgot-password?${query}`, data);
}

export function resetPassword(data) {
    const query = qs.stringify();
    return axios.post(`/auth/reset-password?${query}`, data);
}
