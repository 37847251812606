import { defineStore } from 'pinia';
import _ from 'lodash';

const defaultQuery = {
    sort: ['distance:asc', 'price:asc'],
    filters: {
        price: {
            $lte: 100,
        },
        distance: {
            $lte: 100,
        },
        $or: [
            {
                type: { $in: ['indoor', 'outdoor'] },
            },
            {
                type: { $null: true },
            },
        ],
    },
};

export const useParkStore = defineStore({
    id: 'park',
    state: () => ({
        parks: [],
        query: _.cloneDeep(defaultQuery),
        savedSpot: {
            text: '',
            location: {
                lat: 0,
                lng: 0,
            },
        },
        parksLotation: [],
        searchText: '',
    }),
    getters: {
        getParks(state) {
            return state.parks;
        },
        getQuery(state) {
            return state.query;
        },
        getSavedSpot(state) {
            return state.savedSpot;
        },
        getSearchText(state) {
            return state.searchText;
        },
        getParksLotation(state) {
            return state.parksLotation;
        }
    },
    actions: {
        setParks(parks) {
            this.parks = parks;
        },
        setQuery(query) {
            this.query = query;
        },
        resetQuery() {
            this.query = _.cloneDeep(defaultQuery);
        },
        setSavedSpot(spot) {
            this.savedSpot = spot;
        },
        setSearchText(text) {
            this.searchText = text;
        },
        setParksLotationDate(date, parkId, lotation) {
            this.parksLotation.push({
                parkId,
                date,
                lotation,
            });
        },
        setParksLotation(lotation) {
            this.parksLotation = lotation;
        },
        resetSavedSpot() {
            this.savedSpot = {
                text: '',
                location: {
                    lat: 0,
                    lng: 0,
                },
            };
        },
    },
    persist: {
        paths: ['query', 'savedSpot', 'searchText'],
    },
});
