<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="is-flex is-flex-direction-row is-justify-content-space-between">
                <h1 class="title is-5 has-text-left">{{ $t('components.bussearch.title') }}</h1>
                <b-button @click="reverseInput" size="is-44x44" class="button is-blue-lighter has-shadow">
                    <b-icon type="is-primary" pack="fas" icon="arrow-right-arrow-left" style="transform: rotate(90deg)"></b-icon>
                </b-button>
            </div>
            <div class="is-flex is-flex-direction-column gap-1">
                <div class="is-flex is-flex-direction-row gap-3">
                    <img src="@/assets/icons/Elipses.svg" />
                    <b-autocomplete
                        ref="fromStop"
                        :loading="isLoading"
                        class="fromStop is-flex-grow-1"
                        @input="onInput($event, 'from')"
                        type="name"
                        field="name"
                        expanded
                        :placeholder="$t('components.bussearch.yourlocation')"
                        open-on-focus
                        clearable
                        :data="allFromStops"
                        @select="onSelected($event, 'from')">
                    </b-autocomplete>
                </div>
                <div class="is-flex is-flex-direction-row ml-2">
                    <img src="@/assets/icons/3circles.svg" />
                </div>
                <div class="is-flex is-flex-direction-row gap-3">
                    <img src="@/assets/icons/Elipses.svg" />
                    <b-autocomplete
                        class="toStop is-flex-grow-1"
                        ref="toStop"
                        :loading="isLoading"
                        @input="onInput($event, 'to')"
                        type="name"
                        field="name"
                        expanded
                        :placeholder="$t('components.bussearch.destiny')"
                        open-on-focus
                        clearable
                        :data="allToStops"
                        @select="onSelected($event, 'to')">
                    </b-autocomplete>
                </div>
            </div>
            <div class="is-flex is-flex-direction-column gap-4 is-align-items-flex-start">
                <b-select v-model="dateOption" @input="setDateOption" :placeholder="$t('components.routes.leaveNow')">
                    <option value="leaveNow">{{ $t('components.routes.leaveNow') }}</option>
                    <option value="departAt">{{ $t('components.routes.departAt') }}</option>
                    <option value="arrivedBy">{{ $t('components.routes.arriveAt') }}</option>
                </b-select>
                <b-datetimepicker
                    v-if="showTimePicker"
                    :min-datetime="new Date()"
                    :mobile-native="false"
                    v-model="selectedDate"
                    placeholder="Click to select..."
                    :icon-right="selectedDate ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="clearDateTime"
                    @input="setDateTime"
                    horizontal-time-picker>
                </b-datetimepicker>
            </div>
            <b-button :disabled="isDisabled" @click="setRoutes" expanded class="submit-button has-text-weight-lighter has-shadow" type="is-primary">
                <p class="is-size-6">{{ $t('components.routes.routesbutton') }}</p>
            </b-button>
        </div>
    </div>
</template>

<script>
    import { getStops, searchStops } from '@/api/stops';
    import _ from 'lodash';

    export default {
        name: 'TransportResearch',

        data() {
            return {
                selectedDate: new Date(),
                isLoading: false,

                searchFrom: '',
                selectedFrom: null,
                allFromStops: [],
                currentPageFrom: 0,
                pageCountFrom: 1,
                timer: null,

                dateOption: 'leaveNow',
                searchTo: '',
                selectedTo: null,
                allToStops: [],
                currentPageTo: 0,
                pageCountTo: 1,

                hasMounted: false,
                hasData: false,
                firstTime: true,

                execSelect: false,
                execSwitch: false,
            };
        },
        async created() {
            await this.onNextPage();
            this.selectedFrom = this.$route.query.from;
            this.selectedTo = this.$route.query.to;
            this.dateOption = _.get(this.$route, 'query.timeType', 'leaveNow');
            this.selectedDate = new Date(_.get(this.$route, 'query.time', new Date()));
            this.hasData = true;
            this.setInitial();
        },
        mounted() {
            const fromStop = document.querySelector('.fromStop .dropdown-content');
            fromStop.addEventListener('scroll', () => {
                if (fromStop.clientHeight != fromStop.scrollHeight && fromStop.scrollTop + fromStop.clientHeight + 50 >= fromStop.scrollHeight) {
                    this.onNextPage('from');
                }
            });
            const toStop = document.querySelector('.toStop .dropdown-content');
            toStop.addEventListener('scroll', () => {
                if (toStop.clientHeight != toStop.scrollHeight && toStop.scrollTop + toStop.clientHeight + 50 >= toStop.scrollHeight) {
                    this.onNextPage('to');
                }
            });
            this.hasMounted = true;
            this.setInitial();
        },
        computed: {
            showTimePicker() {
                return this.dateOption != 'leaveNow';
            },
            isDisabled() {
                return !this.selectedFrom && !this.selectedTo;
            },
        },
        methods: {
            async setInitial() {
                if (this.hasMounted && this.hasData && this.firstTime && (this.selectedFrom || this.selectedTo)) {
                    let toFind = [];
                    if (this.selectedFrom) {
                        toFind.push(this.selectedFrom);
                    }

                    if (this.selectedTo) {
                        toFind.push(this.selectedTo);
                    }

                    let { data } = await getStops({
                        filters: {
                            id: {
                                $in: toFind,
                            },
                        },
                    });

                    if (this.selectedFrom) this.$refs.fromStop.setSelected(data.data.find((stop) => stop.id == this.selectedFrom));
                    if (this.selectedTo) this.$refs.toStop.setSelected(data.data.find((stop) => stop.id == this.selectedTo));
                }
            },
            async onNextPage(inputName) {
                let currentPage = 0;
                let pageCount = 1;
                let name = '';

                this.isLoading = true;

                if (inputName == 'from') {
                    currentPage = ++this.currentPageFrom;
                    pageCount = this.pageCountFrom;
                    name = this.searchFrom;
                } else {
                    currentPage = ++this.currentPageTo;
                    pageCount = this.pageCountTo;
                    name = this.searchTo;
                }

                if (!inputName) {
                    currentPage = ++this.currentPageFrom;
                    pageCount = this.pageCountFrom;
                }

                if (currentPage > pageCount) return;

                let query = {
                    pagination: {
                        pageSize: 100,
                        page: currentPage,
                    },
                    ...(name && {
                        filters: {
                            name: {
                                $containsi: name,
                            },
                        },
                    }),
                    sort: ['name'],
                };

                // if (inputName == 'from' && this.selectedTo) _.set(query, 'filters.stop_times.trip.stop_times.stop.id', this.selectedTo);
                // if (inputName == 'to' && this.selectedFrom) _.set(query, 'filters.stop_times.trip.stop_times.stop.id', this.selectedFrom);

                if (inputName == 'from' && this.selectedTo) {
                    _.set(query, 'filters.stop', this.selectedTo);
                    _.set(query, 'filters.direction', 'from');
                }
                if (inputName == 'to' && this.selectedFrom) {
                    _.set(query, 'filters.stop', this.selectedFrom);
                    _.set(query, 'filters.direction', 'to');
                }

                await searchStops(query)
                    // await getStops(query)
                    .then((response) => {
                        if (inputName == 'from' || !inputName || (inputName == 'to' && !this.selectedFrom)) {
                            this.allFromStops = currentPage == 1 ? response.data.data : this.allFromStops.concat(response.data.data);
                            this.pageCountFrom = response.data.meta.pagination.pageCount;
                        }
                        if (inputName == 'to' || !inputName || (inputName == 'from' && !this.selectedTo)) {
                            this.allToStops = currentPage == 1 ? response.data.data : this.allToStops.concat(response.data.data);
                            this.pageCountTo = response.data.meta.pagination.pageCount;
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.$nextTick(() => {
                            this.execSelect = false;
                        });
                    });
            },
            onInput(input, inputName) {
                if (this.execSwitch || this.execSelect) return;

                if (inputName == 'from') {
                    this.searchFrom = input;
                    this.currentPageFrom = 0;
                    this.pageCountFrom = 1;
                } else {
                    this.searchTo = input;
                    this.currentPageTo = 0;
                    this.pageCountTo = 1;
                }
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.onNextPage(inputName);
                }, 300);
            },
            onSelected(option, input) {
                if (this.execSwitch) return;
                this.execSelect = true;

                if (option) {
                    if (input == 'from') {
                        this.currentPageTo = 0;
                        this.pageCountTo = 1;
                        if (_.has(option, 'id')) {
                            this.selectedFrom = option.id;
                            this.$router.replace({
                                query: {
                                    from: option.id,
                                    ...this.$route.query,
                                },
                            });
                        }
                    } else {
                        this.currentPageFrom = 0;
                        this.pageCountFrom = 1;
                        if (_.has(option, 'id')) {
                            this.selectedTo = option.id;
                            this.$router.replace({
                                query: {
                                    to: option.id,
                                    ...this.$route.query,
                                },
                            });
                        }
                    }
                    this.onNextPage(input == 'from' ? 'to' : 'from');
                } else {
                    if (input == 'from') {
                        this.currentPageFrom = 0;
                        this.pageCountFrom = 1;
                        this.selectedFrom = null;
                        // remove from query
                        let query = _.cloneDeep(this.$route.query);
                        delete query.from;
                        this.$router.replace({
                            query,
                        });
                    } else {
                        this.currentPageTo = 0;
                        this.pageCountTo = 1;
                        this.selectedTo = null;
                        // remove from query
                        let query = _.cloneDeep(this.$route.query);
                        delete query.to;
                        this.$router.replace({
                            query,
                        });
                    }
                    this.onNextPage(input);
                }
            },
            setRoutes() {
                this.$router.push({
                    name: 'TransportResults',
                    query: {
                        from: this.selectedFrom,
                        to: this.selectedTo,
                        time: this.selectedDate.toISOString(),
                        timeType: this.dateOption,
                    },
                });
            },
            reverseInput() {
                this.execSwitch = true;
                let searchFrom = this.searchFrom;
                let searchTo = this.searchTo;
                this.searchFrom = searchTo;
                this.searchTo = searchFrom;
                let selectedTo = this.selectedTo;
                let selectedFrom = this.selectedFrom;
                this.selectedFrom = selectedTo;
                this.selectedTo = selectedFrom;

                this.$refs.fromStop.setSelected(this.allFromStops.find((stop) => stop.id == this.selectedFrom));
                this.$refs.toStop.setSelected(this.allToStops.find((stop) => stop.id == this.selectedTo));

                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        from: this.selectedFrom,
                        to: this.selectedTo,
                    },
                });

                this.$nextTick(() => {
                    this.execSwitch = false;
                });
            },
            clearDateTime() {
                this.selectedDate = null;
            },
            setDateOption(option) {
                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        timeType: option,
                    },
                });
            },
            setDateTime(date) {
                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        time: date.toISOString(),
                    },
                });
            },
        },
    };
</script>
