<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-4">
            <h1 class="title is-5 mt-2 has-text-center">{{ $t('components.realTime.title') }}</h1>
            <div class="scrollable-content gap-0">
                <template v-for="(stop, i) in stops">
                    <img v-if="i != 0" src="@/assets/icons/3circles.svg" class="image is-32x32 ml-2 my-2" alt="" />
                    <div class="is-flex is-align-items-center">
                        <b-icon v-if="i == 0 || i == stops.length - 1" type="is-primary has-background-primary-light" class="px-5 py-5 has-icon-radius" pack="fas" icon="location-dot"></b-icon>
                        <img v-if="i != 0 && i != stops.length - 1" class="ml-4" :src="require('@/assets/icons/purpleE.svg')" alt="" />
                        <p v-if="!stop.isNext" class="is-size-6 ml-4 has-text-weight-semibold">{{ createText(stop) }}</p>
                        <b-tag v-else rounded type="is-primary-light has-text-black" class="ml-2 has-icon-radius is-size-6 has-text-weight-semibold">{{ createText(stop) }}</b-tag>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '@/plugins/axios';
    import { useTripStore } from '@/store/trip';
    import qs from 'qs';

    export default {
        data() {
            return {
                subscription: null,
                busLocation: null,
                intervalId: null,
            };
        },
        async created() {
            await this.fetchSubscription();
            await this.fetchBusLocation();
            this.intervalId = setInterval(async () => {
                await this.fetchBusLocation();
            }, 15000);
        },
        beforeDestroy() {
            clearInterval(this.intervalId);
        },
        beforeRouteLeave(to, from, next) {
            clearInterval(this.intervalId);
            next();
        },
        computed: {
            stops() {
                return this.subscription?.trip?.stop_times?.map((stopTime) => ({
                    name: stopTime.stop.name,
                    id: stopTime.stop.id,
                    isCurrent: stopTime.stop.id == this.busLocation?.currentStop?.id,
                    isNext: stopTime.stop.id == this.busLocation?.nextStop?.id,
                    isOrigin: stopTime.stop.id == this.subscription?.fromStopTime?.stop?.id,
                    isDestination: stopTime.stop.id == this.subscription?.toStopTime?.stop?.id,
                }));
            },
        },
        methods: {
            createText(stop) {
                let str = '';
                if (stop.isNext) str += `${this.$t('components.realTime.nextStop')}: `;

                str += stop.name;

                if (stop.isOrigin) str += ` (${this.$t('components.realTime.origin')})`;
                else if (stop.isDestination) str += ` (${this.$t('components.realTime.destination')})`;

                return str;
            },
            async fetchSubscription() {
                let tripId = this.tripStore.getSubscriptionId;
                const query = {
                    populate: {
                        trip: {
                            populate: {
                                stop_times: {
                                    sort: ['sequence:asc'],
                                    populate: {
                                        stop: true,
                                    },
                                },
                            },
                        },
                        fromStopTime: {
                            populate: {
                                stop: true,
                            },
                        },
                        toStopTime: {
                            populate: {
                                stop: true,
                            },
                        },
                    },
                };
                const { data } = await axios.get(`/subscription-transports/${tripId}?${qs.stringify(query)}`);

                this.subscription = data.data;
            },
            async fetchBusLocation() {
                const query = {
                    filters: {
                        trip: this.subscription.trip.id,
                    },
                    populate: ['currentStop', 'nextStop'],
                };
                const { data } = await axios.get(`/sensor-bus-locations/?${qs.stringify(query)}`);
                this.busLocation = data.data ? data.data[0] : null;
            },
        },
        setup() {
            const tripStore = useTripStore();
            return {
                tripStore,
            };
        },
    };
</script>
