<template>
    <section class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-8">
            <div class="top-bar">
                <b-button @click="$router.push({ name: isDesktop ? 'Main-Settings' : 'Settings' })" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">
                    {{ $t('views.profile.contacts.title') }}
                </h1>
                <div></div>
            </div>

            <ValidationObserver slim v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)" class="is-flex is-flex-direction-column is-align-items-center gap-6 fill-height">
                    <ValidationProvider tag="div" :name="$t('views.initial.register.name')" rules="required" v-slot="{ errors }">
                        <b-input v-model="form.name" type="text" name="name" :placeholder="$t('views.profile.contacts.name')"></b-input>
                        <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                    <ValidationProvider tag="div" :name="$t('views.initial.register.email')" rules="required|email" v-slot="{ errors }">
                        <b-input class="has-shadow" v-model="form.email" type="email" name="email" :placeholder="$t('views.profile.contacts.email')"></b-input>
                        <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                    <b-input v-model="form.subject" class="has-shadow" type="text" name="subject" :placeholder="$t('views.profile.contacts.subject')"></b-input>
                    <b-input v-model="form.message" maxlength="200" type="textarea" name="message" :placeholder="$t('views.profile.contacts.message')"></b-input>
                    <b-button :loading="isLoading" expanded native-type="submit" class="submit-button has-text-weight-lighter has-shadow" type="is-primary">{{
                        $t('views.profile.contacts.button')
                    }}</b-button>
                </form>
            </ValidationObserver>
        </div>
    </section>
</template>

<script>
    import { sendContact } from '@/api/contacts';
    import { useAuthStore } from '@/store/auth';
    import useDesktop from '@/mixins/useDesktop';

    export default {
        name: 'Contacts',

        data() {
            return {
                isLoading: false,
                authStore: useAuthStore(),
                form: {
                    email: '',
                    name: '',
                    subject: '',
                    message: '',
                },
                useDesktop: useDesktop(),
            };
        },
        created() {
            this.form = {
                email: this.authStore.getUser.email,
                name: this.authStore.getUser.name,
                subject: '',
                message: '',
            };
        },
        computed: {
            isDesktop() {
                return this.useDesktop.isDesktop;
            },
        },
        methods: {
            onSubmit() {
                this.isLoading = true;
                sendContact(this.form)
                    .then((response) => {
                        this.$buefy.toast.open({
                            message: this.$t('views.profile.settings.contactSuccess'),
                            position: 'is-top',
                            type: 'is-success',
                        });
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            message: this.$t('shared.commonError'),
                            position: 'is-top',
                            type: 'is-danger',
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
    };
</script>
<style scoped lang="scss">
    form > * {
        width: 100%;
    }
</style>
