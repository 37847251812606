<template>
    <section v-if="showAllowLocation" class="custom-notification">
        <div class="navbar has-background-white container is-expanded is-relative pb-5">
            <div class="columns is-mobile is-multiline is-centered is-vcentered mb-4 mt-6">
                <div class="column is-10 mt-6">
                    <b-icon type="is-primary is-large has-background-primary-light has-border-radius py-6 px-6" pack="fas" icon="location-dot"></b-icon>
                </div>
                <div class="column is-10">
                    <h1 class="title is-5">{{ $t('components.notifications.allowLocation') }}</h1>
                </div>
                <div class="column is-10">
                    <p class="subtitle is-6 has-text-weight-light px-5">{{ $t('components.notifications.locationParagraph') }}</p>
                </div>
                <div class="column is-9 mt-5">
                    <b-button @click="allowLocation" expanded class="has-text-weight-lighter has-shadow mb-4" type="is-primary">
                        {{ $t('components.notifications.allowbutton') }}
                    </b-button>
                    <a @click="notNow" class="has-text-grey is-size-6">
                        {{ $t('components.notifications.notNow') }}
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import useGeolocation from '@/mixins/useGeolocation';
    import { useAppStore } from '@/store/app';

    export default {
        name: 'AllowLocation',
        async created() {
            await useGeolocation().askForLocationPermission();
        },
        computed: {
            showAllowLocation() {
                return !useAppStore().getHasLocationPermission && !useAppStore().getUserDeclinedLocationPermission;
            },
        },
        methods: {
            async allowLocation() {
                await useGeolocation().askForLocationPermission();
            },
            notNow() {
                useAppStore().getUserDeclinedLocationPermission(true);
                useAppStore().getHasLocationPermission(false);
            },
        },
    };
</script>

<style scoped lang="scss">
    @media screen and (min-width: 60em) {
        .custom-notification {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            & > div {
                border-radius: 3rem;
            }
        }
    }
</style>
