<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-8">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.parking.spottitle') }}</h1>
                <div></div>
            </div>

            <div v-if="park" class="is-flex is-flex-direction-column is-justify-content-center gap-6">
                <figure>
                    <img :src="getParkPic" class="image is-128x128 has-border-radius mx-auto" />
                </figure>
                <div>
                    <p class="title is-size-5 has-text-weight-medium">{{ destination }}</p>
                    <p class="subtitle is-size-6 has-text-grey-light has-text-weight-light">{{ destinationAddress }}</p>
                </div>

                <b-button @click="useCompleted" class="has-text-weight-lighter has-shadow" type="is-primary">{{ $t('components.parking.conclusion') }}</b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { useParkStore } from '@/store/park';
    import { getPark } from '@/api/parks';
    import useParks from '@/mixins/useParks';
    import { useMapStore } from '@/store/map';

    export default {
        data() {
            return {
                parkStore: useParkStore(),
                mapStore: useMapStore(),
                useParks: useParks(),
                park: null,
            };
        },
        created() {
            this.getPark();
        },
        computed: {
            getParkPic() {
                if (this.park.pictures) {
                    if (this.park?.pictures[0]?.formats?.small?.url) {
                        return this.park.pictures[0].formats.small.url;
                    } else {
                        return this.park.pictures[0].url;
                    }
                } else return require('@/assets/img/examples/parque.jpg');
            },
            spotSelected() {
                return this.parkStore.getSavedSpot;
            },
            destination() {
                return this.park.name;
            },
            destinationAddress() {
                return this.park.address;
            },
        },
        methods: {
            useCompleted() {
                this.mapStore.setShowParks(true);
                this.mapStore.setShowStops(true);
                this.mapStore.setShowRoutes(true);
                this.$router.push({ name: 'BottomMenu' });
            },
            async getPark() {
                const park = await this.useParks.getParkById(this.$route.params.id);
                this.park = park;
            },
        },
    };
</script>
<style scoped lang="scss">
    img {
        object-fit: cover;
    }
</style>
