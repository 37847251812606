import { getStops, getStop } from '@/api/stops';
import useGeolocation from './useGeolocation';

export const populates = [];

export default function useStops() {
    return {
        async getStopsByQuery(query = {}) {
            const { coords } = await useGeolocation().getPosition();

            if (!_.has(query, 'calculateDistance')) query.calculateDistance = true;
            if (!_.has(query, 'calcRealDistance')) query.calcRealDistance = true;
            if (!query.latitude) query.latitude = coords.latitude;
            if (!query.longitude) query.longitude = coords.longitude;
            if (!query.populate) query.populate = populates;

            const { data, meta } = await getStops(query).then(({ data }) => data);
            return { data, meta };
        },
        async getStopById(id, query = {}) {
            const { coords } = await useGeolocation().getPosition();
            if (!_.has(query, 'calculateDistance')) query.calculateDistance = true;
            if (!_.has(query, 'calcRealDistance')) query.calcRealDistance = true;
            if (!query.latitude) query.latitude = coords.latitude;
            if (!query.longitude) query.longitude = coords.longitude;
            if (!query.populate) query.populate = populates;

            const { data } = await getStop(id, query).then(({ data }) => data);
            return data;
        },
    };
}
