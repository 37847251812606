import { render, staticRenderFns } from "./ParkingSpot.vue?vue&type=template&id=255c766d&scoped=true&"
import script from "./ParkingSpot.vue?vue&type=script&lang=js&"
export * from "./ParkingSpot.vue?vue&type=script&lang=js&"
import style0 from "./ParkingSpot.vue?vue&type=style&index=0&id=255c766d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255c766d",
  null
  
)

export default component.exports