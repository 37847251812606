<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-8">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.routes.results') }}</h1>
                <div></div>
            </div>

            <div class="is-flex is-align-items-center is-justify-content-center">
                <h1 class="has-text-grey has-text-weight-bold is-small py-1 mx-2">{{ origin }}</h1>
                <b-icon type="is-grey" size="is-small" icon-pack="fas" icon="angle-right"></b-icon>
                <h1 class="has-text-grey has-text-weight-bold is-small py-1 mx-2">{{ destination }}</h1>
            </div>

            <div v-if="routes.length > 0" class="scrollable-content">
                <div v-for="(route, i) in routes" :key="`route_${i}`" class="is-flex is-flex-direction-column gap-4">
                    <div class="is-flex is-flex-direction-row gap-4">
                        <b-icon :type="getMostUsedTravelMode(route).type" class="px-5 py-5 has-icon-radius" pack="fas" :icon="getMostUsedTravelMode(route).icon"></b-icon>
                        <p class="is-size-8 has-text-grey-light">
                            <strong class="has-text-weight-semibold">{{ getShedule(route) }}</strong>
                        </p>
                        <h1 class="title is-size-5 has-text-weight-bold has-text-right ml-auto">{{ getDuration(route) }}</h1>
                    </div>
                    <div class="is-flex is-flex-direction-row is-flex-wrap-wrap gap-2 is-justify-content-space-evenly">
                        <div v-for="(step, s) in route.legs[0].steps" :key="`step_${s}`" class="is-flex is-flex-direction-row is-align-items-center is-flex-wrap-nowrap gap-2">
                            <b-icon type="is-grey is-small" icon-pack="fas" :icon="getTravelMode(step).icon"></b-icon>
                            <b-tag v-if="getSteps(step).text" :style="getSteps(step).style" class="line-tag has-text-weight-medium is-small py-4">{{ getSteps(step).text }} </b-tag>
                            <b-icon v-if="s != route.legs[0].steps.length - 1" type="is-grey is-small" icon-pack="fas" icon="angle-right"></b-icon>
                        </div>
                    </div>
                    <b-button @click="goToDetails(i)" type="is-outlined is-primary is-small" icon-pack="fas" icon-left="plus"> Detalhes </b-button>
                    <hr class="mt-2 mb-2" />
                </div>
            </div>
            <p v-else>
                {{ $t('components.routes.noResults') }}
            </p>
        </div>
    </div>
</template>
<script>
    import { useMapStore } from '@/store/map';
    import _ from 'lodash';
    export default {
        props: ['transportType'],
        data() {
            return {
                mapStore: useMapStore(),
            };
        },
        created() {
            this.mapStore.setShowFavorites(false);
            this.mapStore.setShowParks(false);
            this.mapStore.setShowStops(false);
            this.mapStore.setShowDirections(true);
        },
        computed: {
            destination() {
                return _.get(this.mapStore.getRouteDestination, 'name', this.$t('components.search.yourlocation'));
            },
            origin() {
                return _.get(this.mapStore.getRouteOrigin, 'name', this.$t('components.search.yourlocation'));
            },
            routes() {
                return _.get(this.mapStore.getRouteResult, 'routes', []);
            },
        },
        methods: {
            getDuration(route) {
                let durationResult = route.legs[0].duration.text;
                if (durationResult.split(' ').length > 3) {
                    return durationResult.split(' ')[0] + ' h ' + durationResult.split(' ')[2] + ' min';
                } else {
                    return durationResult.split(' ')[0] + ' min ';
                }
            },

            getShedule(route) {
                let departureTime = _.get(route, 'legs[0].departure_time.text', '');
                let arrivalTime = _.get(route, 'legs[0].arrival_time.text', '');
                if (!departureTime || !arrivalTime) return _.get(route, 'legs[0].distance.text', '');

                return departureTime + ' - ' + arrivalTime;
            },

            getMostUsedTravelMode(route) {
                let travelModes = [];
                let hasWalking = false;
                route.legs[0].steps.forEach((step) => {
                    if (step.travel_mode.toUpperCase() == 'WALKING') {
                        hasWalking = true;
                        return;
                    }
                    const vehicle = _.get(step, 'transit_details.line.vehicle.type', '');
                    if (!vehicle) return;
                    travelModes.push(vehicle.toUpperCase().includes('TRAIN') || vehicle.toUpperCase().includes('SUBWAY') ? 'TRAIN' : 'BUS');
                });

                if (travelModes.length == 0 && hasWalking)
                    return {
                        type: 'is-green has-background-green-light',
                        icon: 'walking',
                    };

                travelModes = _.chain(travelModes).countBy().toPairs().max(_.last).head().value();

                if (travelModes == 'BUS')
                    return {
                        type: 'is-yellow has-background-yellow-light',
                        icon: 'bus',
                    };

                if (travelModes == 'TRAIN')
                    return {
                        type: 'is-purple has-background-purple-light',
                        icon: 'train-subway',
                    };

                return {
                    type: 'is-green has-background-green-light',
                    icon: 'walking',
                };
            },

            getSteps(step) {
                if (step.travel_mode == 'TRANSIT') {
                    let hasBorder = false;
                    if (['#ffffff', '#fff'].includes(_.get(step, 'transit_details.line.color', '').toLowerCase())) hasBorder = true;

                    const shortName = _.get(step, 'transit_details.line.short_name', '');
                    const agencyName = _.get(step, 'transit_details.line.agencies[0].name', '');

                    return {
                        style: `background-color: ${step.transit_details.line.color} !important; color: ${step.transit_details.line.text_color} !important;${
                            hasBorder ? 'border: 1px solid #b5b5b5' : ''
                        }`,
                        text: `${shortName ? shortName + ' - ' : ''}` + agencyName,
                    };
                } else if (step.travel_mode == 'WALKING') {
                    return {
                        text: '',
                        style: 'background-color: transparent',
                    };
                }
            },
            getTravelMode(step) {
                if (step.travel_mode == 'TRANSIT') {
                    return {
                        icon: 'subway',
                        color: 'is-purple has-background-purple-light',
                        title: step.transit_details.line.agencies[0].name,
                    };
                } else if (step.travel_mode == 'WALKING') {
                    return {
                        icon: 'person-walking',
                        color: 'is-green has-background-green-light',
                        title: 'A pé',
                    };
                }
            },
            getStepInformation(step) {
                return {
                    distance: step.distance.text,
                    duration: step.duration.text.split(' ')[0],
                    instructions: step.instructions,
                };
            },
            goToDetails(i) {
                if (window.innerWidth < 768) {
                    this.$router.push({ name: 'TransitRouteDetails', params: { id: i } });
                } else {
                    this.$router.push({ name: 'TransitDetailsDesktop', params: { id: i } });
                }
            },
        },
    };
</script>

<style lang="scss">
    @import '@/assets/scss/_variables.scss';
</style>
