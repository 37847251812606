import { helpers, getGoogleMapsAPI } from 'gmap-vue';
import { getRoutes } from '@/api/routes';
import { useMapStore } from '@/store/map';

export default helpers.MapElementFactory({
    name: 'DirectionsRenderer',

    ctr() {
        return window.google.maps.DirectionsRenderer;
    },
    data() {
        return {
            directionsRenderer: null,
            mapStore: useMapStore(),
        };
    },
    events: [],

    mappedProps: {},

    props: {
        origin: { type: [Object, Array] },
        destination: { type: [Object, Array] },
        travelMode: { type: String },
        time: { type: Date },
    },
    computed: {
        google: getGoogleMapsAPI,
        _destination() {
            return this.mapStore.getRouteDestination;
        },
        _origin() {
            return this.mapStore.getRouteOrigin;
        },
        _travelMode() {
            return this.mapStore.getRouteTravelMode;
        },
        _time() {
            return this.mapStore.getRouteTime;
        },
        _timeType() {
            this.mapStore.getRouteTimeType;
        },
    },
    async afterCreate(directionsRenderer) {
        this.directionsRenderer = directionsRenderer;
        this.getDirections();
    },
    watch: {
        _destination(newValue, oldValue) {
            this.getDirections();
        },
        _origin(newValue, oldValue) {
            this.getDirections();
        },
    },
    methods: {
        async getDirections() {
            if (!this.directionsRenderer) return;
            let origLat = typeof this._origin.geometry.location.lat == 'function' ? this._origin.geometry.location.lat() : this._origin.geometry.location.lat;
            let origLng = typeof this._origin.geometry.location.lng == 'function' ? this._origin.geometry.location.lng() : this._origin.geometry.location.lng;
            let destLat = typeof this._destination.geometry.location.lat == 'function' ? this._destination.geometry.location.lat() : this._destination.geometry.location.lat;
            let destLng = typeof this._destination.geometry.location.lng == 'function' ? this._destination.geometry.location.lng() : this._destination.geometry.location.lng;
            const origin = origLat + ',' + origLng;
            const destination = destLat + ',' + destLng;
            await getRoutes({
                directions: true,
                origin: origin,
                destination: destination,
                mode: this._travelMode.toLowerCase(),
                ...(this._timeType == 'arrivedBy' && { arrival_time: this._time }),
                ...(this._timeType == 'departAt' && { departure_time: this._time }),
            }).then((response) => {
                this.mapStore.setRouteResult(response.data.data);
                var bounds = new google.maps.LatLngBounds(response.data.data.routes[0].bounds.southwest, response.data.data.routes[0].bounds.northeast);
                response.data.data.routes[0].bounds = bounds;
                response.data.data.routes[0].overview_path = google.maps.geometry.encoding.decodePath(response.data.data.routes[0].overview_polyline.points);
                response.data.data.routes[0].legs = response.data.data.routes[0].legs.map(function (leg) {
                    leg.start_location = new google.maps.LatLng(leg.start_location.lat, leg.start_location.lng);

                    leg.end_location = new google.maps.LatLng(leg.end_location.lat, leg.end_location.lng);

                    leg.steps = leg.steps.map(function (step) {
                        step.path = google.maps.geometry.encoding.decodePath(step.polyline.points);

                        step.start_location = new google.maps.LatLng(step.start_location.lat, step.start_location.lng);

                        step.end_location = new google.maps.LatLng(step.end_location.lat, step.end_location.lng);

                        return step;
                    });

                    return leg;
                });

                this.directionsRenderer.setDirections(response.data.data);
            });
        },
    },
});
