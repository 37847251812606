<template>
    <section class="rounded fill-height">
        <div class="has-background-grey-darker rounded is-relative fill-height">
            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-6">
                    <h5 class="is-size-5 has-text-white has-text-weight-medium mt-5">{{ destination }}</h5>
                    <p class="is-size-9 has-text-white has-text-weight-light mt-2">{{ destinationAddress }}</p>
                </div>
            </div>
            <div class="has-background-white rounded mt-5 fill-height">
                <div class="columns is-mobile is-vcentered is-flex is-justify-content-center px-6">
                    <div class="column is-3-mobile is-3-tablet">
                        <p class="is-size-5 has-text-grey-light has-text-left">{{ $t('components.path.distance') }}</p>
                        <p class="subtitle is-size-5 has-text-grey-dark has-text-weight-semibold has-text-left">{{ distance }}</p>
                    </div>
                    <div class="column is-6-mobile is-6-tablet">
                        <div class="columns is-multiline is-flex is-justify-content-center">
                            <div v-if="mapStore.getRouteTravelMode == 'DRIVING' || mapStore.getRouteTravelMode == 'WALKING'" class="column is-12-mobile is-12-tablet">
                                <b-icon
                                    size="is-medium"
                                    class="has-icon-radius has-padding-35"
                                    :type="mapStore.getRouteTravelMode == 'DRIVING' ? 'is-pink has-background-pink-light' : 'is-green has-background-green-light'"
                                    pack="fas"
                                    :icon="mapStore.getRouteTravelMode == 'DRIVING' ? 'car' : 'person-walking'"></b-icon>
                            </div>
                            <div v-else class="column is-12-mobile is-12-tablet">
                                <b-icon
                                    size="is-medium"
                                    class="has-icon-radius has-padding-35"
                                    :type="mapStore.getRouteTravelMode == 'TRANSIT' ? 'is-purple has-background-purple-light' : 'is-yellow has-background-yellow-light'"
                                    pack="fas"
                                    :icon="mapStore.getRouteTravelMode == 'TRANSIT' ? 'bus' : 'person-biking'"></b-icon>
                            </div>
                        </div>
                    </div>
                    <div class="column is-3-mobile is-3-tablet">
                        <p class="is-size-5 has-text-grey-light has-text-right">{{ $t('components.path.time') }}</p>
                        <p class="subtitle is-size-5 has-text-grey-dark has-text-weight-semibold has-text-right">{{ duration }}</p>
                    </div>
                </div>
                <b-button @click="openGoogleMaps" class="button has-text-primary has-text-weight-bold mt-2 is-borderless">{{ $t('components.path.navegation') }} </b-button>
            </div>
        </div>
    </section>
</template>

<script>
    import _ from 'lodash';
    import { useMapStore } from '@/store/map';
    import { useAppStore } from '@/store/app';

    export default {
        name: 'PathDirection',
        data() {
            return {
                url: null,
                routeOrigin: null,
                routeDestination: null,
                mapStore: useMapStore(),
                appStore: useAppStore(),
            };
        },
        created() {
            this.routeOrigin = this.mapStore.getRouteOrigin.geometry.location;
            this.routeDestination = this.mapStore.getRouteDestination.geometry.location;
            this.mapStore.setShowFavorites(false);
            this.mapStore.setShowParks(false);
            this.mapStore.setShowRoutes(false);
            this.mapStore.setShowStops(false);
            this.mapStore.setShowDirections(true);
            // this.appStore.setIsShowOpen(false);
        },
        computed: {
            destination() {
                return _.get(this.mapStore.getRouteDestination, 'name', this.$t('components.search.yourlocation'));
            },
            destinationAddress() {
                return _.get(this.mapStore.getRouteDestination, 'formatted_address', null);
            },
            distance() {
                return _.get(this.mapStore.getRouteResult, 'routes[0].legs[0].distance.text', null);
            },
            duration() {
                let duration = _.get(this.mapStore.getRouteResult, 'routes[0].legs[0].duration.value', 0);
                if (duration < 60) duration = 60;
                // the duration is in seconds, convert it to "Xh Ymin"
                let hours = Math.floor(duration / 3600);
                let minutes = Math.floor((duration - hours * 3600) / 60);
                let result = '';
                if (hours > 0) {
                    result += hours + ' h ';
                }
                if (minutes > 0) {
                    result += minutes + ' min';
                }
                return result;
            },
        },
        methods: {
            cleanRoute() {
                this.mapStore.setShowDirections(false);
                this.mapStore.setShowStops(true);
                this.mapStore.setShowParks(true);
                this.mapStore.setShowRoutes(true);
                this.appStore.setIsShowOpen(false);
                this.$router.push({ name: 'BottomMenu' });
            },
            openGoogleMaps() {
                if (_.get(this.mapStore.getRouteDestination, 'parkId', null) && this.mapStore.getRouteTravelMode == 'DRIVING') {
                    this.$router.push({ name: 'ArrivedPark', params: { id: _.get(this.mapStore.getRouteDestination, 'parkId', null) } });
                }
                let origLat = typeof this.routeOrigin.lat == 'function' ? this.routeOrigin.lat() : this.routeOrigin.lat;
                let origLng = typeof this.routeOrigin.lng == 'function' ? this.routeOrigin.lng() : this.routeOrigin.lng;
                let destLat = typeof this.routeDestination.lat == 'function' ? this.routeDestination.lat() : this.routeDestination.lat;
                let destLng = typeof this.routeDestination.lng == 'function' ? this.routeDestination.lng() : this.routeDestination.lng;
                let travelmode = this.mapStore.getRouteTravelMode;

                if (navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPod') != -1 || navigator.platform.indexOf('iPad') != -1) this.url = `maps://?q=${destLat},${destLng}'`;
                else this.url = `https://www.google.com/maps/dir/?api=1&travelmode=${travelmode.toLowerCase()}&layer=traffic&destination=${destLat},${destLng}&origin=${origLat},${origLng}`;

                window.open(this.url, '_system');
            },
        },
    };
</script>

<style scoped>
    .has-padding-35 {
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .has-background-white {
        padding-top: 3rem;
        padding-bottom: 10rem;
        margin-bottom: -10rem;
    }
</style>
