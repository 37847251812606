<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.filters.title') }}</h1>
                <div></div>
            </div>
            <div class="scrollable-content gap-6">
                <div>
                    <h1 class="subtitle is-size-8 has-text-left mb-3">{{ $t('components.filters.order') }}</h1>
                    <div class="is-flex is-flex-direction-column gap-3">
                        <b-radio v-model="radio" size="is-small-mobile is-large-tablet" class="is-primary" native-value="distance"> {{ $t('components.filters.shortcut') }} </b-radio>
                        <b-radio v-model="radio" size="is-small-mobile is-large-tablet" native-value="price"> {{ $t('components.filters.cheaper') }} </b-radio>
                    </div>
                </div>
                <div>
                    <h1 class="subtitle is-size-8 has-text-left mb-3">{{ $t('components.filters.parktype') }}</h1>
                    <div class="is-flex is-flex-direction-column gap-3">
                        <b-checkbox v-model="parkType" native-value="outdoor"> {{ $t('components.filters.outdoor') }} </b-checkbox>
                        <b-checkbox v-model="parkType" native-value="indoor"> {{ $t('components.filters.indoor') }} </b-checkbox>
                    </div>
                </div>
                <div>
                    <h1 class="subtitle is-size-8 has-text-left mb-3">{{ $t('components.filters.value') }}</h1>
                    <b-slider v-model="query.filters.price.$lte" :step="0.1" :max="5" class="is-small mt-3 mb-2" rounded></b-slider>
                    <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                        <p class="has-text-left has-text-grey-light is-size-6">0€</p>
                        <p class="has-text-right has-text-grey-light is-size-6">5€</p>
                    </div>
                </div>
                <div>
                    <h1 class="subtitle is-size-8 has-text-left mb-3">{{ $t('components.filters.distance') }}</h1>
                    <b-slider v-model="query.filters.distance.$lte" class="is-small mt-3 mb-2" rounded></b-slider>
                    <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                        <p class="has-text-left has-text-grey-light is-size-6">0km</p>
                        <p class="has-text-right has-text-grey-light is-size-6">100km</p>
                    </div>
                </div>
                <div>
                    <b-button @click="onClick" expanded class="has-text-weight-lighter has-shadow" type="is-primary">{{ $t('components.filters.filterbutton') }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useParkStore } from '@/store/park';
    import _ from 'lodash';

    export default {
        name: 'Filters',
        data() {
            return {
                radio: null,
                selectedTransport: false,
                selectedTransportType: null,
                query: {},
                parkStore: useParkStore(),
                parkType: [],
            };
        },
        created() {
            this.query = this.parkStore.getQuery;
            this.radio = _.get(this.query, 'sort[0]', 'distance:').split(':')[0];
            this.parkType = _.get(this.query, 'filters.type.$in', ['outdoor', 'indoor']);
        },
        methods: {
            onClick() {
                let parkTypes = this.parkType;
                if (parkTypes.length == 0) {
                    parkTypes = ['outdoor', 'indoor'];
                }

                if (parkTypes.length == 2) {
                    _.unset(this.query, 'filters.type');
                    _.set(this.query, 'filters.$or', [
                        {
                            type: { $in: parkTypes },
                        },
                        {
                            type: { $null: true },
                        },
                    ]);
                } else {
                    _.unset(this.query, 'filters.$or');
                    _.set(this.query, 'filters.type.$in', parkTypes);
                }
                const otherRadio = this.radio == 'distance' ? 'price' : 'distance';
                _.set(this.query, 'sort', [`${this.radio}:asc`, `${otherRadio}:asc`]);
                this.parkStore.setQuery(this.query);
                this.$router.go(-1);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';

    .has-icon-euro-radius {
        border-radius: 8px;
    }
    .icon {
        width: 15px;
    }

    .is-80x80 {
        width: 80px;
        height: 80px;
    }
    .is-45x45 {
        width: 45px;
        height: 45px;
    }
</style>
