<template>
    <section class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-8">
            <div class="top-bar">
                <b-button @click="$router.push({ name: isDesktop ? 'Main-Privacy' : 'Privacy' })" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">
                    {{ $t('views.profile.accountinactivation.title') }}
                </h1>
                <div></div>
            </div>

            <div>
                <p class="has-text-left has-text-grey is-family-secondary">{{ $t('views.profile.accountinactivation.text') }}</p>
                <br />
                <p class="has-text-left has-text-grey is-family-secondary">{{ $t('views.profile.accountinactivation.text2') }}</p>
                <br />
            </div>

            <b-button expanded class="has-text-weight-lighter has-shadow submit-button" type="is-primary">
                {{ $t('views.profile.accountinactivation.inactivationbutton') }}
            </b-button>
        </div>
    </section>
</template>
<script>
    import useDesktop from '@/mixins/useDesktop';
    export default {
        name: 'AccountInactivation',
        data() {
            return {
                useDesktop: useDesktop(),
            };
        },
        computed: {
            isDesktop() {
                return this.useDesktop.isDesktop;
            },
        },
    };
</script>
