<template>
    <article class="media">
        <div class="media-left">
            <figure>
                <b-icon type="is-yellow has-background-yellow-light has-icon-radius py-5 px-5" pack="fas" icon="bus"></b-icon>
            </figure>
        </div>
        <div class="media-content is-clipped">
            <div class="content has-text-left">
                <div v-if="this.authStore.isGuest == false" class="columns is-mobile">
                    <div @click="$router.push({ name: 'BusStop', params: { id: stop.id } })" class="column is-10">
                        <h5 class="title is-size-6 is-family-primary pb-1 is-capitalized">{{ stop.name.toLowerCase() }}</h5>
                        <p class="subtitle is-7 is-family-secondary has-text-grey-light">{{ stop.address }}</p>
                    </div>
                    <div class="column is-2">
                        <b-button
                            :loading="isLoading"
                            @click="setFavorite"
                            :type="favorite ? 'has-text-pink' : 'has-text-grey-light'"
                            class="is-borderless is-white is-small"
                            :class="{ 'fa-spin-pulse': isLoading }"
                            icon-pack="fas"
                            :icon-right="!isLoading ? 'heart' : 'spinner'" />
                        <b-button
                            @click="$router.push({ name: 'BusStop', params: { id: stop.id } })"
                            type="has-text-primary"
                            class="is-borderless has-text-primary is-small"
                            icon-pack="fas"
                            icon-right="plus" />
                    </div>
                </div>
                <div v-else class="columns is-mobile is-vcentered">
                    <div class="column is-8">
                        <h5 class="title is-size-6 is-family-primary pb-1 is-capitalized">{{ stop.name }}</h5>
                        <p class="subtitle is-7 is-family-secondary has-text-grey-light">{{ stop.address }}</p>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
    import { addFavorites, removeFavorites } from '@/api/favorites';
    import _ from 'lodash';

    export default {
        name: 'StopInformation',
        data() {
            return {
                isLoading: false,
                favoriteObj: {
                    stop: 'string or id',
                    type: 'stop',
                    user: this.authStore.getUser.id,
                },
            };
        },
        props: {
            stop: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        methods: {
            async setFavorite() {
                this.isLoading = true;
                this.favoriteObj.stop = this.stop.id;

                if (!this.favorite) {
                    await addFavorites(this.favoriteObj).then((response) => {
                        _.set(response.data.data, 'stop.distance', this.stop.distance);
                        this.$store.commit('favorites/addToFavorites', response.data.data);
                    });
                } else {
                    await removeFavorites(this.favorite.id).then((response) => {
                        this.$store.commit('favorites/removeFromFavorites', this.favorite);
                        //	this.$store.commit('stops/setFavoritesStops');
                    });
                }

                this.isLoading = false;
            },
        },
        computed: {
            favorite() {
                return this.$store.getters['favorites/getFavorites'].find((favorite) => favorite.type == 'stop' && favorite['stop'].id == this.stop.id);
            },
            distance() {
                return _.get(this.stop.distance, 'distance.text', null);
            },
            duration() {
                return _.get(this.stop.distance, 'duration.text', '').split(' ')[0];
            },
        },
    };
</script>
<style scoped>
    .has-icon-euro-radius {
        border-radius: 8px;
        width: 12px;
    }
</style>
