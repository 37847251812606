import { defineStore } from 'pinia';

export const useRecentsStore = defineStore({
    id: 'recents',
    state: () => ({
        recents: [],
    }),
    getters: {
        getRecents(state) {
            return state.recents;
        },
    },
    actions: {
        /**
         *
         * @param {Object} data
         * @param {String} data.type
         * @param {String|Number} data.id
         */
        addRecent(data) {
            // filter out the same type and id
            const filtered = this.recents.filter((recent) => {
                if (recent.type != data.type) return true;
                return recent.id != data.id;
            });

            this.recents = [data, ...filtered];
        },
        resetRecents() {
            this.recents = [];
        },
    },
    persist: true,
});
