import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/initial/Home.vue';
import HomeDemo from '@/views/initial/HomeDemo.vue';
import Login from '@/views/initial/Login.vue';
import IncorrectData from '@/components/notifications/IncorrectData.vue';
import Register from '@/views/initial/Register.vue';
import ForgotPassword from '@/views/initial/ForgotPassword.vue';
import NewPassword from '@/views/initial/NewPassword.vue';
import Profile from '@/views/profile/Profile.vue';
import PersonalData from '@/views/profile/PersonalData.vue';
import FavoriteView from '@/views/profile/FavoriteView.vue';
import Help from '@/views/profile/Help.vue';
import Settings from '@/views/profile/settings/Settings.vue';
import Language from '@/views/profile/settings/Language.vue';
import Notifications from '@/views/profile/settings/Notifications.vue';
import Privacy from '@/views/profile/settings/privacy/Privacy.vue';
import Contacts from '@/views/profile/settings/Contacts.vue';
import DeleteData from '@/views/profile/settings/privacy/DeleteData.vue';
import AccountInactivation from '@/views/profile/settings/privacy/AccountInactivation.vue';
import DataPortability from '@/views/profile/settings/privacy/DataPortability.vue';
import RemoveConsent from '@/views/profile/settings/privacy/RemoveConsent.vue';
import TrainSchedule from '@/views/routes/TrainSchedule.vue';
import BusSchedule from '@/views/routes/BusSchedule.vue';
import SingleRoute from '@/views/routes/SingleRoute.vue';
import ParkMap from '@/views/parks/ParkMap.vue';
import ParkReservation from '@/views/parks/ParkReservation.vue';
import BottomMenu from '@/views/BottomMenu.vue';
import TransitRouteDetails from '@/components/routes/TransitRouteDetails.vue';
import Research from '@/components/search/Research.vue';
import TransportResearch from '@/components/transport/TransportResearch.vue';
import ParkingResearch from '@/components/parking/ParkingResearch.vue';
import ParkDetails from '@/components/parking/ParkDetails.vue';
import RouteResearch from '@/components/routes/RouteResearch.vue';
import NearMe from '@/components/nearme/NearMe.vue';
import ResearchFavorites from '@/components/search/Favorite.vue';
import ResearchRecents from '@/components/search/RecentNav.vue';
import ResearchResults from '@/components/search/ResearchResults.vue';
import TransportResults from '@/components/transport/TransportResults.vue';
import TransportFilters from '@/components/transport/TransportFilters.vue';
import Filters from '@/components/parking/FiltersNav.vue';
import AutomaticRoutes from '@/components/routes/AutoRouteResult.vue';
import PathDirection from '@/components/directions/PathDirection.vue';
import ArrivedPark from '@/components/parking/ArrivedPark.vue';
import RouteDirection from '@/components/directions/RouteDirection.vue';
import RouteDetails from '@/components/routes/RouteDetails.vue';
import CompletedRoute from '@/components/routes/CompletedRoute.vue';
import ParkingSpot from '@/components/parking/ParkingSpot.vue';
import MapSettings from '@/components/map/MapSettings.vue';
import BusStop from '@/components/stops/BusStop.vue';
import AllowNotifications from '@/components/notifications/AllowNotification.vue';
import AllowMicrophone from '@/components/notifications/AllowMicrophone.vue';
import NotRegistered from '@/components/notifications/NotRegistered.vue';
import ErrorNotification from '@/components/notifications/ErrorNotification.vue';
import ContinueRoute from '@/components/notifications/ContinueRoute.vue';
import NoConnection from '@/components/notifications/NoConnection.vue';
import NoGPS from '@/components/notifications/NoGPS.vue';
import LogoutConfirm from '@/components/notifications/LogoutConfirm.vue';
import RegisteredEmail from '@/components/notifications/RegisteredEmail.vue';
import Connection from '@/components/notifications/Connection.vue';
import CalcutateRoute from '@/components/notifications/CalcutateRoute.vue';
import AllowLocation from '@/components/notifications/AllowLocation.vue';
import NotificationsList from '@/components/notifications/NotificationsList.vue';
import TransitRoutes from '@/components/routes/TransitRoutes.vue';
import TransitDetailsDesktop from '@/components/routes/TransitDetailsDesktop.vue';
import TrackInsideBus from '@/components/real-time/TrackInsideBus.vue';
import SearchBusStop from '@/components/stops/SearchBusStop.vue';
import BusDetails from '@/components/transport/BusDetails.vue';
import TransportSubscribe from '@/components/transport/TransportSubscribe.vue';

// import store from '../store_old/index';
import { useAppStore } from '@/store/app';
import { useMapStore } from '@/store/map';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'HomeDemo',
        component: HomeDemo,
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
    },

    {
        path: '/login',
        name: 'Login',
        component: Login,
        children: [{ path: 'incorrectdata', name: 'IncorretData', component: IncorrectData }],
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/reset-password',
        name: 'NewPassword',
        component: NewPassword,
    },
    // Remaining routes...
    // Note: Make sure to import the necessary components for the remaining routes.

    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/personaldata',
        name: 'PersonalData',
        component: PersonalData,
    },
    {
        path: '/favorite',
        name: 'Favorite',
        component: FavoriteView,
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
    },
    {
        path: '/language',
        name: 'Language',
        component: Language,
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
    },
    {
        path: '/contact',
        name: 'Contacts',
        component: Contacts,
    },
    {
        path: '/deletedata',
        name: 'DeleteData',
        component: DeleteData,
    },
    {
        path: '/accountinactivation',
        name: 'AccountInactivation',
        component: AccountInactivation,
    },
    {
        path: '/dataportability',
        name: 'DataPortability',
        component: DataPortability,
    },
    {
        path: '/removeconsent',
        name: 'RemoveConsent',
        component: RemoveConsent,
    },

    {
        path: '/train',
        name: 'TrainSchedule',
        component: TrainSchedule,
    },
    {
        path: '/bus',
        name: 'BusSchedule',
        component: BusSchedule,
    },
    {
        path: '/route',
        name: 'SingleRoute',
        component: SingleRoute,
    },
    {
        path: '/parkmap',
        name: 'ParkMap',
        component: ParkMap,
    },
    {
        path: '/parkreservation',
        name: 'ParkReservation',
        component: ParkReservation,
    },
    {
        path: '/main',
        name: 'BottomMenu',
        component: BottomMenu,
        children: [
            {
                path: 'profile',
                name: 'Main-Profile',
                component: Profile,
            },
            {
                path: 'personaldata',
                name: 'Main-PersonalData',
                component: PersonalData,
            },
            {
                path: 'help',
                name: 'Main-Help',
                component: Help,
            },
            {
                path: 'settings',
                name: 'Main-Settings',
                component: Settings,
            },
            {
                path: 'language',
                name: 'Main-Language',
                component: Language,
            },
            {
                path: 'notifications',
                name: 'Main-Notifications',
                component: Notifications,
            },
            {
                path: 'privacy',
                name: 'Main-Privacy',
                component: Privacy,
            },
            {
                path: 'contact',
                name: 'Main-Contacts',
                component: Contacts,
            },
            {
                path: 'deletedata',
                name: 'Main-DeleteData',
                component: DeleteData,
            },
            {
                path: 'accountinactivation',
                name: 'Main-AccountInactivation',
                component: AccountInactivation,
            },
            {
                path: 'dataportability',
                name: 'Main-DataPortability',
                component: DataPortability,
            },
            {
                path: 'removeconsent',
                name: 'Main-RemoveConsent',
                component: RemoveConsent,
            },
            {
                path: 'transitdetails/:id',
                name: 'TransitRouteDetails',
                component: TransitRouteDetails,
            },
            {
                path: 'research',
                name: 'Research',
                component: Research,
                meta: {
                    cardSize: 'small',
                    activeTab: 'research',
                },
            },
            {
                path: 'profile',
                name: 'ProfileDesktop',
                component: Profile,
            },
            {
                path: 'personaldata',
                name: 'PersonalDataDesktop',
                component: PersonalData,
            },
            {
                path: 'favorite',
                name: 'FavoriteDesktop',
                component: FavoriteView,
            },
            {
                path: 'help',
                name: 'HelpDesktop',
                component: Help,
            },
            {
                path: 'settings',
                name: 'SettingsDesktop',
                component: Settings,
            },
            {
                path: 'language',
                name: 'LanguageDesktop',
                component: Language,
            },
            {
                path: 'notifications',
                name: 'NotificationsDesktop',
                component: Notifications,
            },
            {
                path: 'privacy',
                name: 'PrivacyDesktop',
                component: Privacy,
            },
            {
                path: 'contact',
                name: 'ContactsDesktop',
                component: Contacts,
            },
            {
                path: 'deletedata',
                name: 'DeleteDataDesktop',
                component: DeleteData,
            },
            {
                path: 'accountinactivation',
                name: 'AccountInactivationDesktop',
                component: AccountInactivation,
            },
            {
                path: 'dataportability',
                name: 'DataPortabilityDesktop',
                component: DataPortability,
            },
            {
                path: 'removeconsent',
                name: 'RemoveConsentDesktop',
                component: RemoveConsent,
            },
            {
                path: 'transport',
                name: 'TransportResearch',
                component: TransportResearch,
                meta: {
                    cardSize: 'medium',
                    activeTab: 'transport',
                },
            },
            {
                path: 'park',
                name: 'ParkingResearch',
                component: ParkingResearch,
                meta: {
                    activeTab: 'park',
                },
            },
            {
                path: 'park-details/:id',
                name: 'ParkDetails',
                component: ParkDetails,
                meta: {
                    cardSize: 'medium',
                    activeTab: 'park',
                },
            },
            {
                path: 'route',
                name: 'RouteResearch',
                component: RouteResearch,
                meta: {
                    cardSize: 'large',
                    activeTab: 'route',
                },
            },
            {
                path: 'nearme',
                name: 'NearMe',
                component: NearMe,
                meta: {
                    activeTab: 'nearme',
                },
            },
            {
                path: 'favorites',
                name: 'ResearchFavorites',
                component: ResearchFavorites,
                meta: {
                    activeTab: 'research',
                },
            },
            {
                path: 'recent',
                name: 'ResearchRecents',
                component: ResearchRecents,
                meta: {
                    activeTab: 'research',
                },
            },
            { path: 'results', name: 'ResearchResults', component: ResearchResults },
            {
                path: 'transport-results',
                name: 'TransportResults',
                component: TransportResults,
                meta: {
                    activeTab: 'transport',
                },
            },
            {
                path: 'transport-filters',
                name: 'TransportFilters',
                component: TransportFilters,
                meta: {
                    activeTab: 'transport',
                },
            },
            {
                path: 'transport-subscribe',
                name: 'TransportSubscribe',
                component: TransportSubscribe,
                meta: {
                    activeTab: 'transport',
                    cardSize: 'small',
                },
            },
            {
                path: 'park-filters',
                name: 'Filters',
                component: Filters,
                meta: {
                    activeTab: 'park',
                },
            },
            { path: 'automatic', name: 'AutomaticRoutes', component: AutomaticRoutes },
            {
                path: 'direction',
                name: 'PathDirection',
                component: PathDirection,
                meta: {
                    activeTab: 'route',
                    cardSize: 'medium-small',
                    hideBlueLine: true,
                },
            },
            {
                path: 'arrivedpark/:id',
                name: 'ArrivedPark',
                component: ArrivedPark,
                meta: {
                    cardSize: 'medium',
                },
            },
            { path: 'routedirection', name: 'RouteDirection', component: RouteDirection },
            { path: 'routedetails', name: 'RouteDetails', component: RouteDetails },
            { path: 'completedroute', name: 'CompletedRoute', component: CompletedRoute },
            {
                path: 'parkingspot/:id',
                name: 'ParkingSpot',
                component: ParkingSpot,
                meta: {
                    cardSize: 'medium',
                },
            },
            {
                path: 'mapsettings',
                name: 'MapSettings',
                component: MapSettings,
                meta: {
                    cardSize: 'medium',
                },
            },
            {
                path: 'busstop/:id',
                name: 'BusStop',
                component: BusStop,
                meta: {
                    cardSize: 'small',
                },
            },
            { path: 'allownotifications', name: 'AllowNotifications', component: AllowNotifications },
            { path: 'allowmicro', name: 'AllowMicrophone', component: AllowMicrophone },
            { path: 'notregistered', name: 'NotRegistered', component: NotRegistered },
            { path: 'error', name: 'ErrorNotification', component: ErrorNotification },
            { path: 'continueroute', name: 'ContinueRoute', component: ContinueRoute },
            { path: 'noconnection', name: 'NoConnection', component: NoConnection },
            { path: 'noGPS', name: 'NoGPS', component: NoGPS },
            { path: 'logoutconfirm', name: 'LogoutConfirm', component: LogoutConfirm },
            { path: 'registeredemail', name: 'RegisteredEmail', component: RegisteredEmail },
            { path: 'connection', name: 'Connection', component: Connection },
            { path: 'calcutateroute', name: 'CalcutateRoute', component: CalcutateRoute },
            { path: 'allowlocation', name: 'AllowLocation', component: AllowLocation },
            { path: 'notificationslist', name: 'NotificationsList', component: NotificationsList },
            { path: 'transitroutes', name: 'TransitRoutes', component: TransitRoutes },
            {
                path: '/transitroutedetails/:id',
                name: 'TransitDetailsDesktop',
                component: TransitDetailsDesktop,
            },
            { path: 'track-bus', name: 'TrackInsideBus', component: TrackInsideBus },
            { path: 'search-stop', name: 'SearchBusStop', component: SearchBusStop },
            {
                path: 'bus-details/:id',
                name: 'BusDetails',
                component: BusDetails,
                meta: {
                    cardSize: 'small',
                },
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name == 'BottomMenu') {
        useAppStore().setIsShowOpen(false);
    }
    if (['BusDetails', 'ArrivedPark'].includes(to.name)) {
        useAppStore().setIsShowOpen(true);
    }
    if (to.name != 'PathDirection' && to.name != 'TransitRoutes') {
        useMapStore().setShowDirections(false);
    }
    if (from.name == 'Login' && to.name == 'BottomMenu') {
        // store.commit('map/setLocation', null);
    }
    if (from.name == 'ParkingResearch') {
        useMapStore().setShowParks(true);
    }

    useAppStore().setCardSize(to.meta.cardSize || 'x-large');

    next();
});

export default router;
