import axios from '@/plugins/axios';
import qs from 'qs';
import _ from 'lodash';

export function getRoutes(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/mobility-routes?${query}`);
}

export function getSchedules(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/mobility-trips/search?${query}`);
}

export function getMobilityStops(query = {}) {
    if (!query.populate) query.populate = ['trip', 'trip.transport', 'trip.stop_times.stop', 'trip.calendars.calendar_dates', 'trip.route.agency.pictures', 'stop'];
    query = qs.stringify(query);
    return axios.get(`/mobility-stop-times?${query}`);
}

export function getMobilityAgencies(query = {}) {
    if (!query.populate) query.populate = ['pictures'];
    query = qs.stringify(query);
    return axios.get(`/mobility-agencies?${query}`);
}
export function getMobilityTransports(query = {}) {
    if (!query.populate) query.populate = ['pictures'];
    query = qs.stringify(query);
    return axios.get(`/mobility-transports?${query}`);
}
