<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-6">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.filters.title') }}</h1>
                <div></div>
            </div>

            <div>
                <h1 class="subtitle is-size-8 has-text-left mb-3">{{ $t('components.filters.order') }}</h1>
                <div class="is-flex is-flex-direction-column gap-3">
                    <b-radio v-model="radio" size="is-small-mobile is-large-tablet" native-value="departure_time"> {{ $t('components.filters.schedule') }} </b-radio>
                    <b-radio v-model="radio" size="is-small-mobile is-large-tablet" class="is-primary" native-value="travel_time"> {{ $t('components.filters.time') }} </b-radio>
                </div>
            </div>

            <div>
                <h1 class="subtitle is-size-8 has-text-left mb-3">{{ $t('components.filters.transporttype') }}</h1>
                <div class="is-flex is-flex-direction-column gap-3">
                    <b-checkbox @input="onSelectAllTypes" :value="allSelectedType" :indeterminate="indeterminateType" native-value="all">{{ $t('components.filters.all') }}</b-checkbox>
                    <b-checkbox v-model="typesSelected" v-for="(transport, index) of transportTypes" :key="index" :native-value="transport">
                        <p v-if="transport == 'roadway'">{{ $t('components.filters.bus') }}</p>
                        <p v-if="transport == 'railway'">{{ $t('components.filters.train') }}</p>
                    </b-checkbox>
                </div>
            </div>

            <div>
                <h1 class="subtitle is-size-8 has-text-left mb-3">{{ $t('components.filters.agency') }}</h1>
                <div class="is-flex is-flex-direction-column gap-3">
                    <b-checkbox @input="onSelectAll" :value="allSelected" :indeterminate="indeterminate" native-value="all">{{ $t('components.filters.all') }}</b-checkbox>
                    <b-checkbox v-model="agenciesSelected" v-for="(agency, index) of agencies" :key="index" :native-value="agency.id">
                        {{ agency.name }}
                    </b-checkbox>
                </div>
            </div>

            <b-button @click="onClick" expanded class="submit-button has-text-weight-lighter has-shadow" type="is-primary">{{ $t('components.filters.filterbutton') }}</b-button>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { useTripStore } from '@/store/trip';

    export default {
        name: 'TransportFilters',
        data() {
            return {
                radio: null,
                selectedTransport: false,
                selectedTransportType: null,
                query: {},
                agenciesSelected: [],
                typesSelected: [],
                tripStore: useTripStore(),
            };
        },
        async created() {
            this.query = this.tripStore.getQuery;
            this.agenciesSelected = _.get(this.query, 'agencies', []);
            this.typesSelected = _.get(this.query, 'transportTypes', []);

            if (this.agenciesSelected.length == 0) this.agenciesSelected = this.agencies.map((agency) => agency.id);
            if (this.typesSelected.length == 0) this.typesSelected = this.transportTypes.map((transport) => transport);
            this.radio = _.get(this.query, 'sort', 'departure_time');
        },
        computed: {
            agencies() {
                return this.tripStore.getAgencies;
            },
            transportTypes() {
                return this.tripStore.getTransportTypes;
            },
            indeterminate() {
                return this.agenciesSelected.length > 0 && this.agenciesSelected.length < this.agencies.length;
            },
            allSelected() {
                return this.agenciesSelected.length == this.agencies.length;
            },
            indeterminateType() {
                return this.typesSelected.length > 0 && this.typesSelected.length < this.transportTypes.length;
            },
            allSelectedType() {
                return this.typesSelected.length == this.transportTypes.length;
            },
        },
        methods: {
            onSelectAll(value) {
                if (value) {
                    this.agenciesSelected = this.agencies.map((agency) => agency.id);
                } else {
                    this.agenciesSelected = [];
                }
            },
            onSelectAllTypes(value) {
                if (value) {
                    this.typesSelected = this.transportTypes.map((transport) => transport);
                } else {
                    this.typesSelected = [];
                }
            },
            onClick() {
                _.set(this.query, 'sort', this.radio);
                _.set(this.query, 'agencies', this.agenciesSelected);
                _.set(this.query, 'transportTypes', this.typesSelected);
                this.tripStore.setQuery(this.query);
                this.$router.go(-1);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
</style>
