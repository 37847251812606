import axios from '@/plugins/axios';
import qs from 'qs';

export function getFavorites(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/favorites?${query}`);
}

export function addFavorites(data, query = {}) {
    query = qs.stringify(query);
    return axios.post(`/favorites?${query}`, { data });
}

export function removeFavorites(id) {
    return axios.delete(`/favorites/${id}`);
}
