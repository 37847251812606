<template>
    <div>
        <div class="columns is-mobile is-multiline is-centered is-flex is-flex-wrap-wrap is-gapless">
            <div class="column is-12-mobile is-12-tablet is-flex is-justify-content-left mb-2">
                <div class="has-background-grey-light has-radius py-2 px-3">
                    <h1 class="has-text-grey has-text-weight-medium">{{ subSection.name }}</h1>
                </div>
            </div>
            <ParkSpot v-for="spot in subSection.spots" :key="spot.name" :selectedSection="selectedSection" :selectedFloor="selectedFloor" :spot="spot" @selectedSpot="emitSelectedSpot" />
        </div>
    </div>
</template>

<script>
    import ParkSpot from '@/components/parking/ParkSpot.vue';
    export default {
        data() {
            return {};
        },
        props: {
            selectedSection: {
                default: null,
            },
            subSection: {
                default: null,
            },
            selectedFloor: {
                default: null,
            },
        },
        components: {
            ParkSpot,
        },
        methods: {
            emitSelectedSpot(spot) {
                this.$emit('selected-spot', spot);
            },
        },
    };
</script>
<style scoped>
    .has-radius {
        border-radius: 12px;
    }
</style>
