<template>
    <div class="columns is-mobile is-multiline is-centered fill-height">
        <div class="column is-10-mobile is-10-desktop is-9-tablet is-flex is-flex-direction-column fill-height gap-8">
            <div class="top-bar">
                <b-button @click="$router.go(-1)" size="is-40x40" class="is-primary">
                    <b-icon type="is-white" pack="fas" icon="angle-left"></b-icon>
                </b-button>
                <h1 class="title is-5 mb-0">{{ $t('components.parking.details') }}</h1>
                <div></div>
            </div>
            <b-loading :active="isLoading"></b-loading>
            <template v-if="park">
                <ParkInformation :park="park"></ParkInformation>
                <div v-if="park.price && park.price > 0" class="is-flex is-flex-direction-column gap-2">
                    <h1 class="is-size-6 has-text-grey-dark has-text-weight-semibold has-text-left">{{ $t('components.parking.duration') }}:</h1>
                    <div class="grid-30-70 is-align-items-center">
                        <p class="is-size-6 has-text-grey-dark has-text-weight-light has-text-left">{{ $t('components.parking.hours') }}:</p>
                        <b-numberinput type="is-white has-text-primary" v-model="hours" placeholder="0" :min="0"></b-numberinput>
                    </div>
                    <div class="grid-30-70 is-align-items-center">
                        <p class="is-size-6 has-text-grey-dark has-text-weight-light has-text-left">{{ $t('components.parking.minutes') }}:</p>
                        <b-numberinput type="is-white has-text-primary" v-model="minutes" step="15" placeholder="0" :min="0" max="59"></b-numberinput>
                    </div>
                    <div class="grid-30-70 is-align-items-center">
                        <p class="is-size-5 has-text-grey-dark has-text-weight-semibold has-text-left">{{ $t('components.parking.total') }}:</p>
                        <p class="is-size-5 has-text-grey-dark has-text-weight-semibold has-text-right">{{ ((this.hours + this.minutes / 60) * park.price).toFixed(2) }} €</p>
                    </div>
                </div>
                <div class="is-flex is-flex-direction-column gap-4">
                    <b-button @click="$router.push({ name: 'ParkMap', query: { park: park.id } })" expanded class="has-text-weight-lighter has-shadow" type="is-primary">
                        <p class="is-size-6">{{ $t('views.parkreservation.title') }}</p>
                    </b-button>
                    <b-button @click="parkDestination" expanded class="has-text-weight-lighter has-shadow" type="is-primary">
                        <p class="is-size-6">{{ $t('components.parking.button') }}</p>
                    </b-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import ParkInformation from '@/components/ui/ParkInformation.vue';
    import PathDirection from '@/components/directions/PathDirection.vue';
    import useParks from '@/mixins/useParks';
    import { useMapStore } from '@/store/map';
    import { useRecentsStore } from '@/store/recents';
    import { useAppStore } from '@/store/app';

    export default {
        name: 'ParkDetails',
        components: {
            ParkInformation,
            PathDirection,
        },

        data() {
            return {
                isLoading: false,
                hours: 0,
                minutes: 0,
                park: null,
                useParks: useParks(),
                mapStore: useMapStore(),
                recentsStore: useRecentsStore(),
                appStore: useAppStore(),
            };
        },
        async created() {
            await this.fetchPark();
            this.setRecents();
        },
        watch: {
            '$route.params.id'(newValue) {
                this.fetchPark();
                this.setRecents();
            },
        },

        methods: {
            parkDestination() {
                this.mapStore.setRouteDestination({
                    parkId: this.park.id,
                    name: this.park.name,
                    formatted_address: this.park.address,
                    geometry: {
                        location: {
                            lat: this.park.latitude,
                            lng: this.park.longitude,
                        },
                    },
                });
                this.$router.push({ name: 'RouteResearch' });
            },
            setRecents() {
                this.recentsStore.addRecent({
                    type: 'parkingLot',
                    id: this.$route.params.id,
                });
            },
            async fetchPark() {
                this.isLoading = true;
                this.park = await this.useParks.getParkById(this.$route.params.id);
                if (this.park.price > 0) {
                    this.appStore.setCardSize('large');
                }

                if (this.$route.query.freeLots) {
                    this.park.freeLots = this.$route.query.freeLots;
                }

                this.isLoading = false;
            },
        },
    };
</script>
