<template>
    <div class="container px-3">
        <div class="columns is-mobile is-multiline is-vcentered">
            <div class="column is-1-mobile is-1-tablet is-2-desktop mt-5 mb-5">
                <b-button @click="$router.go(-1)" size="is-40x40" class="button is-primary is-pulled-left ml-5 submit-button">
                    <b-icon type="is-white py-1	px-1" pack="fas" icon="angle-left"> </b-icon>
                </b-button>
            </div>
            <div class="column is-10-mobile is-11-tablet is-8-desktop mt-5 mb-5">
                <h1 class="title is-5">Detalhes</h1>
            </div>
            <div class="column is-1-mobile is-11-tablet is-8-desktop"></div>
        </div>

        <div class="is-flex is-align-items-center is-justify-content-center mb-6">
            <h1 type=" has-text-grey" class="has-text-weight-bold is-small py-1 mx-2">{{ origin }}</h1>
            <b-icon type="is-grey is-small" icon-pack="fas" icon="angle-right"></b-icon>
            <h1 type=" has-text-grey" class="has-text-weight-bold is-small py-1 mx-2">{{ destination }}</h1>
        </div>

        <div class="has-scroll-large">
            <div v-for="(step, s) in mapStore.getRouteResult.routes[selectedRoute].legs[0].steps" :key="s" class="columns is-mobile is-multiline is-centered is-vcentered">
                <div class="column is-2-mobile is-2-tablet">
                    <b-icon :type="getTravelMode(step).color" class="px-5 py-5 has-icon-radius" pack="fas" :icon="getTravelMode(step).icon"></b-icon>
                </div>
                <div class="column is-8-mobile is-6-tablet has-text-left">
                    <p class="is-size-6 ml-4 has-text-grey-light">
                        <strong class="has-text-weight-semibold">{{ getTravelMode(step).title }}</strong> ({{ getStepInformation(step).distance }})
                    </p>
                    <p class="subtitle is-size-7 ml-4 has-text-grey-light mt-1">{{ getStepInformation(step).instructions }}</p>
                </div>
                <div class="column is-2-mobile is-4-tablet">
                    <h1 class="title is-size-5 has-text-weight-bold has-text-right">{{ getStepInformation(step).duration }}</h1>
                    <p class="subtitle is-size-7 has-text-right has-text-grey-light">min</p>
                </div>
                <div class="column is-2-mobile is-2-tablet">
                    <b-icon type="is-grey-light" pack="fas" icon="ellipsis-vertical"></b-icon>
                </div>
                <div class="column is-10-mobile is-10-tablet"></div>
            </div>
            <div class="columns is-mobile is-multiline is-centered is-vcentered pb-6">
                <div class="column is-2-mobile is-2-tablet">
                    <b-icon type="is-primary has-background-primary-light" class="px-5 py-5 has-icon-radius" pack="fas" icon="location-dot"></b-icon>
                </div>
                <div class="column is-8-mobile is-6-tablet has-text-left">
                    <p class="is-size-6 ml-4 has-text-grey-light">
                        <strong class="has-text-weight-semibold">{{ destination }}</strong>
                    </p>
                </div>

                <div class="column is-2-mobile is-4-tablet"></div>
                <b-button @click="openGoogleMaps" class="button has-text-primary has-text-weight-bold mt-2">{{ $t('components.path.navegation') }} </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { useMapStore } from '@/store/map';
    export default {
        props: ['transportType'],

        data() {
            return {
                selectedRoute: this.$route.params.id,
                routeOrigin: null,
                routeDestination: null,
                mapStore: useMapStore(),
            };
        },
        created() {
            this.routeOrigin = this.mapStore.getRouteOrigin.geometry.location;
            this.routeDestination = this.mapStore.getRouteDestination.geometry.location;
        },
        computed: {
            destination() {
                return _.get(this.mapStore.getRouteDestination, 'name', this.$t('components.search.yourlocation'));
            },
            origin() {
                return _.get(this.mapStore.getRouteOrigin, 'name', this.$t('components.search.yourlocation'));
            },
        },
        methods: {
            getSteps(step) {
                if (step.travel_mode == 'TRANSIT') {
                    this.travelMode = 'TRANSIT';
                    //      const color = step.transit.line.color;
                    /* const colorName = GetColorName(step.transit.line.color);
                     */

                    return {
                        style: 'background-color: ' + step.transit_details.line.color,
                        text: step.transit_details.line.short_name + ' - ' + step.transit_details.line.agencies[0].name,
                    };
                } else if (step.travel_mode == 'WALKING') {
                    this.travelMode = 'WALKING';
                    return {
                        text: '',
                        style: 'background-color: transparent',
                    };
                }
            },
            getTravelMode(step) {
                if (step.travel_mode == 'TRANSIT') {
                    return {
                        icon: 'subway',
                        color: 'is-purple has-background-purple-light',
                        title: step.transit_details.line.agencies[0].name,
                    };
                } else if (step.travel_mode == 'WALKING') {
                    return {
                        icon: 'person-walking',
                        color: 'is-green has-background-green-light',
                        title: 'A pé',
                    };
                }
            },
            getStepInformation(step) {
                return {
                    distance: step.distance.text,
                    duration: step.duration.text.split(' ')[0],
                    instructions: step.instructions,
                };
            },
            openGoogleMaps() {
                let origLat = typeof this.routeOrigin.lat == 'function' ? this.routeOrigin.lat() : this.routeOrigin.lat;
                let origLng = typeof this.routeOrigin.lng == 'function' ? this.routeOrigin.lng() : this.routeOrigin.lng;
                let destLat = typeof this.routeDestination.lat == 'function' ? this.routeDestination.lat() : this.routeDestination.lat;
                let destLng = typeof this.routeDestination.lng == 'function' ? this.routeDestination.lng() : this.routeDestination.lng;
                let travelmode = this.mapStore.getRouteTravelMode;

                if (navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPod') != -1 || navigator.platform.indexOf('iPad') != -1) this.url = `maps://?q=${destLat},${destLng}'`;
                else this.url = `https://www.google.com/maps/dir/?api=1&travelmode=${travelmode.toLowerCase()}&layer=traffic&destination=${destLat},${destLng}&origin=${origLat},${origLng}`;

                window.open(this.url, '_system');
            },
        },
    };
</script>

<style scoped>
    .has-scroll-large {
        max-height: 80vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
</style>
