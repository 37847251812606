<template>
    <nav class="navbar is-fixed-bottom rounded" role="navigation">
        <div class="navbar has-background-white container is-expanded rounded is-relative pb-5">
            <div class="columns is-mobile is-multiline is-centered">
                <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet mt-6">
                    <b-icon type="is-green is-large has-background-green-light has-border-radius py-6 px-6" pack="fas" icon="route"></b-icon>
                </div>
            </div>
            <div class="columns is-mobile is-multiline is-centered is-vcentered mb-4 mt-4">
                <div class="column is-10-mobile is-10-tablet">
                    <h1 class="title is-5">{{ $t('components.notifications.routecalculating') }}</h1>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'CalcutateRoute',
    };
</script>
