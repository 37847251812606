// API
import { tokenAuth, googleLogin, facebookLogin, appleLogin } from '@/api/auth';
import { useAuthStore } from '@/store/auth';
// import { cfaSignInGoogle, cfaSignInFacebook, cfaSignInApple } from '@iotechpis/capacitor-firebase-auth';

export default {
    methods: {
        onLoginSuccess(data) {
            useAuthStore().setToken(data.jwt);
            useAuthStore().setUser(data.user);
            this.$router.push({ name: 'BottomMenu' });
        },
        //FACEBOOK
        facebookLogin() {
            this.$store.commit('auth/setSocialType', 'facebook');
            // cfaSignInFacebook().subscribe(
            // 	(result) => {
            // 		facebookLogin({ idToken: result.idToken })
            // 			.then((response) => {
            // 				this.onLoginSuccess(response.data);
            // 			})
            // 			.catch((error) => {
            //
            // 				this.$buefy.toast.open({
            // 					message: this.$t('mixins.auth.invalidSocial'),
            // 					position: 'is-top',
            // 					type: 'is-danger',
            // 				});
            // 			})
            // 			.finally(() => {
            // 				this.$store.commit('auth/setSocialType', null);
            // 			});
            // 	},
            // 	(error) => {
            //
            // 		this.$buefy.toast.open({
            // 			message: this.$t('mixins.auth.invalidSocial'),
            // 			position: 'is-top',
            // 			type: 'is-danger',
            // 		});
            // 	},
            // 	() => {
            // 		this.$store.commit('auth/setSocialType', null);
            // 	}
            // );
        },
        //GOOGLE
        googleLogin() {
            this.$store.commit('auth/setSocialType', 'google');
            // cfaSignInGoogle().subscribe(
            // 	async (result) => {
            // 		googleLogin({ idToken: result.idToken })
            // 			.then(async (response) => {
            // 				this.onLoginSuccess(response.data);
            // 			})
            // 			.catch((error) => {
            //
            // 				this.$buefy.toast.open({
            // 					message: this.$t('mixins.auth.invalidSocial'),
            // 					position: 'is-top',
            // 					type: 'is-danger',
            // 				});
            // 			})
            // 			.finally(() => {
            // 				this.$store.commit('auth/setSocialType', null);
            // 			});
            // 	},
            // 	(error) => {
            //
            // 		this.$buefy.toast.open({
            // 			message: this.$t('mixins.auth.invalidSocial'),
            // 			position: 'is-top',
            // 			type: 'is-danger',
            // 		});
            // 	},
            // 	() => {
            // 		this.$store.commit('auth/setSocialType', null);
            // 	}
            // );
        },
        //APPLE
        appleLogin() {
            this.$store.commit('auth/setSocialType', 'apple');
            // cfaSignInApple().subscribe(
            // 	(result) => {
            // 		appleLogin(result)
            // 			.then(async (response) => {
            // 				this.onLoginSuccess(response.data);
            // 			})
            // 			.catch((error) => {
            //
            // 				this.$buefy.toast.open({
            // 					message: this.$t('mixins.auth.invalidSocial'),
            // 					position: 'is-top',
            // 					type: 'is-danger',
            // 				});
            // 			})
            // 			.finally(() => {
            // 				this.$store.commit('auth/setSocialType', null);
            // 			});
            // 	},
            // 	(error) => {
            //
            // 		this.$buefy.toast.open({
            // 			message: this.$t('mixins.auth.invalidSocial'),
            // 			position: 'is-top',
            // 			type: 'is-danger',
            // 		});
            // 	},
            // 	() => {
            // 		this.$store.commit('auth/setSocialType', null);
            // 	}
            // );
        },
    },
};
