import { defineStore } from 'pinia';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user: null,
        token: null,
        socialType: null,
        _isGuest: false,
    }),
    getters: {
        getUser(state) {
            return state.user;
        },
        getToken(state) {
            return state.token;
        },
        getSocialType(state) {
            return state.socialType;
        },
        isGuest(state) {
            return state._isGuest;
        },
    },
    actions: {
        setUser(user) {
            this.user = user;
        },
        setToken(token) {
            this.token = token;
        },
        setSocialType(socialType) {
            this.socialType = socialType;
        },
        setIsGuest(guest) {
            this._isGuest = guest;
        },
        resetStore() {
            this.user = null;
            this.token = null;
            this.socialType = null;
            this._isGuest = false;
        },
    },
    persist: true,
});
