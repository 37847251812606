<template>
    <nav class="navbar is-fixed-bottom rounded" role="navigation">
        <div class="navbar has-background-white container is-expanded rounded is-relative pb-9">
            <div class="columns is-mobile is-multiline is-centered">
                <div class="colum is-offset-one-quarter-mobile is-offset-one-quarter-tablet">
                    <b-icon class="location" type="is-white has-background-primary has-icon-radius py-5 px-5" pack="fas" icon="location-dot"></b-icon>
                    <h1 class="title is-size-4 has-text-weight-semibold mt-6">{{ $t('components.routes.arrived') }}</h1>
                    <p class="title is-size-5 has-text-weight-medium has-text-grey-light mt-4">Nome do parque</p>
                    <p class="subtitle is-size-6 has-text-weight-light has-text-grey-light">Rua do parque</p>
                    <b-button @click="$router.push({ name: 'BottomMenu' })" expanded class="button is-primary mt-6">
                        {{ $t('components.routes.done') }}
                    </b-button>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'CompletedRoute',
    };
</script>

<style scoped>
    .location {
        position: absolute;
        transform: translate(-50%, -50%);
    }
</style>
