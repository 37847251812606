<template>
    <div>
        <div class="navbar">
            <div class="columns is-mobile is-multiline is-vcentered">
                <div class="column is-1-mobile is-1-tablet is-2-desktop">
                    <b-button @click="$router.go(-1)" size="is-40x40" class="button is-primary is-pulled-left ml-5">
                        <b-icon type="is-white py-1	px-1" pack="fas" icon="angle-left"> </b-icon>
                    </b-button>
                </div>
                <div class="column is-10-mobile is-11-tablet is-8-desktop">
                    <h1 class="title is-5">
                        {{ $t('components.automaticroutes.title') }}
                    </h1>
                </div>
                <div class="column is-1-mobile is-11-tablet is-8-desktop"></div>
            </div>
        </div>
        <div class="columns is-mobile is-multiline is-centered mt-3">
            <div class="column is-10-mobile is-10-tablet">
                <b-button expanded @click="$router.push({ name: 'SingleRoute' })" class="button has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-green has-background-green-light has-icon-radius py-5 px-5" pack="fab" icon="envira"></b-icon>
                    <p class="has-text-left">
                        {{ $t('components.automaticroutes.sustainable') }} <br />
                        <small>10min</small> •

                        <small>10km</small>
                    </p>
                    <b-icon type="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>
            </div>
            <div class="column is-10-mobile is-10-tablet">
                <b-button expanded @click="$router.push({ name: 'SingleRoute' })" class="button has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-pink has-background-pink-light has-icon-radius py-5 px-5" pack="fas" icon="clock-rotate-left"></b-icon>
                    <p class="has-text-left">
                        {{ $t('components.automaticroutes.faster') }} <br />
                        <small>10min</small> •

                        <small>10km</small>
                    </p>
                    <b-icon type="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>
            </div>
            <div class="column is-10-mobile is-10-tablet">
                <b-button expanded @click="$router.push({ name: 'SingleRoute' })" class="button has-shadow has-text-grey py-2 has-icon-right-margin-auto has-icons-flex-start">
                    <b-icon class="mr-5" type="is-yellow has-background-yellow-light has-icon-radius py-5 px-5" pack="fas" icon="euro"></b-icon>
                    <p class="has-text-left">
                        {{ $t('components.automaticroutes.economic') }} <br />
                        <small>10min</small> •

                        <small>10km</small>
                    </p>
                    <b-icon type="is-grey" pack="fas" icon="angle-right"></b-icon>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AutomaticRoutes',
    };
</script>
